//* ============================================================
//* To replace .env file with setting.js file
//* To save settings and configurations for the application that are not sensitive
//* Do not save sensitive information such as passwords, API keys, etc. in this file
// todo: Always check the exports
//* ============================================================

// backend URL for API calls 
// Do not need the / at the end of the URL
// const backendUrl_local = 'http://localhost:3888'; 
const backendUrl_app = 'https://app-backend.techvisor.io'; 
// const backendUrl_ipp = 'https://ipp-backend.techvisor.io'; 

// Do not need the / at the end of the URL
// const comparisonURL_local = 'http://localhost:3788';
const comparisonURL_app = 'https://comparison.techvisor.io';
// const comparisonURL_ipp = 'https://ipp-comparison.techvisor.io';

// User context for each product
// Do not change the values of the userContext as it will be used in the backend to display comparison online
const userContext_101 = "101";
const userContext_integratedShield = "integratedShield";
const userContext_longTermCare = "longTermCare";
const userContext_lifetimeIncome = "lifetimeIncome";
const userContext_multiPayCI = "multiPayCI";
const userContext_retirementIncome = "retirementIncome";
const userContext_term = "term";
const userContext_universalLifeIndex = "universalLifeIndex";
const userContext_wholeLifeEndowment = "wholeLifeEndowment";
const userContext_wholeLife = "wholeLife";
const allUserContext = { userContext_101, userContext_integratedShield, userContext_longTermCare, userContext_lifetimeIncome, userContext_multiPayCI, userContext_retirementIncome, userContext_term, userContext_universalLifeIndex, userContext_wholeLifeEndowment, userContext_wholeLife };

// Insurer list for each product
const ipp_insurer_integratedShield = ["AIA", "HSBC Life", "Income", "Raffles Health Insurance", "Singlife"];
const ipp_insurer_longTermCare = ["Income", "Singlife"];
const ipp_insurer_lifetimeIncome = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife"];
const ipp_insurer_multiPayCI = ["AIA", "FWD", "Manulife", "Singlife", "Tokio Marine"];
const ipp_insurer_retirementIncome = ["AIA", "CTPIS", "Income", "Manulife", "Singlife"];
const ipp_insurer_term = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife", "Tokio Marine"];
const ipp_insurer_universalLifeIndex = ["AIA", "HSBC Life", "Manulife", "Singlife"];
const ipp_insurer_wholeLifeEndowment = ["AIA", "Income", "Manulife"];
const ipp_insurer_wholeLife = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife"];
const ipp_insurer_101 = ["AIA", "ETIQA", "FWD", "HSBC Life", "Income", "Singlife", "Tokio Marine" /* "Manulife" */];
const ippComparison = {...allUserContext, ipp_insurer_101, ipp_insurer_integratedShield, ipp_insurer_longTermCare, ipp_insurer_lifetimeIncome, ipp_insurer_multiPayCI, ipp_insurer_retirementIncome, ipp_insurer_term, ipp_insurer_universalLifeIndex, ipp_insurer_wholeLifeEndowment, ipp_insurer_wholeLife };


// todo: Always check the exports before building the application

exports.userContext_integratedShield = userContext_integratedShield;        // integratedShield
exports.userContext_longTermCare = userContext_longTermCare;                // longTermCare
exports.userContext_lifetimeIncome = userContext_lifetimeIncome;            // lifetimeIncome
exports.userContext_multiPayCI = userContext_multiPayCI;                    // multiPayCI
exports.userContext_retirementIncome = userContext_retirementIncome;        // retirementIncome
exports.userContext_term = userContext_term;                                // term
exports.userContext_universalLifeIndex = userContext_universalLifeIndex;    // universalLifeIndex
exports.userContext_wholeLifeEndowment = userContext_wholeLifeEndowment;    // wholeLifeEndowment
exports.userContext_wholeLife = userContext_wholeLife;                      // wholeLife
exports.userContext_101 = userContext_101;                                  // 101

exports.insurer_integratedShield = ipp_insurer_integratedShield;        // integratedShield
exports.insurer_longTermCare = ipp_insurer_longTermCare;                // longTermCare
exports.insurer_lifetimeIncome = ipp_insurer_lifetimeIncome;            // lifetimeIncome
exports.insurer_multiPayCI = ipp_insurer_multiPayCI;                    // multiPayCI
exports.insurer_retirementIncome = ipp_insurer_retirementIncome;        // retirementIncome
exports.insurer_term = ipp_insurer_term;                                // term
exports.insurer_universalLifeIndex = ipp_insurer_universalLifeIndex;    // universalLifeIndex
exports.insurer_wholeLifeEndowment = ipp_insurer_wholeLifeEndowment;    // wholeLifeEndowment
exports.insurer_wholeLife = ipp_insurer_wholeLife;                      // wholeLife
exports.insurer_101 = ipp_insurer_101;                                  // 101

exports.comparison = ippComparison;
exports.backendUrl = backendUrl_app;    
exports.clientComparisonURL = comparisonURL_app;
