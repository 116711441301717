import React, { useState } from 'react'
import PropTypes from 'prop-types';
import DOMPurify from "dompurify";
import { useFormik } from 'formik';

// prime react 
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { SelectButton } from 'primereact/selectbutton';

// html to image (https://www.npmjs.com/package/html-to-image)
import * as htmlToImage from 'html-to-image';

// custom css contact card
import ContactCardToPrint from './ContactCardToPrint';

// custom helpers
import { renderQuillHeader } from 'helpers/quillheader';

const textSample = '<p>--------------</p><h1>Heading 1</h1><h2>Heading 2</h2><h3>Heading 3</h3><h4>Heading 4</h4><h5>Heading 5</h5><p>normal</p><p><strong>bold</strong> <em>italic</em> <u>underline</u> <s>strike</s></p><p><span style="color: rgb(0, 102, 204);">Blue</span> <span style="background-color: rgb(255, 255, 0);">Yellow</span></p><ol><li>Item 1</li><li>Item 2</li><li>Item 3</li></ol><p><br></p>';

// formik initalValues
const initialValues = {
    title: "My Title",
    subtitle: "My Subtitle",
    htmlContent: textSample,
}

// css
const centerCSS = { display: "flex", justifyContent: "center", marginTop: "10px" }

// component
const FormCardContent = (props) => {

    // props
    const { profileURL, backgroundURL } = props;

    // active tab index
    const [activeIndex, setActiveIndex] = useState(0);

    // card length option
    const options = ['Standard', 'Long Card'];
    const [cardOption, setCardOption] = useState(options[0])

    // formik
    const formik = useFormik({
        initialValues,
        onSubmit: values => generateCard()
    })

    // rendering the custom Quill header and pass to props headerTemplate
    const header = renderQuillHeader();

    // handle clear button of Quill form
    const handleClear = () => {
        formik.setFieldValue("htmlContent", "");
        formik.setFieldValue("title", "");
        formik.setFieldValue("subtitle", "");
    }

    // create the jpeg contact card
    const generateCard = () => {

        // https://www.npmjs.com/package/html-to-image
        // seems to only works well with chrome or when cache is cleared
        htmlToImage.toJpeg(document.getElementById(`contact-card`), { cacheBust: true })
            .then(function (dataUrl) {
                // set delay cos safari need the delay to work while chrome does not
                // https://stackoverflow.com/questions/69949555/convert-svg-with-image-not-working-in-safari
                const browser = window.navigator.userAgent;
                let delay = 300;
                if (browser.indexOf("Chrome") < 0) {
                    alert("For safari browser, you may need to generate more than once for the image to appear");
                    delay = 1000
                }
                const link = document.createElement('a');
                link.download = "contact-card.jpeg";
                setTimeout(() => {
                    link.href = dataUrl;
                    link.click();
                }, delay);

            })
            .catch((err) => {
                // console.log(err)
            });
    }

    return (
        <div className='grid'>
            <div className='col-12 md:col-6'>
                <div className="field card">
                    <label htmlFor="title" className="block text-900 font-medium mt-3 mb-3">Title: </label>
                    <InputText
                        id="title"
                        type="text"
                        value={formik.values.title}
                        onChange={(e) => formik.setFieldValue("title", e.target.value)}
                        className="w-full mb-3" />
                    <label htmlFor="subtitle" className="block text-900 font-medium mb-3">Subtitle: </label>
                    <InputText
                        id="subtitle"
                        type="text"
                        value={formik.values.subtitle}
                        onChange={(e) => formik.setFieldValue("subtitle", e.target.value)}
                        className="w-full mb-3" />
                    <label htmlFor="description" className="block text-900 font-medium mb-3">Content: </label>
                    <Editor
                        id="description"
                        headerTemplate={header}
                        style={{ height: '448px' }}
                        value={formik.values.htmlContent}
                        onTextChange={(e) => formik.setFieldValue("htmlContent", e.htmlValue)}
                        className="w-full mb-3" />
                    <div className='grid mt-2'>
                        <div className="col-12 md:col-6">
                            <Button onClick={handleClear} label="Clear Card" aria-label="Clear" className="p-button-outlined w-full" />
                        </div>
                        <div className="col-12 md:col-6">
                            <Button onClick={formik.submitForm} label="Download Card" aria-label="Submit" className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <div className='card'>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Style I">
                        </TabPanel>
                        <TabPanel header="Style II">
                        </TabPanel>
                        <TabPanel header="Style III">
                        </TabPanel>
                    </TabView>
                    <div className="flex justify-content-center flex-wrap w-auto">
                            <SelectButton value={cardOption} onChange={(e) => setCardOption(e.value)} options={options} unselectable={false} />
                        </div>
                    <div className='grid'>
                        <div className='col-12'>

                            <div style={centerCSS}>
                                <ContactCardToPrint
                                    design={activeIndex + 1} // need to add 1 cos activeIndex start from zero
                                    title={formik.values.title}
                                    subTitle={formik.values.subtitle}
                                    backgroundURL={backgroundURL}
                                    profileURL={profileURL}
                                    cardStandardLength={cardOption === "Standard"}
                                    htmlContent={(DOMPurify.sanitize(formik.values.htmlContent,
                                        {
                                            USE_PROFILES: {
                                                html: true,
                                                CUSTOM_ELEMENT_HANDLING: {
                                                    tagNameCheck: null, // no custom elements are allowed
                                                    attributeNameCheck: /ql-/, // allow all attributes containing "ql-"
                                                    allowCustomizedBuiltInElements: true, // allow customized built-ins
                                                },
                                            }
                                        }))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='grid'>
                            <div className="col-12">
                                <Button onClick={generateCard} label="Download Card" aria-label="Submit" className='w-full' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormCardContent

FormCardContent.propTypes = {
    profileURL: PropTypes.string,
    backgroundURL: PropTypes.string,
}
