const breakEven_fwdInvestFirstMax = [
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 1.47,
        "totalValue": 60008.87473320871
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 1.47,
        "totalValue": 90013.31209981309
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.31,
        "totalValue": 120039.59406428551
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.31,
        "totalValue": 180059.39109642818
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.23,
        "totalValue": 240086.8992211424
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.04,
        "totalValue": 360044.966129251
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.91,
        "totalValue": 480052.1481426927
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 500,
        "breakEvenYield": 1.36,
        "totalValue": 66002.98810638898
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 750,
        "breakEvenYield": 1.36,
        "totalValue": 99004.48215958338
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.23,
        "totalValue": 132000.62163086896
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.23,
        "totalValue": 198000.9324463034
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.17,
        "totalValue": 264053.94140357146
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.02,
        "totalValue": 396010.3139505797
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.92,
        "totalValue": 528097.4971234767
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 500,
        "breakEvenYield": 1.27,
        "totalValue": 72031.23712888504
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 750,
        "breakEvenYield": 1.27,
        "totalValue": 108046.85569332754
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.16,
        "totalValue": 144008.61852072264
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.16,
        "totalValue": 216012.92778108394
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.11,
        "totalValue": 288036.07824782573
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 3000,
        "breakEvenYield": 1,
        "totalValue": 432249.51682603464
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.91,
        "totalValue": 576094.9333060593
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 500,
        "breakEvenYield": 1.18,
        "totalValue": 78015.62337377334
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 750,
        "breakEvenYield": 1.18,
        "totalValue": 117023.43506066
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.1,
        "totalValue": 156068.9686655254
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.1,
        "totalValue": 234103.45299828783
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.06,
        "totalValue": 312167.4816309917
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.96,
        "totalValue": 468127.9533562777
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.89,
        "totalValue": 624072.4759802221
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 500,
        "breakEvenYield": 1.11,
        "totalValue": 84051.5757145601
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 750,
        "breakEvenYield": 1.11,
        "totalValue": 126077.36357184009
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.04,
        "totalValue": 168089.16789042726
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.04,
        "totalValue": 252133.7518356409
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 2000,
        "breakEvenYield": 1,
        "totalValue": 336044.68896594725
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.92,
        "totalValue": 504021.4505829861
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.87,
        "totalValue": 672278.152633501
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 500,
        "breakEvenYield": 0.79,
        "totalValue": 90040.50991706073
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 750,
        "breakEvenYield": 0.79,
        "totalValue": 135060.76487559118
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.69,
        "totalValue": 180077.01439040955
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.69,
        "totalValue": 270115.5215856143
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.62,
        "totalValue": 360188.3539179371
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.56,
        "totalValue": 540345.8170324288
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.52,
        "totalValue": 720193.0991724861
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 500,
        "breakEvenYield": 0.75,
        "totalValue": 96065.62816180775
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 750,
        "breakEvenYield": 0.75,
        "totalValue": 144098.44224271155
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.66,
        "totalValue": 192031.78561945405
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.66,
        "totalValue": 288047.6784291812
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.6,
        "totalValue": 384052.86015599355
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.55,
        "totalValue": 576149.8974636224
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.52,
        "totalValue": 768154.4777983162
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 500,
        "breakEvenYield": 0.71,
        "totalValue": 102067.1000219946
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 750,
        "breakEvenYield": 0.71,
        "totalValue": 153100.650032992
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.64,
        "totalValue": 204134.27446653825
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.64,
        "totalValue": 306201.41169980785
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.59,
        "totalValue": 408276.64147823746
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.54,
        "totalValue": 612089.6643056499
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.52,
        "totalValue": 816432.0848443836
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 500,
        "breakEvenYield": 0.67,
        "totalValue": 108033.09058914307
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 750,
        "breakEvenYield": 0.67,
        "totalValue": 162049.63588371463
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.61,
        "totalValue": 216051.81231952447
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.61,
        "totalValue": 324077.71847928717
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.57,
        "totalValue": 432199.24862108595
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.53,
        "totalValue": 648125.1727156127
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.51,
        "totalValue": 864237.4399196412
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 500,
        "breakEvenYield": 0.64,
        "totalValue": 114058.93552075808
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 750,
        "breakEvenYield": 0.64,
        "totalValue": 171088.40328113732
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.59,
        "totalValue": 228126.5871169477
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.59,
        "totalValue": 342189.88067542086
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.55,
        "totalValue": 456098.6440241009
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.52,
        "totalValue": 684239.7872331009
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.5,
        "totalValue": 912142.7216174201
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 300,
        "breakEvenYield": 0.48,
        "totalValue": 72009.21076607343
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 500,
        "breakEvenYield": 0.48,
        "totalValue": 120015.35127678895
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 0.48,
        "totalValue": 180023.02691518358
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.38,
        "totalValue": 240078.55309781653
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.38,
        "totalValue": 360117.82964672474
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.34,
        "totalValue": 480023.87568809534
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.33,
        "totalValue": 720110.0907592218
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.32,
        "totalValue": 960584.4767774265
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 300,
        "breakEvenYield": 0.44,
        "totalValue": 75672.62406749796
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 500,
        "breakEvenYield": 0.44,
        "totalValue": 126121.04011249657
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 750,
        "breakEvenYield": 0.44,
        "totalValue": 189181.56016874517
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.35,
        "totalValue": 252209.31434191603
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.35,
        "totalValue": 378313.97151287366
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.31,
        "totalValue": 504025.8233743723
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.3,
        "totalValue": 756010.575045273
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.29,
        "totalValue": 1008301.0214798216
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 300,
        "breakEvenYield": 0.39,
        "totalValue": 79213.9456820778
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 500,
        "breakEvenYield": 0.39,
        "totalValue": 132023.24280346287
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 750,
        "breakEvenYield": 0.39,
        "totalValue": 198034.86420519458
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.32,
        "totalValue": 264252.50244708156
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.32,
        "totalValue": 396378.75367062236
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.29,
        "totalValue": 528406.3125021008
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.28,
        "totalValue": 792477.0753433931
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.27,
        "totalValue": 1056770.952981332
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 300,
        "breakEvenYield": 0.36,
        "totalValue": 82876.49941170467
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 500,
        "breakEvenYield": 0.36,
        "totalValue": 138127.49901950767
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 750,
        "breakEvenYield": 0.36,
        "totalValue": 207191.24852926156
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.29,
        "totalValue": 276189.1396932841
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.29,
        "totalValue": 414283.7095399263
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.26,
        "totalValue": 552058.6033485194
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.26,
        "totalValue": 828766.702246596
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.25,
        "totalValue": 1105002.3549768869
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 300,
        "breakEvenYield": 0.32,
        "totalValue": 86405.9397925249
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 500,
        "breakEvenYield": 0.32,
        "totalValue": 144009.89965420833
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 750,
        "breakEvenYield": 0.32,
        "totalValue": 216014.84948131256
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.26,
        "totalValue": 288008.1798624534
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.26,
        "totalValue": 432012.2697936801
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.24,
        "totalValue": 576136.7338677632
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.24,
        "totalValue": 864855.9378636155
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.23,
        "totalValue": 1152963.925763433
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 300,
        "breakEvenYield": 0.28,
        "totalValue": 90092.37900553709
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 500,
        "breakEvenYield": 0.28,
        "totalValue": 150153.96500922862
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 750,
        "breakEvenYield": 0.28,
        "totalValue": 225230.94751384255
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.22,
        "totalValue": 300032.577810694
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.22,
        "totalValue": 450048.8667160413
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.17,
        "totalValue": 600306.9441423932
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.17,
        "totalValue": 901076.7622995732
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.16,
        "totalValue": 1201077.5862620035
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 300,
        "breakEvenYield": 0.25,
        "totalValue": 93672.51401330484
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 500,
        "breakEvenYield": 0.25,
        "totalValue": 156120.8566888415
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 750,
        "breakEvenYield": 0.25,
        "totalValue": 234181.285033262
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.2,
        "totalValue": 312107.61947415245
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.2,
        "totalValue": 468161.4292112284
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.15,
        "totalValue": 624019.3400765694
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.15,
        "totalValue": 936424.4483281665
    },
    {
        "premiumPaymentTerm": 26,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.14,
        "totalValue": 1248313.6385606704
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 300,
        "breakEvenYield": 0.23,
        "totalValue": 97310.21767697786
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 500,
        "breakEvenYield": 0.23,
        "totalValue": 162183.6961282963
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 750,
        "breakEvenYield": 0.23,
        "totalValue": 243275.5441924445
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.18,
        "totalValue": 324013.05812886736
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.18,
        "totalValue": 486019.58719330083
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.14,
        "totalValue": 648232.5036948168
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.14,
        "totalValue": 972920.8527792991
    },
    {
        "premiumPaymentTerm": 27,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.13,
        "totalValue": 1296551.904260913
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 300,
        "breakEvenYield": 0.2,
        "totalValue": 100800.1735421262
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 500,
        "breakEvenYield": 0.2,
        "totalValue": 168000.28923687708
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 750,
        "breakEvenYield": 0.2,
        "totalValue": 252000.43385531552
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.17,
        "totalValue": 336325.3794735142
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.17,
        "totalValue": 504488.0692102719
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.13,
        "totalValue": 672473.4132293006
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.13,
        "totalValue": 1009077.9028837081
    },
    {
        "premiumPaymentTerm": 28,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.12,
        "totalValue": 1344842.6332907854
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 300,
        "breakEvenYield": 0.19,
        "totalValue": 104507.79586155245
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 500,
        "breakEvenYield": 0.19,
        "totalValue": 174179.65976925418
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 750,
        "breakEvenYield": 0.19,
        "totalValue": 261269.48965388135
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.15,
        "totalValue": 348039.2394769733
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.15,
        "totalValue": 522058.8592154594
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.12,
        "totalValue": 696484.9011266066
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.12,
        "totalValue": 1045259.2262291653
    },
    {
        "premiumPaymentTerm": 29,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.11,
        "totalValue": 1392672.826109408
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 300,
        "breakEvenYield": 0,
        "totalValue": 109599.60379291634
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 500,
        "breakEvenYield": 0,
        "totalValue": 182666.00632152706
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 750,
        "breakEvenYield": 0,
        "totalValue": 273999.00948229065
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 1000,
        "breakEvenYield": 0,
        "totalValue": 366104.47501373314
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 1500,
        "breakEvenYield": 0,
        "totalValue": 549156.7125205997
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 2000,
        "breakEvenYield": 0,
        "totalValue": 735078.0959757025
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 3000,
        "breakEvenYield": 0,
        "totalValue": 1102948.1992652733
    },
    {
        "premiumPaymentTerm": 30,
        "monthlyPremium": 4000,
        "breakEvenYield": 0,
        "totalValue": 1471480.413158283
    }
]

exports.breakEven_fwdInvestFirstMax = breakEven_fwdInvestFirstMax;