import React, { useRef, useState } from 'react'

// primereact 
import { TabView } from 'primereact/tabview'
import { TabPanel } from 'primereact/tabview'

// custom component
import FormUpdateUserInfo from '_dashboard/modules/usersManagement/FormUpdateUserInfo';
import FormRequestUpdate from '_dashboard/modules/usersManagement/FormRequestUpdate';
import FormChangePassword from '_dashboard/modules/usersManagement/FormChangePassword'
import FormUpdatePic from '_dashboard/modules/usersManagement/FormUpdatePic';
import FormSocialMediaAccountUpdate from './FormSocialMediaAccountUpdate';

import DisplayMembership from '_dashboard/modules/usersManagement/DisplayMembership';


const UpdateUser = () => {

    // state for tab
    const [activeTabIndex, setActiveTabIndex] = useState()

    // ref for tab
    const inputRef = useRef();

    return (
        <div className='grid'>
            <div className='col-12'>
                <div className="card">
                    <h5>Update User Info</h5>
                    <p>You can do the following at this page</p>
                    <ol>
                        <li>View/Edit your info and password</li>
                        <li>Upload/change your profile picture</li>
                        <ul>
                            <li>Aspect Ratio of 1 (image height must be same as image width) </li>
                            <li>E.g. 200 x 200 pixel. Maximum allowed is 800 x 800 pixel </li>
                            <li>Max File Size is 1 MB, and supported file types are: jpg, jpeg, png</li>
                        </ul>
                        <li>Upload/change your background picture</li>
                        <ul>
                            <li>Aspect Ratio of 2 (Image width is 2X of image height) </li>
                            <li>E.g. 400 x 200 pixel. Max is 800 x 400 pixel </li>
                            <li>Max File Size is 1 MB, and supported file types are: jpg, jpeg, png</li>
                        </ul>
                    </ol>
                </div>
            </div>
            <div className="col-12">
                <div className="card" ref={inputRef}>
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                        <TabPanel header="User Info" >
                            <div className='grid'>
                                <div className='col-12 md:col-6'>
                                    <FormUpdateUserInfo />
                                    <FormRequestUpdate />
                                    <FormSocialMediaAccountUpdate />
                                </div>
                                <div className='col-12 md:col-6'>
                                    <DisplayMembership />
                                    <FormChangePassword />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Profile Pic">
                            <FormUpdatePic type="avatarURL" aspectRatio={1} />
                        </TabPanel>
                        <TabPanel header="Background Pic">
                            <FormUpdatePic type="backgroundURL" aspectRatio={2 / 1} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    )
}

export default UpdateUser