/**
 * A component that allows the user to crop an image with a specified aspect ratio.
 * 
 * @param {Object} props - The props object containing the following properties:
 * @param {number} [props.aspectRatio=1] - The aspect ratio to use for cropping. Defaults to 1 (square).
 * @param {string} props.imageDataURL - The data URL of the image to crop.
 * @param {Function} props.getCroppedImageDataURL - A callback function that will be called with the data URL of the cropped image.
 * @param {Function} props.closeImageCropper - A callback function that will be called when the user cancels or completes the cropping process.
 * 
 * @returns {JSX.Element} - The ImageCropper component.
 * The code is adapted from youtube -> https://www.youtube.com/watch?v=RmiP1AY5HFM
 * GitHub Link => https://github.com/manishboro/image-crop-and-zoom/blob/master/src/App.jsx#L39
 */

import React, { useState, useCallback } from 'react'
import Cropper from "react-easy-crop";

// PrimeReact
import { Button } from 'primereact/button'
// import { Slider } from 'primereact/slider';

// helper function
import { getCroppedImg } from "helpers/cropImage";

const ImageCropper = (props) => {

    // destructure props for use
    const { aspectRatio = 1, imageDataURL, getCroppedImageDataURL, closeImageCropper } = props;

    // states
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    // const [croppedImage, setCroppedImage] = useState(null);

    // cropper functions
    const onCropChange = (value) => setCrop(value);
    const onZoomChange = (value) => setZoom(value);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        // console.log("onCropCompleye @ ImageCropper.js", croppedArea, croppedAreaPixels);
        setTimeout(() => {
            setCroppedArea(croppedAreaPixels);
        }, 100);
    }, []);

    const resetCropper = () => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        closeImageCropper();
    }

    const handleCrop = async () => {

        // get the cropped image data URL
        const croppedDataURL = await getCroppedImg(imageDataURL, croppedArea)
        getCroppedImageDataURL(croppedDataURL);

        // reset the states to initial values and close the cropper
        resetCropper();
    }

    return (
        <div style={{ position: "absolute", height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: "relative", flex: 1 }}>
                <Cropper
                    image={imageDataURL}
                    crop={crop}
                    zoom={zoom}
                    restrictPosition={true}
                    aspect={aspectRatio} // 1 for a square 
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                />
            </div>
            <div style={{ height: '20%', width: "80%", margin: "auto", padding: "10px" }}>
                {
                    // <Slider className='mt-3' value={zoom} min={0} max={3} step={0.1} onChange={e => setZoom(e.value)} />
                }
                <div className='grid mt-3'>
                    <div className="col-12 md:col-6">
                        <Button label="Cancel" aria-label="Clear" className="col-12" onClick={() => resetCropper()} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button label={"Upload"} aria-label="Submit" className="col-12" onClick={() => handleCrop()} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageCropper

/*
      <div style={{ position: "absolute", height: '100vh', width: '100vw' }}>
            <div style={{ position: "relative", height: '80%' }}>
                <Cropper
                    image={imageDataURL}
                    crop={crop}
                    zoom={zoom}
                    restrictPosition={true}
                    aspect={aspectRatio} // 1 for a square 
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                />
            </div>
            <div style={{ height: '20%', margin: "auto", padding: "10px" }}>
                <Slider className='mt-3' value={zoom} min={0} max={3} step={0.1} onChange={e => setZoom(e.value)} />
                <div className='grid mt-3'>
                    <div className="col-12 md:col-6">
                        <Button label="Cancel" aria-label="Clear" className="col-12" onClick={() => resetCropper()} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button label={"Crop & Upload"} aria-label="Submit" className="col-12" onClick={() => handleCrop()} />
                    </div>
                </div>
            </div>
        </div>


        <div id='modal' style={{ position: "absolute", height: '100vh', width: '100vw' }}>
            <div id='cropImage' style={{ position: "relative", height: '80%' }}>
                <Cropper
                    image={imageDataURL}
                    crop={crop}
                    zoom={zoom}
                    restrictPosition={true}
                    aspect={aspectRatio} // 1 for a square 
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={onCropComplete}
                />
            </div>
            <div id='control' style={{ height: '20%', margin: "auto", padding: "10px" }}>
                <Slider className='mt-3' value={zoom} min={0} max={3} step={0.1} onChange={e => setZoom(e.value)} />
                <div className='grid mt-3'>
                    <div className="col-12 md:col-6">
                        <Button label="Cancel" aria-label="Clear" className="col-12" onClick={() => resetCropper()} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button label={"Crop & Upload"} aria-label="Submit" className="col-12" onClick={() => handleCrop()} />
                    </div>
                </div>
            </div>
        </div>
*/