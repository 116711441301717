/**
 * Scrolls the window to the top of the page with a smooth animation.
 *
 * @function
 * @name scrollToTop
 * @returns {void}
 */

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
};

export default scrollToTop;