/**
 * Returns the RGB and RGBA color values for the specified insurer.
 *
 * @param {string} insurer - The name of the insurer.       
 * @returns {object} An object containing the RGB and RGBA color values for the insurer.
 */

const AIA = "rgb(187, 14, 38)";
const ChinaLife = "rgb(0, 155, 99)";
const CTPIS = "rgb(187, 14, 38)";
const eTiQa = "rgb(255, 181, 0)";
const FWD = "rgb(231, 119, 35)";
const HSBC = "rgb(219, 0, 17)";
const Income = "rgb(255, 118, 0)";
const Manulife = "rgb(15, 174, 90)";
const RHI = "rgb(0, 128, 102)";
const Singlife = "rgb(255, 0, 8)";
const TM = "rgb(0, 150, 169)";

// main function
const insurerColour = (insurer) => {

    switch (insurer.toLowerCase().trim()) {

        case "aia": return {rgb: AIA, rgba: rgbToRgba(AIA)};
        case "china": return {rgb: ChinaLife, rgba: rgbToRgba(ChinaLife)};
        case "china life": return {rgb: ChinaLife, rgba: rgbToRgba(ChinaLife)};
        case "china taiping": return {rgb: CTPIS, rgba: rgbToRgba(CTPIS)};
        case "ctpis": return {rgb: CTPIS, rgba: rgbToRgba(CTPIS)};
        case "etiqa": return {rgb: eTiQa, rgba: rgbToRgba(eTiQa)};
        case "fwd": return {rgb: FWD, rgba: rgbToRgba(FWD)};
        case "hsbc": return {rgb: HSBC, rgba: rgbToRgba(HSBC)};
        case "hsbc life": return {rgb: HSBC, rgba: rgbToRgba(HSBC)};
        case "income": return {rgb: Income, rgba: rgbToRgba(Income)};
        case "ntuc": return {rgb: Income, rgba: rgbToRgba(Income)};
        case "manulife": return {rgb: Manulife, rgba: rgbToRgba(Manulife)};
        case "rhi": return {rgb: RHI, rgba: rgbToRgba(RHI)};
        case "singlife": return {rgb: Singlife, rgba: rgbToRgba(Singlife)};
        case "tm": return {rgb: TM, rgba: rgbToRgba(TM)};
        case "tokio marine": return {rgb: TM, rgba: rgbToRgba(TM)};
        default: 
            return {rgb: "rgb(26, 53, 91)", rgba: rgbToRgba("rgb(26, 53, 91)")};
    }
}

/**
 * Converts RGB color values to RGBA.
 *
 * @param {string} rgb - The RGB color value.
 * @param {number} [alpha=0.2] - The alpha value for the RGBA color.
 * @returns {string} The RGBA color value.
 */
const rgbToRgba = (rgb, alpha = 0.2) => rgb.replace("rgb", "rgba").replace(")", `, ${alpha})`);

exports.insurerColour = insurerColour;
