
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// PrimeReact
// https://stackoverflow.com/questions/60389073/how-to-upload-files-using-primereact-fileupload-component
import { FileUpload } from 'primereact/fileupload';

// component to upload a SINGLE image (use for cropping image) or multiple files
// props :
// -> type = "image" or "files" (default: image)
// -> maxSixe = "1000000" (default: 1000000 | 1MB)
// -> getUploadFiles is a passed in method to get the upload evenet

const ImageFilesUploader = (props) => {

    // props
    const { maxSize, getUploadEvent } = props;

    // ref 
    const fileUploadRef = useRef(); // for fileUpload function E.g. fileUploadRef.current.clear();

    // event when upload button is pressed
    const handleUpload = (event) => getUploadEvent(event);

    // customising the template for image upload area
    const emptyTemplate = () => {
        return (
            <div className='grid'>
                <div className='col-12'>
                    <span className="pi pi-image flex justify-content-center" style={{ 'fontSize': '2em' }}></span>
                </div>
                <div className='col-12'>
                    <p className='flex justify-content-center'>Drag and drop files to here to upload.</p>
                </div>
            </div>
        )
    }

    // customising the template for header where choose, upload & cancel button are
    const headerTemplate = (options) => {
        const { className, chooseButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex' }}>
                {chooseButton}
            </div>
        );
    }

    return (
        <div>
            <div className="card">
                <FileUpload
                    accept="image/*"
                    customUpload
                    maxFileSize={maxSize}
                    uploadHandler={handleUpload}
                    emptyTemplate={emptyTemplate}
                    headerTemplate={headerTemplate}
                    ref={fileUploadRef}
                    auto
                />
            </div>
        </div>
    )
}

export default ImageFilesUploader

ImageFilesUploader.defaultProps = {
    maxSize: 1000000
}

ImageFilesUploader.propTypes = {
    maxSize: PropTypes.number,
    getUploadEvent: PropTypes.func
}