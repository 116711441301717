import { useState, useEffect } from 'react'

// adapted from https://www.robinwieruch.de/react-uselocalstorage-hook/
// Nullish coalescing operator 
// more info at https://stackoverflow.com/questions/70334826/difference-between-and-in-javascript

const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(
        JSON.parse(window.localStorage.getItem(key)) ?? defaultValue // Nullish coalescing operator 
    )
    // update localStorage whenever the value changes with setValue method
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key])
    return [value, setValue];
}

export default useLocalStorage

