import date from 'date-and-time';
import { getCurrentTime } from "helpers/getCurrentTime";

const savvyInvestII = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true, type) => {

    // console.log("Start of savvyInvest_II")
    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod, createExcel, type });

    // ====
    // * Validation
    // ====

    const allTypes = ["Fixed", "Flexible"];
    if (!allTypes.includes(type)) {
        if (!type) {
            // console.log("type is not provided. Only accepts 'Fixed' or 'Flexible'");
        }
        return { availablity: false }
    }

    const allPremiumPaymentTerm = type === "Fixed" ? [3, 5, 10] : [5, 10, 20];
    // console.log("allPremiumPaymentTerm: ", allPremiumPaymentTerm);
    if (!allPremiumPaymentTerm.includes(premiumPaymentTerm)) {
        return { availablity: false }
    }

    const allMinimumPremiums = [
        { premiumPaymentTerm: 3, type: "Fixed", minPremium: 834 },
        { premiumPaymentTerm: 5, type: "Fixed", minPremium: 834 },
        { premiumPaymentTerm: 10, type: "Fixed", minPremium: 300 },
        { premiumPaymentTerm: 5, type: "Flexible", minPremium: 1000 },
        { premiumPaymentTerm: 10, type: "Flexible", minPremium: 500 },
        { premiumPaymentTerm: 20, type: "Flexible", minPremium: 200 },
    ]

    const minPremium = allMinimumPremiums.find(item => item.premiumPaymentTerm === premiumPaymentTerm && item.type === type).minPremium;
    // console.log("minPremium: ", minPremium);

    if (monthlyPremium < minPremium) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====


    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file
    const excelFileName = `Singlife Savvy Invest II ${getCurrentTime()}.xlsx`;

    // Header for the Excel file
    const excelHeader = [
        // One account model
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Monthly Premium', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'Allocation Amount', align: "center" },
        { fontWeight: 'bold', value: 'UT Price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'Account Units (Beg)', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ IUA Units Purchased', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Welcome Bonus Units', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Promotion Bonus Units', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Loyalty Bonus Units', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '- Administartive Charge', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '- Supplementary Charge', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: 'Account Units (End)', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT Price (End)', align: "center" },

        { fontWeight: 'bold', value: 'Account Value', align: "center" }
    ];

    const excelData = [excelHeader];
    const excelSchema = excelHeader.map(item => ({ width: 28 })) // create the Excel schema

    // ====
    // One Account only (Regular Premium Account)
    // ====

    /*
    const annualPremium = Math.round(monthlyPremium * 12);
    const totalMonths = Math.round(investmentPeriod * 12);
    const totalPremiumMonths = Math.round(premiumPaymentTerm * 12);
    */

    let totalInvestedPremium = 0;

    // ====
    // * Bonus Rates
    // ====

    const getAllocationAmount = (monthlyPremium, policyMonthIndex, mip) => {

        const allocationRate = (policyMonthIndex < 120)
            ? 1
            : (policyMonthIndex <= 240) ? 1.02 : 1.05;

        return Math.floor(monthlyPremium * allocationRate);
    }

    const getWelcomeBonusrate = (monthlyPremium, mip, policyMonthIndex, type) => {

        // console.table({monthlyPremium, mip, policyMonthIndex, type});

        if (policyMonthIndex >= 12) { // no start up bonus after the first year
            return 0;
        }

        if (type !== "Fixed" && type !== "Flexible") {
            console.log("Error: Missing type: Fixed or Flexible for Singlife Savvy Invest II");
            process.exit(1);
        }

        const annualPremium = Math.floor(monthlyPremium * 12);
        const allWelcomeBonusData = [
            { mip: 3, type: "Fixed", cutOff: 25000, belowCuttOff: 0, aboveCuttOff: 0.03 },
            { mip: 5, type: "Fixed", cutOff: 25000, belowCuttOff: 0.06, aboveCuttOff: 0.1 },
            { mip: 5, type: "Flexible", cutOff: 12000, belowCuttOff: 0.04, aboveCuttOff: 0 },
            { mip: 10, type: "Fixed", cutOff: 10000, belowCuttOff: 0.1, aboveCuttOff: 0.4 },
            { mip: 10, type: "Flexible", cutOff: 10000, belowCuttOff: 0.08, aboveCuttOff: 0.15 },
            { mip: 20, type: "Flexible", cutOff: 10000, belowCuttOff: 0.3, aboveCuttOff: 0.6 },
        ];

        const data = allWelcomeBonusData.find(item => item.mip === mip && item.type === type);
        const rate = (annualPremium < data.cutOff) ? data.belowCuttOff : data.aboveCuttOff;

        return Math.floor(monthlyPremium * rate);
    }

    const getAdditionalWelcomeBonusrate = (monthlyPremium, mip, policyMonthIndex, type) => {

        // console.table({monthlyPremium, mip, policyMonthIndex, type});

        if (policyMonthIndex >= 12) { // no start up bonus after the first year
            return 0;
        }

        if (type !== "Fixed" && type !== "Flexible") {
            console.log("Error: Missing type: Fixed or Flexible for Singlife Savvy Invest II");
            process.exit(1);
        }

        const annualPremium = Math.floor(monthlyPremium * 12);
        const allAdditionalWelcomeBonusData = [
            { mip: 3, type: "Fixed", cutOff: 25000, belowCuttOff: 0, aboveCuttOff: 0.005 }, // min premium is 834
            { mip: 5, type: "Fixed", cutOff: 25000, belowCuttOff: 0.005, aboveCuttOff: 0.01 },  // min premium is 834
            { mip: 10, type: "Fixed", cutOff: 10000, belowCuttOff: 0.015, aboveCuttOff: 0.03 }, // min premium is 300

            { mip: 5, type: "Flexible", cutOff: 12000, belowCuttOff: 0.005, aboveCuttOff: 0.01 }, // min premium is 1000
            { mip: 10, type: "Flexible", cutOff: 10000, belowCuttOff: 0.025, aboveCuttOff: 0.055 }, // min premium is 500
            { mip: 20, type: "Flexible", cutOff: 10000, belowCuttOff: 0.045, aboveCuttOff: 0.11 }, // min premium is 200
        ];

        const data = allAdditionalWelcomeBonusData.find(item => item.mip === mip && item.type === type);
        const rate = (annualPremium < data.cutOff) ? data.belowCuttOff : data.aboveCuttOff;

        return Math.floor(monthlyPremium * rate);
    }

    // ====
    // * Calculation
    // ====

    const calculationForpolicyAccount = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, excelData, createExcel, type) => {

        let previousMonthEndUnitBalance = 0; // one account only
        let loyaltyBonusPaymentCount = 0;
        const totalMonths = investmentPeriod * 12;

        for (let i = 0; i < totalMonths; i++) {

            let excelRow = [];

            // ====
            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate start up bonus units at the beginning of the month
            // Allocate customer promo bonus units at the beginning of the month
            // Minus policy account charges at the beginning of the month
            // ====

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // monthly premium
            if (i < premiumPaymentTerm * 12) {
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,##', align: "center" });
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
            }

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,##', align: "center" });
            // console.log("totalInvestedPremium: ", totalInvestedPremium);

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate);

            // allocation amount
            const allocationAmount = (i < premiumPaymentTerm * 12) ? getAllocationAmount(monthlyPremium, i, premiumPaymentTerm) : 0;
            createExcel && excelRow.push({ type: Number, value: allocationAmount, format: '$#,##0.00', align: "center" });
            // console.log("allocationAmount: ", allocationAmount);

            // UT price at the beginning of the month
            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance = previousMonthEndUnitBalance;
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance: ", openingBalance);

            // plus purchase of the units at the beginning of month during premium payment term
            const premiumPaymentMonths = premiumPaymentTerm * 12;
            if (i < premiumPaymentMonths) {
                const unitsPurchased = allocationAmount / UTprice;
                createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
                openingBalance += unitsPurchased;
                // console.log("unitsPurchased: ", unitsPurchased);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // welcome bonus for 12 policy momths
            if (i < 12) {
                const welcomeBonus = getWelcomeBonusrate(monthlyPremium, premiumPaymentTerm, i, type);
                const welcomeBonusUnits = welcomeBonus / UTprice;
                // console.log("welcomeBonus: ", welcomeBonus, " | UTprice: ", UTprice, " | welcomeBonusUnits: ", welcomeBonusUnits);
                createExcel && excelRow.push({ type: Number, value: welcomeBonusUnits, format: '#,##0.000000', align: "center" });
                openingBalance += welcomeBonusUnits;

            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // promotion bonus for 12 policy months
            if (i < 12) {
                const promotionBonus = getAdditionalWelcomeBonusrate(monthlyPremium, premiumPaymentTerm, i, type);
                const promotionBonusUnits = promotionBonus / UTprice;
                // console.log("promotionBonus: ", promotionBonus, " | UTprice: ", UTprice, " | promotionBonusUnits: ", promotionBonusUnits);
                createExcel && excelRow.push({ type: Number, value: promotionBonusUnits, format: '#,##0.000000', align: "center" });
                openingBalance += promotionBonusUnits;
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // loyalty bonus starts immediately after MIP. Pay out yearly at the beginning of the policy year
            let loyaltyBonusUnits = 0;
            if (i >= premiumPaymentTerm * 12 && i % 12 === 0) {
                loyaltyBonusPaymentCount++;
                const loyaltyBonusRate = loyaltyBonusPaymentCount <= 10 ? 0.003 : (loyaltyBonusPaymentCount <= 20 ? 0.004 : 0.005);
                loyaltyBonusUnits = openingBalance * loyaltyBonusRate;
                createExcel && excelRow.push({ type: Number, value: loyaltyBonusUnits, format: '#,##0.000000', align: "center" });
                // * Note: Loyalty bonus is not added because need to add all other charges that is also deducted from the beginning of policy month 

            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // administrative charge 0.6% (0.06) per annum or 0.05% (0.0005) per month
            const administrativeCharge = openingBalance * 0.0005;
            createExcel && excelRow.push({ type: Number, value: -administrativeCharge, format: '#,##0.000000', align: "center" });
            // * Note: administrative charge is not added because need to add all other charges that is also deducted from the beginning of policy month 

            // supplementary charge 1.9% (0.019) per annum or 0.1583333333% (0.001583333333) per month
            // only for the first 10 years of the policy (120 months)
            let supplementaryCharge = 0;
            if (i < 120) {
                supplementaryCharge = openingBalance * 0.019 / 12;
                createExcel && excelRow.push({ type: Number, value: -supplementaryCharge, format: '#,##0.000000', align: "center" });
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // adding loyalty bonus units - administrative charge - supplementary charge (all at the beginning of the month)
            openingBalance += loyaltyBonusUnits - administrativeCharge + -supplementaryCharge;

            // update the end of month unit balance
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });

            // calculate the date of the end of the month
            const currentMonthEndDate = date.addMonths(currentMonth1st, i + 1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate);

            // UT price at the end of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // calculate the account value at the end of the month
            const accountValue = openingBalance * UTprice;
            createExcel && excelRow.push({ type: Number, value: accountValue, format: '$#,##0.00', align: "center" });

            // store the excel row
            createExcel && excelData.push(excelRow);

            // update the previous month end unit balance
            previousMonthEndUnitBalance = openingBalance;

        } // end for loop

        return { balance: previousMonthEndUnitBalance, excelData };
    }

    // ====
    // * One Account only (Regular Premium Account)
    // ====

    const result = calculationForpolicyAccount(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, excelData, createExcel, type);
    const { balance } = result;
    let totalValue = balance * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00
    // console.log("totalValue @ eTiQa_InvestSmart: ", totalValue);
    // console.log("excelData @ eTiQa_InvestSmart: ", excelData);

    return { insurer: "Singlife", planName: `Savvy Invest II (${type})`, availablity: true, totalInvestedPremium, balance, totalValue, excelSchema, excelFileName, excelData: result.excelData }

}

export default savvyInvestII;