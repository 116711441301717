import React from 'react'

// prime react
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'

// customed helper function
import { isoTimeStampToDate } from 'helpers/isoTimeStampToDateFormat';
import scrollToTop from 'helpers/scrollToTop';


// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// helper function
const displayDOB = (dob) => {
    if (!dob) return "";
    const date = new Date(dob);
    return `DOB: ${date.toLocaleDateString()}`;
}

const ComparisonDetails = (props) => {

    console.log("@ ComparisonDetails -> props:", props);
    const { clientName, clientALB, clientSmoker, clientDOB, lastViewed, createdAt, uniqueID, disabled, _id, } = props?.data || {};

    const rnf = uniqueID?.split("-")[0].trim();
    const uniqueLink = `https://comparison.techvisor.io/${rnf}/${_id}`;

    // axios instance
    const axiosInstance = useAxiosAuth();

    /*
    const handleExtend = async () => {

    }
    */

    // ===
    // Disable Comparison
    // ===

    const disableCompaison = async (uniqueID) => {
        try {
            const res = await axiosInstance.post(`dashboard/comparisonUpload/disableComparison`, { uniqueID });
            // console.log("res", res);

            const { data } = res;
            if (data?.success) {
                // console.log("Comparison Disabled");
                scrollToTop();
                alert("Press Refresh Comparison List button to see the changes");
            }
        } catch (error) {
            console.log(error);
            console.log("Error Disabling Comparison");
        }

    }

    const handleDisable = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Please confirm to disable. Once disabled, Cannot Undo.',
            icon: 'pi pi-exclamation-triangle',
            accept: () => disableCompaison(uniqueID),
            reject: () => { }
        });
    }

    return <>
        <div className='card'>
            <div>
                <h5 className='mb-3'>Insured Details:</h5>
                <ul>
                    <li>Insured Name: {clientName}</li>
                    {clientDOB && <li>{displayDOB(clientDOB)}</li>}
                    <li>{`ALB: ${clientALB}`}</li>
                    <li>{`Smoker: ${clientSmoker ? "Yes" : "No"}`}</li>
                    <li>Generated on: {isoTimeStampToDate(createdAt, " ", true)}</li>
                    <li>Link: <a
                        href={uniqueLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {uniqueLink}
                    </a></li>

                </ul>
            </div>
            <div className='mt-6'>
                <h5 className='mb-3'>{`View History (Comparison Loaded ${lastViewed.length} ${lastViewed.length > 1 ? "times" : "time"})`}</h5>
                <ol>
                    {lastViewed.map((view, index) => <li key={index}>{isoTimeStampToDate(view, " ", true)}</li>)}
                </ol>
            </div>
            <div className='mt-6'>
                <div className='flex flex-row-reverse'>
                    {
                        /*
                            <Button
                                icon="pi pi-step-forward"
                                label="Extend another 8 days"
                                onClick={handleExtend}
                                disabled
                            />
                            <span className='mr-3'></span>
                        */
                    }
                    {
                        !disabled && <Button
                            icon="pi pi-exclamation-triangle"
                            label="Disable"
                            className='p-button-outlined'
                            onClick={handleDisable}
                        ></Button>
                    }
                    <ConfirmPopup />
                </div>
            </div>
        </div>
    </>
}

export default ComparisonDetails