/**
 * Renders a custom Quill header with various formatting options.
 *
 * @returns {JSX.Element} The JSX element representing the custom Quill header.
 *
 * @see {@link https://quilljs.com/docs/formats/|Quill Formats}
 * @see {@link https://quilljs.com/docs/modules/toolbar/|Quill Toolbar}
 */

export const renderQuillHeader = () => {
    return (
        <>
            <span className="ql-formats">
                <select className="ql-header" defaultValue={""}>
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option value="">Normal</option>
                </select>
                <select className="ql-font"></select>
            </span>

            <span className="ql-formats">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <button className="ql-strike"></button>
            </span>

            <span className="ql-formats">
                <select className="ql-color"></select>
                <select className="ql-background"></select>
            </span>

            <span className="ql-formats">
                <button className="ql-list" value="ordered" triggers="mouseenter:mouseleave"></button>
                <button className="ql-list" value="bullet" triggers="mouseenter:mouseleave"></button>
                <button className="ql-indent" value="-1" triggers="mouseenter:mouseleave"></button>
                <button className="ql-indent" value="+1" triggers="mouseenter:mouseleave"></button>
            </span>

            <span className="ql-formats">
                <select className="ql-align">
                    <option value=""></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>

            <span className="ql-formats">
                <button className="ql-link"></button>
                <button className="ql-blockquote"></button>
            </span>

            <span className="ql-formats">
                <button className="ql-clean"></button>
            </span>
        </>
    );
}


 