import React from 'react'
import PropTypes from 'prop-types';

// customer helper
import { isoTimeStampToDate } from 'helpers/isoTimeStampToDateFormat';

// custom helper method 
import unescapeSpecialChar from 'helpers/unescapeSpecialChar';

// component
const DisplayShieldDetails = (props) => {

    // console.log("DisplayShieldDetails @ props", props)

    // extract data, details, customerPromo from props and set to {} if undefined
    const insurer = props.data.insurer || "";
    const planName = props.data.planName || "";
    const updatedAt = props.data.updatedAt || "";
    const info = props.data.info || {};
    
    const displayPromo = () => {
        return info?.customerPromo?.valid
            ? <>
                <h5>Promotion Details</h5>
                <ul>
                    <li>Promotion Name: {info?.customerPromo.promoName}</li>
                    {info?.customerPromo?.promoDetails?.map((detail, i) => detail !== "" && <li key={i}>{`${unescapeSpecialChar(detail)}`}</li>)}
                </ul>
            </>
            : <></>
    }

    const displayItem = (key, name) => {
        return (info && info[key] && info[key][0] !== "")
            ? <>
                <h5>{name}</h5>
                <ol>
                    {info[key].map((detail, i) => <li key={i}>{unescapeSpecialChar(detail)}</li>)}
                </ol>
            </>
            : <></>
    }

    const displayOnlineMaterial = () => {
        // console.log("material @ displayOnlineMaterial", details.material)
        return info?.material && info.material[0]?.upload
            ? <>
                <h5>Information Online</h5>
                <ol>
                    {info?.material.map((detail, i) => 
                        <li key={i}><a href={detail.url} target="_blank" rel="noreferrer">{detail.fileName}</a></li>)}
                </ol>
            </>
            : <></>
    }

    return (
        <div className='col-12'>
            <div className='card'>
                <h5>{`${insurer} ${planName}`}</h5>
                <ul>
                    <li>Premium updated on: {isoTimeStampToDate(updatedAt)}</li>
                    {info?.updatedAt && <li>Product info updated on: {isoTimeStampToDate(info.updatedAt)}</li>}
                </ul>
                {displayPromo()}
                {displayItem("congenital", "Congenital Coverage")}
                {displayItem("cancerDrugCDL", "Cancer Drug Treatment (CDL)")}
                {displayItem("cancerDrugService", "Cancer Drug Service")}
                {displayItem("cancerDrugNonCDL", "Cancer Drug Treatment (Non-CDL)")}
                {displayItem("preAuthorisation", "Pre-Authorisation Process")}
                {displayItem("panelDoctors", "Insurer's Panel of Doctors")}
                {displayItem("preAndPostHosp", "Pre & Post Hospitalisation Claims")}
                {displayItem("usps", "Unique Selling Points")}
                {displayItem("notes", "Notes To Advisers & Clients")}
                {displayOnlineMaterial()}
            </div>
        </div>
    );
}

export default DisplayShieldDetails

DisplayShieldDetails.propTypes = {
    data: PropTypes.object
}