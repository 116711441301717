const breakEven_singlifeSavvyInvest = [
    {
        "premiumPaymentTerm": 3,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.54,
        "totalValue": 36001.27275822827,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 3,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.54,
        "totalValue": 54001.90913734243,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 3,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.54,
        "totalValue": 72002.54551645654,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 3,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.78,
        "totalValue": 108013.73637747706,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 3,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.78,
        "totalValue": 144018.31516996946,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.02,
        "totalValue": 60000.16625694654,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.03,
        "totalValue": 90016.91084475743,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.02,
        "totalValue": 120000.33251389308,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.68,
        "totalValue": 180034.2064542263,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.68,
        "totalValue": 240045.60860563503,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 300,
        "breakEvenYield": 2.31,
        "totalValue": 36003.632763968075,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 2.31,
        "totalValue": 60009.971763774076,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 2.31,
        "totalValue": 90020.8333814019,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.71,
        "totalValue": 120021.2512082879,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.71,
        "totalValue": 180031.87681243187,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.71,
        "totalValue": 240042.5024165758,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.71,
        "totalValue": 360063.75362486375,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.71,
        "totalValue": 480085.0048331516,
        "type": "Fixed"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.46,
        "totalValue": 60006.43012745276,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.46,
        "totalValue": 90009.64519117924,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.46,
        "totalValue": 120012.86025490552,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.46,
        "totalValue": 180019.29038235848,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.46,
        "totalValue": 240025.72050981104,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 2.33,
        "totalValue": 60010.61510652606,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 2.33,
        "totalValue": 90015.9226597892,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.13,
        "totalValue": 120006.2852567251,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.13,
        "totalValue": 180003.65000915795,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.13,
        "totalValue": 240012.5705134502,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.13,
        "totalValue": 360018.85577017505,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.13,
        "totalValue": 480025.1410269004,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 300,
        "breakEvenYield": 0.82,
        "totalValue": 72028.90796247889,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 500,
        "breakEvenYield": 0.82,
        "totalValue": 120051.6601151333,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 0.82,
        "totalValue": 180077.49017269994,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.66,
        "totalValue": 240073.00770533798,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.66,
        "totalValue": 360109.51155800663,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.66,
        "totalValue": 480146.01541067596,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.66,
        "totalValue": 720219.0231160133,
        "type": "Flexible"
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.66,
        "totalValue": 960292.0308213519,
        "type": "Flexible"
    }
];

exports.breakEven_singlifeSavvyInvest = breakEven_singlifeSavvyInvest;