/**
 * Converts an ISO timestamp to a formatted date string.
 * @param {string} timeStamp - The ISO timestamp to convert.
 * @param {string} [separator=" "] - The separator to use between date parts.
 * @returns {string} The formatted date string.
 */
export const isoTimeStampToDate = (timeStamp, separator = " ", needHHMM = false) => {

    const date = new Date(timeStamp);
    const month = getMonth(date.getMonth());
    return needHHMM 
        ? date.getDate().toString().padStart(2, '0') + separator + month.toString().padStart(2, '0') + separator + date.getFullYear() + separator + "@ " + date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0') + "hrs" 
        : date.getDate().toString().padStart(2, '0') + separator + month.toString().padStart(2, '0') + separator + date.getFullYear();
}

/**
 * Gets the abbreviated month name for a given month index.
 * @param {number} [index=12] - The month index (0-11).
 * @returns {string} The abbreviated month name.
 */
export const getMonth = (index = 12) => {
    let month = "";
    switch (index) {
        case 0: month = "Jan"; break;
        case 1: month = "Feb"; break;
        case 2: month = "Mar"; break;
        case 3: month = "Apr"; break;
        case 4: month = "May"; break;
        case 5: month = "Jun"; break;
        case 6: month = "Jul"; break;
        case 7: month = "Aug"; break;
        case 8: month = "Sep"; break;
        case 9: month = "Oct"; break;
        case 10: month = "Nov"; break;
        case 11: month = "Dec"; break;
        default: month = "error"
    }
    return month;
}
