import React from 'react'
import { Route, Routes } from 'react-router-dom';

import Verify2FA from '_auth/Verify2FA';

const AuthRouter = () => {
  return (
    <div className='flex h-screen align-items-center justify-content-center'>
      <Routes>
        <Route path="/otp" element={<Verify2FA />} />
        <Route path="/*" element={<Verify2FA />} />
      </Routes>
    </div>
  )
}

export default AuthRouter