import React, { useState } from 'react'
import Finance from 'tvm-financejs'; // tvm-financejs -> https://github.com/kgkars/tvm-financejs
import writeXlsxFile from 'write-excel-file';

// formik functions
import {  Formik, FieldArray } from 'formik';
import * as yup from 'yup'

// PrimeReact elements
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber';

// custom function divide by 100 to avoid js float error/precision
import formatDollar from 'helpers/formatDollar'

// custom useLocalStorge hook
import useLocalStorage from 'hooks/useLocalStorage';

// formik default values
const defaultValues = { cashflows :[{invest: true, amount: 0},{invest: false, amount: 0}] }

// formik validation schema
const cashflowValidation = yup.object().shape({
    cashflows: yup.array().of(
      yup.object().shape({
        invest: yup.boolean(),
        amount: yup.number().required("Please enter a number or enter 0").typeError("A number is required"),
      })
    )
})

// error state default values
const defaultErrorState = {
    hasError: false,
    noInflow: false,
    Msg_noInflow: "At least one inflow (collect) with a non-zero number is needed.",
    noOutflow: false,
    Msg_noOutflow: "At least one outflow (invest) with a non-zero number is needed.",
    startsWithZero: false,
    Msg_startsWithZero: "The cash flow sequence should not start with zero.",
    endsWithZero: false,
    Msg_endsWithZero: "The cash flow sequence should not end with zero. Please delete the last cash flow if it is zero.",
}

// Component
const FormIrr = () => {

    // states
    const [calculated, setCalculated] = useState(false);
    const [answer, setAnswer] = useState();
    const [excelFormula, setExcelForumla] = useState();
    const [copyAnswerButtonText, setAnswerButtonText] = useState("Copy");
    const [copyExcelButtonText, setExcelButtonText] = useState("Copy");
    const [errorMsg, setErrorMsg] = useState(defaultErrorState);

    // state to store data for writeXlsxFile
    const [writeXlsxFileData, setWriteXlsxFileData] = useState([[],[]]);

    // custom localStorage hook
    const [fromInputsFromLocalStorage, saveFromInputsToLocalStorage] = useLocalStorage("calculator-IRR", defaultValues);

    // reseting formik form
    const handleClearForm = (resetForm) => {       
        saveFromInputsToLocalStorage(defaultValues); // reset the form by resetting the default values 
        setErrorMsg(defaultErrorState);
        resetForm();
    }

    // Second validation (after formik's yup)
    const handleInput = async (values) => {

        // additional validation to ensure at least 1 inflow (collect) with non-zero number and 1 outflow (invest) non-zero number
        // storing the function sequence for IRR calculation in const inputArray
        let inflowCount = 0, outflowCount = 0;
        const { cashflows } = values;
        const inputArray = cashflows.map(cf => {
            const value = cf.amount === 0 ?  0 : (cf.invest ? -cf.amount : cf.amount) // -amount is Unary negation (-) -> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Unary_negation
            value > 0 && inflowCount++;
            value < 0 && outflowCount++;
            return value;
        }); 

        // setting error message if found error(s) 
        if (inflowCount === 0 || outflowCount === 0 || inputArray[0] === 0 || inputArray[inputArray.length-1] === 0) {
            setErrorMsg(prev => ({
                ...prev,
                hasError: true,
                noInflow: inflowCount === 0,
                noOutflow: outflowCount === 0,
                startsWithZero: inputArray[0] === 0,
                endsWithZero: inputArray[inputArray.length-1] === 0,
            }));
        } else { 
            // inputs are valid and calculate IRR, 
            const finance = new Finance();
            setAnswer(await finance.IRR(inputArray));
            setExcelForumla(`=IRR({${inputArray.join(", ")}})`);
            setWriteXlsxFileData(generate_writeXlsxData(inputArray));
            
            // reset error state & store input to localStorage 
            setErrorMsg(defaultErrorState);
            setCalculated(true);
            saveFromInputsToLocalStorage(values);
        } 
    }

    // handle Copy button to copy the excel formula to clipboard
    const handleCopyButton = async (type) => {

        if (type.toLowerCase() === "answer") {
            setAnswerButtonText("Copied!");
            setExcelButtonText("Copy!");
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(answer);
              } else {
                document.execCommand('copy', true, answer);
            }
            setTimeout(() => setAnswerButtonText("Copy"), 5000);
        } else if (type.toLowerCase() === "excel") {
            setExcelButtonText("Copied!");
            setAnswerButtonText("Copy");
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(excelFormula);
              } else {
                document.execCommand('copy', true, excelFormula);
            }
            setTimeout(() => setExcelButtonText("Copy"), 5000)
        }
    }

    // preparing the data in excel file
    const generate_writeXlsxData = (data) => {

        // setting the excel header
        const resultArray = [[{ value: "End of Yr", fontWeight: 'bold', align: 'center' }, 
                                { value: "Casflow", fontWeight: 'bold', align: 'center'}, 
                                { value: ""}, { value: "IRR", fontWeight: 'bold', align: 'center'}]];
        
        // create the irr forumla
        const irrFormula =  `=irr(B2:B${data.length+1})`;

        // enter the data and formula in the excel
        data.map((entry, index) => index === 0 
                                        ? resultArray.push([{ value: index, type: Number, align: 'center'}, { value: entry, type: Number, align: 'center'}, 
                                                    {value: ""}, {value: irrFormula, type: String, align: 'center'}])
                                        : resultArray.push([{ value: index, type: Number, align: 'center'}, { value: entry, type: Number, align: 'center'}])
        );
        return resultArray;
    }

    // handle Download button to download excel file
    const generate_writeXlsxFile = async () => {    
        const columns = [{ width: 20 },{ width: 20 },{},{width: 20 }];
        try {
            await writeXlsxFile(writeXlsxFileData, {columns, fileName: 'IRR.xlsx'})
        } catch (err) {
            alert("Error : " + err);
        }    
    }

    // render display
    return (
        <div className="grid">
            <div className='col-12 lg:col-8'>
                <div className="field card">                    
                    {
                        errorMsg.hasError 
                        ? <>
                            <h6 className="p-error">Take note!!!</h6>
                            <ol>
                            {
                                Object.keys(errorMsg).map( (key, i) =>{
                                    return (key !== "hasError" && !key.startsWith("Msg_") && errorMsg[key]) && <li key={i} className='p-error'>{errorMsg["Msg_" +  key]}</li>
                                })
                            }
                            </ol>
                            <div className='mt-6 mb-3'></div>
                        </>
                        : <h6>Create the cash flow sequence below</h6>
                    }

                    <Formik                        
                        initialValues={fromInputsFromLocalStorage}
                        enableReinitialize
                        validationSchema={cashflowValidation}
                        onSubmit={ values => handleInput(values)}
                    >
                        {
                            ({values, touched, setFieldValue, handleReset, handleSubmit} /* destructuring [props] */) => ( // props store all the formik functions
                                <>   
                                    {
                                        // console.log(values)
                                    }
                                    <FieldArray
                                        name="cashflows"
                                        render={ arrayHelpers => (
                                            <div>
                                                {
                                                    (values.cashflows && values.cashflows.length > 1) && values.cashflows.map((cashflow, index) => (
                                                        <div className='grid p-fluid' key={index}>
                                                            <div className="col-12">                                                                                                                                                                                         
                                                                <div>
                                                                    {((values.cashflows[index].amount === null)
                                                                        || (index === 0 && touched?.cashflows?.[0]?.amount && values.cashflows[0].amount === 0) 
                                                                        || (index === (values.cashflows.length-1) && touched?.cashflows?.[values.cashflows.length-1]?.amount && values.cashflows[values.cashflows.length-1].amount === 0)) 
                                                                        && <label htmlFor={`cf_${index}`} className="p-error block text-red-900 font-medium mb-2">Please enter a number @ end of yr {index}</label>}
                                                                </div>
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">@ end of yr {index}</span>
                                                                    <Button 
                                                                        label={cashflow["invest"] ? "Invest" : "Collect"} 
                                                                        disabled={calculated ? true : false}
                                                                        className={cashflow["invest"] ? "p-button-warning" : "p-button-success"}
                                                                        onClick={() => setFieldValue(`cashflows.${index}.invest`, !cashflow["invest"])}                                    
                                                                    /> 
                                                                    <span className="p-inputgroup-addon">$</span>                                                                                                                      
                                                                    <InputNumber 
                                                                        inputId={`cf_${index}`} 
                                                                        value={values.cashflows[index].amount} 
                                                                        min={0}                                                        
                                                                        onValueChange={(e) => setFieldValue(`cashflows.${index}.amount`, e.value)}
                                                                        mode="decimal" minFractionDigits={0} maxFractionDigits={2} disabled={calculated}
                                                                    />              
                                                                    <Button 
                                                                        icon="pi pi-arrow-up" className="p-button-primary" 
                                                                        disabled={(calculated || index === 0) ? true : false}
                                                                        onClick={()=> (index > 0) && arrayHelpers.swap(index, (index - 1))}
                                                                    />
                                                                    <Button 
                                                                        icon="pi pi-arrow-down" className="p-button-info" 
                                                                        disabled={(calculated || index === values.cashflows.length -1)? true : false}
                                                                        onClick={()=> arrayHelpers.swap(index, (index + 1))}
                                                                    />
                                                                    <Button 
                                                                        icon="pi pi-plus" className="p-button-success"
                                                                        disabled={calculated ? true : false}
                                                                        onClick={()=> arrayHelpers.insert((index + 1), {invest: true, amount: 0})}
                                                                    />
                                                                    <Button 
                                                                        icon="pi pi-times" className="p-button-danger" 
                                                                        disabled={(calculated || values.cashflows.length === 2) ? true : false}
                                                                        onClick={()=> (values.cashflows.length > 2) && arrayHelpers.remove(index)}
                                                                    />
                                                                </div>
                                                            </div>    
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    />
                                    <div className='grid mt-2'>
                                        <div className="col-12 md:col-6">
                                            <Button label={calculated ? "Edit" : "Clear"} aria-label="Clear" className="p-button-outlined w-full" 
                                            onClick={() => (calculated) ? setCalculated(false) : handleClearForm(handleReset)}/>
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <Button label={`Calculate IRR`} aria-label="Submit" className="w-full" onClick={() => handleSubmit()}/>
                                        </div>
                                    </div>   
                                </>
                            )
                        }
                    </Formik>
                </div>
            </div>
            <div className='col-12 lg:col-4'>            
                {
                    calculated
                        ? <>
                            {
                                isNaN(answer)
                                ?<>
                                    <h4 className="text-pink-500">Error!</h4>
                                    <h6>Invalid inputs or try smaller values</h6>
                                </>
                                : <>
                                    <div className="card">
                                        <h5>Answer:</h5>                                        
                                            <h5 className='text-primary'>The IRR is {formatDollar(answer * 100, 2)}%</h5>                                    
                                            <div className='flex flex-row-reverse'>
                                                <Button label={copyAnswerButtonText} icon="pi pi-copy" className="p-button-raised" onClick={() => handleCopyButton("answer")}/>
                                            </div>                    
                                    </div>
                                    <div className="card">
                                        <h5>MS Excel Formula:</h5>
                                        <h4 className='text-primary'>{excelFormula}</h4>
                                        <div className='flex flex-row-reverse'>
                                            <Button label={copyExcelButtonText} icon="pi pi-copy" className="p-button-raised" onClick={() => handleCopyButton("excel")}/>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <h5>MS Excel File:</h5>
                                        <div className='flex flex-row-reverse'>
                                            <Button label="Download" icon="pi pi-download" className="p-button-raised" onClick={() => generate_writeXlsxFile()} />
                                        </div>
                                        {
                                            /*
                                            <p>Research readings: </p>    
                                            <ol>
                                                <li className='mb-3'>                                                
                                                    <div>
                                                        {"write-excel-file (1 MB - Issue with S3) | "}
                                                        <a href='https://www.npmjs.com/package/write-excel-file' target="_blank" rel="noopener noreferrer">npm </a>
                                                        {" | "}                                                
                                                        <a href='https://gitlab.com/catamphetamine/write-excel-file' target="_blank" rel="noopener noreferrer">gitlab </a>

                                                    </div>                                                                                        
                                                </li>
                                                <li className="mb-3">                                
                                                    {"zipcelx (188kb - Issue with Webpack 2+) | "}
                                                    <a href='https://www.npmjs.com/package/zipcelx' target="_blank" rel="noopener noreferrer">npm </a>
                                                    {" | "}   
                                                    <a href='https://github.com/egeriis/zipcelx#readme' target="_blank" rel="noopener noreferrer">github </a>
                                                </li>
                                            </ol>  
                                            */
                                        }                                               
                                    </div>
                                </>
                            }                            
                        </>
                        :<>
                            <div className="card">
                                <h5>Use case(s):</h5>
                                <ol>
                                {
                                    useCases.map((useCase, index)=> <li key={index}>{useCase}</li>)
                                }
                                </ol>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default FormIrr

const useCases = [
    <>
        <h6>Endowment (3 pay 8)</h6>
        <p>Suppose a person purchase an 8-year endowment that is payable for 3 years. The annual premium is $5,000 and the maturity value at the end of the 8th year is $18,000.</p>
        <p>Hence the cashflow sequence is : [-5000, -5000 ,-5000 ,0 ,0 ,0 ,0 ,0 ,18000]. The effective interest rate is 2.64%(IRR)</p>
        <p></p>
    </>,
    <>
        <h6>Endowment with annual cashback</h6>
        <p>Suppose a person purchase a 10-year endowment with an annual premium of $3,000 payable for 5 years.</p>
        <p>The policy will pay a yearly cashback of $500 from the end of the 2nd year to the end of 9th policy year. There is also a lump sum of $14,800 upon maturity. </p>
        <p>Hence the cashflow sequence is : [-3000, -3000, -2500, -2500, -2500, 500, 500, 500, 500, 500, 14800]. 
              The effective interest rate is 3.28%(IRR)</p>
        <p></p>
    </>
]