import React from 'react';
import ReactDOM from 'react-dom/client';
import { /* BrowserRouter, */ HashRouter } from 'react-router-dom';

import App from './App';
// import reportWebVitals from './reportWebVitals';
// import AppWrapper from 'AppWrapper';

// custom context
import { UserContextProvider } from 'contexts/userContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserContextProvider>
        {
            // <React.StrictMode>
            // use HashRouter instead of BrowserRouter to avoid 404 error on page refresh
            // e.g. https://app.techvisor.io/#resetPassword/:token
        }
        <HashRouter>
            <App />
        </HashRouter>
        {
            //</React.StrictMode>
        }
    </UserContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals();
