import React from 'react';
import date from 'date-and-time';

import { Chart } from 'primereact/chart';

const FundPriceChart = (props) => {

    // extract data from props
    const { investmentPeriod, fundGrowthPA, fundInitalDrop, fundChartData, fundChartType } = props.sourceData;
    const chartTitle = `${fundChartType} Fund Price Chart (${investmentPeriod} Years @ ${Math.floor(fundGrowthPA * 100)}% p.a Growth)`
        + ((fundChartType === "Custom Growth") ? ` with ${Math.floor((1 - fundInitalDrop) * 100)}% Initial Drop)` : "")
    /*
        console.log("@ FundPriceChart")
        console.log("investmentPeriod: ", investmentPeriod)
        console.log("investmentPeriod: ", investmentPeriod)
        console.log("fundGrowthPA: ", fundGrowthPA)
        console.log("fundInitalDrop: ", fundInitalDrop)
        console.log("fundChartData: ", fundChartData)
        console.log("funcChartType: ", fundChartType) ;
    */

    const getLabelDates = () => {
        const currentMonth19th = new Date();
        currentMonth19th.setDate(19);

        const labelDates = [];
        for (let i = 0; i <= (investmentPeriod * 12); i++) {
            labelDates.push(date.format(date.addMonths(currentMonth19th, i), 'MMM YYYY'))
        }
        // labelDates.forEach((item, index) => console.log(`index: ${index}, item: ${item}`));
        return labelDates;
    }

    // chart data
    //  labels: fundChartData ? fundChartData.map((item, index) => `Month ${index}`) : [],
    const data = {
        labels: fundChartData ? getLabelDates() : [],
        datasets: [
            {
                label: 'Price',
                data: fundChartData,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderWidth: 0,
            },
        ]
    };

    // chart options
    const options = {
        animation: false, // this is needed because the shakes a lot when mouse over
        responsive: true,
        maintainAspectRatio: true,
        layout: {
            padding: {
                top: 0, // Increase top padding to create space between title and chart
            }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                }
            },
            y: {
                suggestedMin: 0, // Set the minimum value of Y-axis
                suggestedMax: Math.ceil(fundChartData ? fundChartData[fundChartData.length - 1] : 0), // Set the maximum value of Y-axis
                ticks: {
                    callback: function (value, index, values) {
                        return '$' + value;
                    },
                    stepSize: 2 // Adjust the step size of ticks on Y-axis
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: chartTitle,
                padding: {
                    bottom: 25 // Set bottom padding for the title
                }
            },
        },
    };

    return (
        <Chart type="bar" data={data} options={options} className="mb-3" />
    )
}

export default FundPriceChart;