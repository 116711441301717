import React, { useState, useEffect, useContext, useRef } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

// custom context
import { UserContext } from 'contexts/userContext';
import { DispatchUserContext } from 'contexts/userContext';

// PrimeReact 
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages';

// custom components
import LoadingScreen from 'components/LoadingScreen';

// custom method
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

const Verify2FA = () => {

  const [loading, setLoading] = useState(false);
  const [remainingLoginTime, setRemainingLoginTime] = useState(300); // 5 minutes in seconds

  // Ref for messages
  const messages = useRef(null);

  // userContext state & dispatch function
  const user = useContext(UserContext);
  const dispatch = useContext(DispatchUserContext);

  // navigate to other pages
  const navigate = useNavigate();

  // ensure validUser else log out
  useEffect(() => {
    if (!user.validUser || remainingLoginTime === 0) {
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      navigate("/");
    }
  }, [user, dispatch, navigate, remainingLoginTime]);

  // check if need to setup 2FA
  const setup2FA = user.qrCodeSetupReq && user.qrCodeDataURL ? true : false;
  
  // login countdown timer for 5 mins
  useEffect(() => {
    let timer;
    if (!setup2FA && remainingLoginTime > 0) {
      timer = setInterval(() => {
        setRemainingLoginTime(prevTime => prevTime - 1);
      }, 1000);
    } else if (remainingLoginTime === 0) {
      clearInterval(timer);
    }

    // unmount to clear interval
    return () => clearInterval(timer);
  }, [setup2FA, remainingLoginTime]);

  // to format the remaining login time at the button
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // custom hook
  const axiosInstance = useAxiosAuth();

  // formik instance
  const formik = useFormik({
    initialValues: { otp: "" },
    validate: (data) => (data.otp) ? {} : { otp: "Please key in the 2FA shown on your authentication app" },
    onSubmit: values => handleSubmit(values)
  })

  // function to go back to sign in page
  const handleLogOut = () => dispatch({ type: "LOGOUT" });

  // funtion to verify OTP
  const handleSubmit = async (values) => {

    // display loading screen
    setLoading(true);
    
    try {
      const res = await axiosInstance.post('non-auth/verifyOTP', { otp: values.otp, id: user?.userID });
      dispatch({ type: "LOADING", value: false });

      const { success, ...rest } = res?.data;
      if (success) {
        setLoading(false);
        dispatch({ type: "VALID_LOGIN", value: rest });
      } else {
        setLoading(false);
        messages.current.show({ severity: 'error', detail: `Invalid OTP. Please enter OTP again`, sticky: true })
        formik.resetForm();
      }
    } catch (error) {
      setLoading(false);
      primeMesagesErrorCatcher(error, messages);
    }
  }

  return (
    <div className="surface-card p-4 shadow-2 border-round sm:w-12 lg:w-4">
      <Messages ref={messages}></Messages>
      <div className="text-center mb-5">
        <i className="pi pi-user" style={{ fontSize: '2rem' }}></i>
        <div className="text-900 text-3xl font-medium mb-3 mt-2">
          2FA {setup2FA ? "Setup" : "Verification"}
        </div>
        <span className="text-600 font-medium line-height-3">
          {setup2FA ? "Please scan the above QR code with your authentication app" : "Please enter the password in your authentication app"}
        </span>
      </div>

      <div className='flex align-items-center justify-content-center mb-3'>
        {setup2FA && <img src={user.qrCodeDataURL} alt="QR Code" />}
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div>
          {displayFormikLabel("otp", setup2FA ? "Please enter 2FA below" : "", formik.touched, formik.errors)}
          <InputText id="otp" type="password" value={formik.values.otp} onChange={formik.handleChange} className="w-full mb-4" />
          <Button 
            type="submit" 
            label={setup2FA ? "Setup 2FA" : `Verify 2FA (${formatTime(remainingLoginTime)})`} 
            icon="pi pi-user" 
            className="w-full" />
          <div className="flex align-items-center justify-content-end mt-2">
            <a href="/#" className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={handleLogOut}>Log Out</a>
          </div>
        </div>
      </form>
      {loading && <LoadingScreen />}
    </div>
  )
}

export default Verify2FA