/**
 * Scrolls the window to the top of the specified DOM element with a smooth animation.
 *
 * @param {Object} ref - The reference to the DOM element to scroll to.
 */

const scrollToRef = (ref) => {
  window.scrollTo({
    top: ref.offsetTop,
    left: 0,
    behavior: "smooth",
  });
};

export default scrollToRef