import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup'

// prime react
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';

// user context
import { DispatchUserContext } from 'contexts/userContext';

// custom useLocalStorge hook
import useLocalStorage from 'hooks/useLocalStorage';

// custom helper method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import calculateAge from 'helpers/calculateAge';
import getPrimeDate from 'helpers/getPrimeDate';

// variable not needed for rendering
const today = new Date();
const genders = ["Female", "Male"];
const smokingStatus = ["Non-Smoker", "Smoker"];

// component
const FormInsuredDetails = () => {

    // state
    const [activeTabIndex, setActiveTabIndex] = useState(0); // 0 for DOB, 1 for ALB
    const [comparisonDialogVisible, setComparisonDialogVisible] = useState(false);
    const [insuredDetailsDialogVisible, setinsuredDetailsDialogVisible] = useState(false);

    // custom localStorage hook
    const [localInsuredDetails, setLocalInsuredDetails] = useLocalStorage(`comparison-InsuredDetails`, null);
    // console.log("localInsuredDetails");
    // console.log(localInsuredDetails);

    // context
    const dispatch = useContext(DispatchUserContext);

    // accessing locations (history of previous routes)
    const location = useLocation();

    // react router navigate to previous page (back button)
    const navigate = useNavigate();

    // display dialog panel to key in insured details
    useEffect(() => {
        if (location?.state?.toLowerCase().startsWith("from_compare")) {
            setinsuredDetailsDialogVisible(true);
        }
    }, [location])

    // formik
    const initialValues = localInsuredDetails
        ? {
            name: localInsuredDetails.name,
            gender: localInsuredDetails.gender,
            smokingStatus: localInsuredDetails.smokingStatus,
            dob: localInsuredDetails.dob ? getPrimeDate(localInsuredDetails.dob) : "",
            alb: localInsuredDetails.ALB,
        }
        : {
            name: "Valued Client",
            gender: "Female",
            smokingStatus: "Non-Smoker",
            dob: "",
            alb: 40,
        }

    const validationSchema = yup.object({
        name: yup.string().required("Please enter insured's name."),
        gender: yup.string().required("Please enter insured's gender."),
        smokingStatus: yup.string().required("Please select smoking status"),
        dob: activeTabIndex === 0 ? yup.string().typeError("Please enter date in DD/MM/YYYY").required("Please select insured's date of birth") : yup.string(),
        alb: activeTabIndex === 1 ? yup.number().required("Please enter insured's current age").min(0, "Minimum 0").max(100, "Allowed maximum is 100") : yup.number()
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    });

    const handleSubmit = (inputs) => {

        const values = { ...inputs };
        let age;
        if (activeTabIndex === 0) {
            const date = new Date(values.dob);
            const dob = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
            age = calculateAge(dob);
            values.dob = dob;
        } else {
            age = {
                ALB: +values.alb,
                ANB: +values.alb + 1,
                AnB: +values.alb + 1
            }
            delete values.dob;
        }

        delete values.alb;
        const insured = { ...age, ...values }

        dispatch({ type: "COMPARISON_SET_INSURED", value: { insured } })
        setLocalInsuredDetails(insured)
        setComparisonDialogVisible(true);
    }

    const handleReset = () => {
        formik.resetForm();
        formik.setFieldValue("alb", 0);
        formik.setFieldValue("dob", "");
        formik.setFieldValue("name", "");
        dispatch({ type: "COMPARISON_SET_INSURED", value: {} })
        setLocalInsuredDetails({})
    }

    return (
        <div className='grid'>
            <div className='col-12'>
                <div className='card'>
                    <h5>Input Insured Details for insurance comparison</h5>
                    <p>2 ways to input insured's age</p>
                    <ol>
                        <li>Date of birth (D.O.B)</li>
                        <li>Age last birthday (ALB)</li>
                    </ol>
                    <p>Other required fields</p>
                    <ul>
                        <li>Name</li>
                        <li>Gender</li>
                        <li>Smoking status</li>
                    </ul>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <div className='card'>
                    <h5>Enter Insured's details</h5>
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                        <TabPanel header="By D.O.B">
                            {displayFormikLabel("dob", "Select or key in date (DD/MM/YYYY):", formik.touched, formik.errors)}
                            <Calendar id="dob" dateFormat="dd/mm/yy" maxDate={today}
                                value={formik.values.dob} onChange={(e) => formik.setFieldValue("dob", e.value)} showWeek showButtonBar className="w-full mb-3" />
                        </TabPanel>
                        <TabPanel header="By ALB">
                            {displayFormikLabel("alb", "Insured's age last birthday:", formik.touched, formik.errors)}
                            <InputNumber id="alb" showButtons min="0" max="99" step="1" value={formik.values.alb} onChange={(e) => formik.setFieldValue("alb", e.value)} className="w-full mb-4" placeholder="Current age" />
                        </TabPanel>
                    </TabView>
                    {displayFormikLabel("name", "Name of insured:", formik.touched, formik.errors)}
                    <InputText id="name" type="text" value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} className="w-full mb-4" placeholder="Insured's name" />

                    {displayFormikLabel("gender", "Gender of insured:", formik.touched, formik.errors)}
                    <SelectButton id="gender" value={formik.values.gender} options={genders} onChange={(e) => formik.setFieldValue("gender", e.value)} className="mb-4" />

                    {displayFormikLabel("smokingStatus", "Smoking Status:", formik.touched, formik.errors)}
                    <SelectButton id="smokingStatus" value={formik.values.smokingStatus} options={smokingStatus} onChange={(e) => formik.setFieldValue("smokingStatus", e.value)} className="mb-5" />

                    <hr></hr>

                    <div className='grid mt-2'>
                        <div className='col-12 md:col-6'>
                            <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                        </div>
                        <div className='col-12 md:col-6'>
                            <Button type="submit" label="Submit" aria-label="Submit" className='w-full' onClick={formik.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <DisplayInsuredDetails />
            </div>
            <Dialog header="Comparison Menu" visible={comparisonDialogVisible} style={{ width: '80vw' }} onHide={() => setComparisonDialogVisible(false)}>
                <div className="grid pt-4">
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>101 Investment</b>
                        </div>
                    </Divider>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="101 ILP (Regular Premium)" aria-label="Retirement Income Plan" className='w-full' onClick={() => navigate('/comparison/101RP')} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="[Coming Soon!] 101 ILP (Single Premium)" aria-label="Retirement Income Plan" className='w-full' disabled />
                    </div>
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Endowment & Retirement</b>
                        </div>
                    </Divider>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Retirement Income Plan" aria-label="Retirement Income Plan" className='w-full' onClick={() => navigate('/comparison/retirementIncomePlan')} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Lifetime Income Endowment" aria-label="Lifetime Income Endowment" className='w-full' onClick={() => navigate('/comparison/lifetimeIncome')} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Whole Life Endowment" aria-label="Whole Life Endowment" className='w-full' onClick={() => navigate('/comparison/wholeLifeEndowment')} />
                    </div>
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Legacy Planning</b>
                        </div>
                    </Divider>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Indexed Universal Life" aria-label="Indexed Universal Life" className='w-full' onClick={() => navigate('/comparison/universalLifeIndex')} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="[Coming Soon!] Traditional Universal Life" aria-label="Traditional Universal Life" className='w-full' disabled />
                    </div>
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Protection Planning</b>
                        </div>
                    </Divider>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Term" aria-label="Term" className='w-full' onClick={() => navigate('/comparison/term')} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="MultiPay CI" aria-label="MultiPay CI" className='w-full' onClick={() => navigate('/comparison/multiCI')} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Whole Life Plan (Limited Pay)" aria-label="Limited Payment Whole Life" className='w-full' onClick={() => navigate('/comparison/wholeLife')} />
                    </div>
                    <Divider align="left">
                        <div className="inline-flex align-items-center">
                            <b>Accident & Health</b>
                        </div>
                    </Divider>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Integrated Shield" aria-label="Integrated Shield" className='w-full' onClick={() => navigate('/comparison/integratedShield')} />
                    </div>
                    <div className='mb-6'></div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Long Term Care" aria-label="Long Term Care" className='w-full' onClick={() => navigate('/comparison/longTermCare')} />
                    </div>
                </div>
            </Dialog>

            <Dialog visible={insuredDetailsDialogVisible}
                header={<div className="flex align-items-center">
                    <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style={{ width: '32px', height: '32px' }}>
                        <i className="pi pi-info text-lg"></i>
                    </span>
                    <span className="font-medium text-2xl text-900">No Insured Details</span>
                </div>}
                onHide={() => setinsuredDetailsDialogVisible(false)}
                footer={<div className=" border-top-1 surface-border pt-3">
                    <Button onClick={() => setinsuredDetailsDialogVisible(false)} label="Close" />
                </div >}
                modal
                breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }}>
                <ul>
                    <li><p className="line-height-3 p-0 m-0">Please enter insured's details before proceeding to comparison.</p></li>
                </ul>
            </Dialog>

        </div>
    )
}

export default FormInsuredDetails