import date from 'date-and-time';
import { getCurrentTime } from 'helpers/getCurrentTime';

const proAchiever_3 = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod, createExcel });

    // ====
    // * Validation
    // ====

    const allPremiumPaymentTerm = [10, 15, 20];
    if (!allPremiumPaymentTerm.includes(premiumPaymentTerm)) {
        return { availablity: false }
    }

    if (monthlyPremium < 200) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file 
    const excelFileName = `AIA Pro Archiever 3 ${getCurrentTime()}.xlsx`

    const excelHeader = [
        // One account model
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Month Premium', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (Beg)', align: "center" },
        { fontWeight: 'bold', value: '+ IUA Units purchased', align: "center" },
        { fontWeight: 'bold', value: '+ Welcome Bonus units', align: "center" },
        { fontWeight: 'bold', value: '+ Customer Promo units', align: "center" },
        { fontWeight: 'bold', value: '+ Special Bonus units', align: "center" },

        { fontWeight: 'bold', value: '- Supplementary Charge', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', align: "center" },

        { fontWeight: 'bold', value: '$ Value (IUA)', align: "center" },
    ]
    const excelData = [excelHeader];

    const createExcelSchema = () => excelHeader.map(item => ({ width: 28 }));

    // ====
    // * Bonus
    // ====

    const getWelcomeBonusRate = (monthlyPremium, premiumPaymentTerm, currentPolicyMonth) => {

        // currentPolicyMonth starts from 1 and currentPolicyYear > 3 => no welcome bonus
        if (currentPolicyMonth > 36) {
            return 0;
        }

        // currentPolicyYear also starts from 1
        const currentPolicyYear = Math.floor((currentPolicyMonth - 1) / 12) + 1;
        // console.log(`currentPolicyMonth: ${currentPolicyMonth} | currentPolicyYear: ${currentPolicyYear}`);

        // mothly premium >= 1000 => Tier 3, >= 600 => Tier 2, >= 400 => Tier 1, < 400 => Tier 0
        // Tier 3 is max and Tier 0 is min
        const bonusTier = monthlyPremium >= 1000 ? 3 : (monthlyPremium >= 600 ? 2: (monthlyPremium >= 400 ? 1 : 0));
        //console.log(`bonusTier: ${bonusTier}`);

        const welcomeBonus = [{
            IIP: 10, // 10 years premium payment term
            year1: [0.05, 0.05, 0.10, 0.15], // tier 0, 1, 2, 3 respectively
            year2: [0, 0.08, 0.13, 0.18],
            year3: [0, 0.1, 0.15, 0.2],
        },
        { IIP: 15, year1: [0.08, 0.08, 0.13, 0.18], year2: [0, 0.11, 0.16, 0.21], year3: [0, 0.14, 0.19, 0.24] },
        { IIP: 20, year1: [0.1, 0.1, 0.15, 0.2], year2: [0, 0.15, 0.2, 0.25], year3: [0, 0.2, 0.25, 0.3] }];

        const IIP = welcomeBonus.find(item => item.IIP === premiumPaymentTerm);
        const bonus = IIP[`year${currentPolicyYear}`][bonusTier];
        // console.table({ monthlyPremium, premiumPaymentTerm, currentPolicyYear, bonusTier, bonus });
        
        return bonus;
    }

    const getSpecialBonusRate = (currentPolicyMonth) => (currentPolicyMonth < 109) ? 0 : (currentPolicyMonth < 241) ? 0.05 : 0.08;

    // ======
    // Todo: Update promtion -> AIA Wealthbeing Promotion 2024 until 31/7/2024
    // ======
    const getPromotionalBonusRate = (monthlyPremium, currentPolicyMonth) => {
        if (currentPolicyMonth > 12) {
            return 0;
        }
        if (monthlyPremium >= 1167) {
            return 0.25;
        } 
        if (monthlyPremium >= 600) {
            return 0.20;
        }   
        if (monthlyPremium >= 400) {
            return 0.15;
        }
        if (monthlyPremium >= 200) {
            return 0.05;
        }
        return 0;
    }

    // ===
    // * Charges
    // ===

    const getSupplementaryChargesRate = (currentPolicyMonth) => (currentPolicyMonth < 121) ? (0.039 / 12) : 0;

    // ====
    // * Calculation
    // ====

    const calculationForpolicyAccount = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel) => {

        let previousMonthEndUnitBalance = 0; // one account only
        let totalInvestedPremium = 0;

        const totalMonths = investmentPeriod * 12;

        for (let i = 0; i < totalMonths; i++) {

            let excelRow = [];

            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate start up bonus units at the beginning of the month
            // Allocate special bonus units at the beginning of the month
            // Allocate customer promo bonus units at the beginning of the month
            // Allocate loyality bonus at the beginning of the month
            // Minus policy account charges at the beginning of the month

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // monthly premium
            if (i < premiumPaymentTerm * 12) {
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,##', align: "center" });
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
            }

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,##', align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // excelRow.push({ type: String, value: date.format(currentMonthStartDate, 'DD/MM/YYYY', false), align: "center"});

            // calculate the UT price at the beginning of the month
            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance = previousMonthEndUnitBalance;
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance_IUA: ", openingBalance_IUA);

            // plus purchase of the units at the beginning of month during premium payment term
            const premiumPaymentMonths = premiumPaymentTerm * 12;
            if (i < premiumPaymentMonths) {
                const unitsPurchased = monthlyPremium / UTprice;
                createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
                openingBalance += unitsPurchased;
                // console.log("unitsPurchased: ", unitsPurchased);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // plus start up bonus units only for 1st year
            if (i < 36) {
                const startUpBonus = monthlyPremium / UTprice * getWelcomeBonusRate(monthlyPremium, premiumPaymentTerm, i + 1 /* i+1 cos function's policyMonth starts from 1 instead of 0 */);
                createExcel && excelRow.push({ type: Number, value: startUpBonus, format: '#,##0.000000', align: "center" });
                openingBalance += startUpBonus;
                // console.log("startUpBonus: ", startUpBonus);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // ===============================
            // todo: update customer promo bonus units
            // ===============================
            if (i < 12) {
                const customerPromoBonus = monthlyPremium / UTprice * getPromotionalBonusRate(monthlyPremium, i + 1 /* i+1 cos function's policyMonth starts from 1 instead of 0 */);
                createExcel && excelRow.push({ type: Number, value: customerPromoBonus, format: '#,##0.000000', align: "center" });
                openingBalance += customerPromoBonus;
                // console.log("customerPromoBonus: ", customerPromoBonus);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // plus special bonus units
            const specialBonus = monthlyPremium / UTprice * getSpecialBonusRate(i + 1 /* i+1 cos function's policyMonth starts from 1 instead of 0 */);
            createExcel && excelRow.push({ type: Number, value: specialBonus, format: '#,##0.000000', align: "center" });
            openingBalance += specialBonus;
            // console.log("specialBonus: ", specialBonus);

            // minus policy account charges
            const policyCharge = getSupplementaryChargesRate(i + 1 /* i+1 cos function's policyMonth starts from 1 instead of 0 */) * openingBalance;
            createExcel && excelRow.push({ type: Number, value: (0 - policyCharge), format: '#,##0.000000', align: "center" });
            openingBalance -= policyCharge;
            // console.log("policyCharge: ", policyCharge);

            // calculate the date of the current month
            const currentMonthEndDate = date.addMonths(currentMonth1st, i + 1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // excelRow.push({ type: String, value: date.format(currentMonthEndDate, 'DD/MM/YYYY', false), align: "center"});

            // calculate the UT price at the end of the month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // calculate the end of month balance
            const endOfMonthBalance = openingBalance;
            createExcel && excelRow.push({ type: Number, value: endOfMonthBalance, format: '#,##0.000000', align: "center" });
            // console.log("endOfMonthBalance: ", endOfMonthBalance);

            // calculate the end of month value
            const endOfMonthValue = endOfMonthBalance * UTprice;
            createExcel && excelRow.push({ type: Number, value: endOfMonthValue, format: '$#,##0.00', align: "center" });
            // console.log("endOfMonthValue: ", endOfMonthValue);


            // store the excel row
            createExcel && excelData.push(excelRow);

            // update the previous month end unit balance
            previousMonthEndUnitBalance = openingBalance;

        } // end for totalMonths

        return { balance: previousMonthEndUnitBalance, totalInvestedPremium }
    }

    // ====
    // *One Account only
    // ====
    const { balance, totalInvestedPremium } = calculationForpolicyAccount(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel);
    // console.log("Initial Investment Account period of 24 months:", previousMonthEndUnitBalance_IUA);

    let totalValue = balance * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00

    return { insurer: "AIA", planName: "Pro Achiever 3.0", availablity: true, totalInvestedPremium, balance, totalValue, excelFileName, excelSchema: createExcelSchema(), excelData };
}

export default proAchiever_3;