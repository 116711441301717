import React, { useEffect, useContext, useState, useCallback, Suspense, useRef } from 'react'
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup'
import date from 'date-and-time';

// prime react
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { SelectButton } from 'primereact/selectbutton';
import { Messages } from 'primereact/messages';

// user context
import { UserContext } from 'contexts/userContext';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import RetirementIncomeChart from 'components/RetirementIncomeChart';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import scrollToTop from 'helpers/scrollToTop';
// import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
// import formatDollar from 'helpers/formatDollar';

// Retirement monthly income comparison insurers
import { insurer_retirementIncome, userContext_retirementIncome } from 'settings';

// dropdown values
const retirementAge = [
    { label: 'Around Age 60', age: 60 },
    { label: 'Around Age 65', age: 65 },
    // { label: 'Around Age 55', age: 55 },
];

const monthlyIncome = [
    { label: '$2,000', amt: 2000 },
    { label: '$1,000', amt: 1000 },
    { label: '$500', amt: 500 },
    // { label: '$750', amt: 750 },
];

const period = [
    { label: '20 Years', period: 20 },
    // { label: 'Around Age 99/100', period: "max" },
    // { label: '10 Years', period: "10yrs" },
];

const premiumTerm = [
    { label: '10 Years', period: 10 },
    { label: '5 Years', period: 5 },
    { label: 'Single Premium', period: 1 },
    // { label: 'Regular', period: "max" },
    // { label: '20 Years', period: "20yrs" },
    // { label: '15 Years', period: "15yrs" },
];

// formik
const initialValues = {
    retirementAge: 65,
    monthlyIncome: 1000,
    period: 20,
    premiumTerm: 5,
}

// options for the select view buttons
const viewOptions = [
    { icon: 'pi pi-book', value: 'default' },
    { icon: 'pi pi-dollar', value: 'value' },
    { icon: 'pi pi-percentage', value: 'kpis' },
];

// 19th of current month
const currentMonth19th = new Date(new Date().getFullYear(), new Date().getMonth(), 19);
currentMonth19th.setMinutes(currentMonth19th.getMinutes() - currentMonth19th.getTimezoneOffset()); // convert to UTC time

// calucate the start date of the payout
const getPayoutStartDate = (entry) => {

    const { policyTerm, incomePayOutPeriod, payoutMthlyOrYrly, payoutAtBegOrEnd } = entry;
    // console.log(`policyTerm: ${policyTerm}, incomePayOutPeriod: ${incomePayOutPeriod}, payoutMthlyOrYrly: ${payoutMthlyOrYrly}`, `payoutAtBegOrEnd: ${payoutAtBegOrEnd}`)
    // console.log("payoutSequence: ", payoutSequence);

    // set policy to start on 19th of current month
    const policyStartDate = new Date();
    policyStartDate.setHours(0, 0, 0, 0);
    policyStartDate.setDate(19)
    // console.log("Policy start date: ", policyStartDate)

    let payoutStartMonth, payoutStartDate;

    if (payoutMthlyOrYrly === "Monthly") {
        if (payoutAtBegOrEnd === "End") {
            payoutStartMonth = date.addMonths(policyStartDate, Math.round((policyTerm - incomePayOutPeriod) * 12 + 1));
            payoutStartDate = date.addDays(payoutStartMonth, -1)
        } else if (payoutAtBegOrEnd === "Beginning") {
            payoutStartMonth = date.addMonths(policyStartDate, Math.round((policyTerm - incomePayOutPeriod) * 12));
            payoutStartDate = date.addDays(payoutStartMonth, 0)
        }
    }

    if (payoutMthlyOrYrly === "Yearly") {
        const maturityDate = date.addYears(policyStartDate, policyTerm);
        if (payoutAtBegOrEnd === "End") {
            payoutStartMonth = date.addYears(maturityDate, -incomePayOutPeriod);
        } else if (payoutAtBegOrEnd === "Beginning") {
            payoutStartMonth = date.addYears(maturityDate, 0 - incomePayOutPeriod - 1);
            payoutStartDate = date.addDays(payoutStartMonth, 1)
        }
    }

    return payoutStartDate;
}

// ===
// main function
// ===
const CompareRetirementMonthlyIncome = () => {

    // Ref for messages
    const messages = useRef(null);

    const [query, setQuery] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
    const [loading, setLoading] = useState(false);

    // to select display policy values or KPIs
    const [view, setView] = useState('default');

    // data-table header states
    const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // user context
    const user = useContext(UserContext);

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width])

    useEffect(() => {
        if (!user.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps    

    // to activate after form is completed
    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const res = await axiosInstance.post('dashboard/comparison/retirementIncomePlan', query);
                if (res.data) {

                    // ======================================================
                    // Preparing the data from repsonse
                    // ======================================================

                    // console.log("---> res.data", res.data);
                    let premiumArr = res.data.result[0]; // array that contains all premium data point from mongo
                    const productArr = res.data.result[1];  // array that contains all product info for this category 
                    // console.log("---> productArr", productArr);

                    // sort premiumArr by premium (descending) follow by insurer name (ascending) name
                    premiumArr = sortArrayOfObjectsByKey(premiumArr, "insurer", "asc");
                    // console.log("---> premiumArr", premiumArr);

                    // ======================================================
                    // filter premiumArr by insurerList and create the final array to be displayed
                    // ======================================================
                    const combineArrData = premiumArr.map(entry => {

                        // getting the insurer & plan name to match the corresponding product info
                        const insurer = entry.insurer.trim();
                        const plan = entry.planName.split('(', 2)[0].trim();

                        // calculate the start date of the payout
                        const payoutStartDate = getPayoutStartDate(entry);
                        // console.log("payoutStartDate", payoutStartDate);

                        // to find the corresponding
                        const entryInfo = productArr.filter(p => p.insurerName === insurer && p.planName === plan)// end filter

                        return entryInfo.length > 0
                            ? { ...entry, policyStart: currentMonth19th, payoutStartDate, details: entryInfo[0], promo: entryInfo[0]?.customerPromo?.valid }
                            : { ...entry, policyStart: currentMonth19th, payoutStartDate, details: {}, promo: false, }

                    }) // end map
                    // console.log("combineArrData", combineArrData);

                    const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_retirementIncome)?.onlyDisplayInsurers;
                    // console.log("allowedInsurers", allowedInsurers);

                    // filter out the insurers that are not allowed to be displayed
                    // of the allowedInsurers is empty, display all insurers
                    (allowedInsurers && allowedInsurers.length > 0)
                        ? setDisplayData(() => combineArrData.filter(ele => allowedInsurers.includes(ele.insurer)))
                        : setDisplayData(() => combineArrData); // use the mongoData if allowedInsurers.length === 0 (display all insurers)

                    // close loading screen
                    setLoading(false);
                } 
            } catch (error) {
                // console.log(error);
                messages.current.show({ severity: 'error', detail: `Error: Backend server is down`, sticky: true });
                setLoading(false);
            }
        }
        query?.anb && getData();

    }, [axiosInstance, navigate, query, user])

    // formik validator with yup to check the value is not an empty string array based on active tab index
    const validationSchema = yup.object({
        retirementAge: yup.number().required("Please select a retirement age").typeError("Please select a retirement age"),
        monthlyIncome: yup.number().required("Please select a monthly income").typeError("Please select a monthly income"),
        period: yup.number().required("Please select a period").typeError("Please select a period"),
        premiumTerm: yup.number().required("Please select a premium term").typeError("Please select a premium term"),
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = (values) => {

        // console.log(values);
        setReadyToSearch(false);
        setShowEditButton(true);

        // console.log(values);
        // console.log(user.comparisonInsured);
        setQuery({
            anb: user.comparisonInsured.ANB,
            ageNearestBirthday: user.comparisonInsured.AnB,
            gender: user?.comparisonInsured?.gender?.charAt(0),
            smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
            ...values
        });
        scrollToTop();
    }

    const handleEdit = () => {
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    const handleReset = () => {
        setDisplayData([]);
        formik.setFieldValue("retirementAge", null);
        formik.setFieldValue("monthlyIncome", null);
        formik.setFieldValue("period", null);
        formik.setFieldValue("premiumTerm", null);
        setReadyToSearch(true);
        setShowEditButton(false);
        setQuery({});
    }

    const generateComparison = useCallback(() => {

        const currencyWholeNumber = { style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: 'USD' }
        const printMthlyOrYrly = (value) => value === "Monthly" ? " (M)" : (value === "Yearly" ? " (Y)" : ` ${value}`);

        // functions to format data in columns
        const formatPremiumTerm = (value) => `${value.premiumTerm} ${+value.premiumTerm === 1 ? "Yr" : "Yrs"}`;
        const formatPolicyStart = (value) => `${value?.policyStart.toLocaleDateString()}`;
        const formatPayoutStart = (value) => `${value?.payoutStartDate.toLocaleDateString()}`;

        const formatPayoutGtd = (value) => value?.payoutMinGtd.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + printMthlyOrYrly(value.payoutMthlyOrYrly);
        const formatPayoutTotal = (value) => value?.payoutMinTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + printMthlyOrYrly(value.payoutMthlyOrYrly);
        const formatspecialPayOut = (value) => value?.specialPayOut ? "Yes" : "No";
        const formatMaturityPayout = (value) => value?.maturityBenefit ? "Yes" : "No";

        const formatGtdPayout = (value) => value?.receivedGtd.toLocaleString('en-US', currencyWholeNumber);
        const formatTotalPayout = (value) => value?.receivedTotal.toLocaleString('en-US', currencyWholeNumber);
        const formatGtdYield = (value) => `${value?.xirr_gtd}% p.a.`;
        const formatTotalYield = (value) => `${value?.xirr_total}% p.a.`;
        const formatPolicyTerm = (value) => `${value?.policyTerm} Yrs`;

        // const formatAnnual = (value) => value.annualPremium.toLocaleString('en-US', currencyWholeNumber);
        const formatAnnual = (value) => value?.annualPremium.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatPromo = (value) => value?.promo ? "Y" : "";

        // function to expand all and collapse all expanders
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p._id}`] = true);
            setExpandedRows(_expandedRows);
        }

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        // function to display icon for select button
        const displayIcon = (option) => {
            return <i className={option.icon}></i>;
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                <span>
                    <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                    <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
                <span>
                    <SelectButton value={view} onChange={e => setView(() => e?.target?.value)} itemTemplate={displayIcon} optionLabel="value" options={viewOptions} className='inline' unselectable={false} />
                </span>
            </div>
        </>

        const rowExpansionTemplate = (data) => <>
            <DisplayProductDetails data={data} />
            <RetirementIncomeChart data={data} />
        </>

        return <div className='col-12'>
            <div className='card'>
                <DisplayComparisonTitle type="Retirement Income Plan" />
                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        // footer
                        footer={<DataTableFooter type="retirementIncome" query={query} />}

                        // expander props
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="_id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="insurer_shortName" header="Insurer" sortable></Column>
                        <Column field="plan_shortName" header="Plan" sortable></Column>

                        {view === "default" && <Column field="policyStartDate" header="Policy Start" body={formatPolicyStart} ></Column>}
                        {view === "default" && <Column field="payoutStartDate" header="Payout Start" body={formatPayoutStart} sortable></Column>}
                        {view === "default" && <Column field="payoutMthlyOrYrly" header="Payout Freq." sortable></Column>}
                        {view === "default" && <Column field="payoutMinGtd" header="Gtd Payout" body={formatPayoutGtd} sortable></Column>}
                        {view === "default" && <Column field="payoutMinTotal" header="Total Payout" body={formatPayoutTotal} sortable></Column>}
                        {view === "default" && <Column field="payoutLevelOrIncrease" header="Level / Increasing" sortable></Column>}

                        {view === "value" && <Column field="specialPayOut" header="Special Payout" body={formatspecialPayOut} sortable></Column>}
                        {view === "value" && <Column field="maturityBenefit" header="Maturity Payout" body={formatMaturityPayout} sortable></Column>}
                        {view === "value" && <Column field="receivedGtd" header={`Guaranteed Payout Received`} body={formatGtdPayout} sortable></Column>}
                        {view === "value" && <Column field="receivedTotal" header={`Total Payout Received`} body={formatTotalPayout} sortable></Column>}
                        {view === "value" && <Column field="policyTerm" header={`Policy Term`} body={formatPolicyTerm} sortable></Column>}

                        {view === "kpis" && <Column field="xirr_gtd" header="Guaranteed Yield (Xirr)" body={formatGtdYield} sortable></Column>}
                        {view === "kpis" && <Column field="xirr_total" header="Total Yield (Xirr)" body={formatTotalYield} sortable></Column>}

                        <Column field="premiumTerm" header="Premium Term" body={formatPremiumTerm} sortable></Column>
                        <Column field="annualPremium" header="Annual Premium" body={formatAnnual} sortable></Column>
                        {view === "default" && <Column field="promo" header="Promo" body={formatPromo} sortable></Column>}
                    </DataTable>
                </Suspense>
            </div>
        </div>

    }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, view, query]) // end of generateComparison (useCallback)

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }
            <div className='col-12 md:col-6'>
                <div className='card'>

                    <Messages ref={messages} />

                    <h5>Select retirement age</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.retirementAge}</p>}
                    <Dropdown value={formik.values.retirementAge} options={retirementAge} disabled={!readyToSearch} onChange={e => formik.setFieldValue("retirementAge", e.value)} optionLabel="label" optionValue="age" className='w-full' />

                    <h5>{`Select monthly income (Guaranteed)`}</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.monthlyIncome}</p>}
                    <Dropdown value={formik.values.monthlyIncome} options={monthlyIncome} disabled={!readyToSearch} onChange={e => formik.setFieldValue("monthlyIncome", e.value)} optionLabel="label" optionValue="amt" className='w-full' />

                    <h5>Select payout period</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.period}</p>}
                    <Dropdown value={formik.values.period} options={period} disabled={!readyToSearch} onChange={e => formik.setFieldValue("period", e.value)} optionLabel="label" optionValue="period" className='w-full' />

                    <h5>Select premium payment term</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.premiumTerm}</p>}
                    <Dropdown value={formik.values.premiumTerm} options={premiumTerm} disabled={!readyToSearch} onChange={e => formik.setFieldValue("premiumTerm", e.value)} optionLabel="label" optionValue="period" className='w-full' />

                    <div className='mt-5'>
                        <hr></hr>
                        <div className='grid mt-3'>
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                            </div>
                            {
                                showEditButton && <div className='col-12 md:col-4'>
                                    <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                                </div>
                            }
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="submit" label="Search" aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <DisplayInsuredDetails />
                <FormInsurerDisplayedOption comparisonType={userContext_retirementIncome} insurerList={insurer_retirementIncome} />
            </div>
            {loading && <LoadingScreen />}
        </div>
    )
}

export default CompareRetirementMonthlyIncome