import React, { useContext } from 'react'

// context
import { UserContext } from 'contexts/userContext';

const DisplayMembership = () => {

  // user context
  const user = useContext(UserContext);
  const date = new Date(user?.userMembership?.accExpiry);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const readableDate = `${day}/${month}/${year}`;

  return (
    <div className='field card'>
      <h5>Membership Details</h5>
      <p>Valid till: {readableDate}</p>
    </div>

  )
}

export default DisplayMembership