import React from 'react'
import { Route, Routes } from 'react-router-dom';

// custom component
import LoginRegister from "_public/LoginRegister";
import ForgetPassword from "_public/ForgetPassword";
import ResetPassword from "_public/ResetPassword";

const PublicRouter = () => {

  return (
    <Routes>
      <Route path="/" element={<LoginRegister />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/resetPassword/:token" element={<ResetPassword />} />
      <Route path="/*" element={<LoginRegister />} />
    </Routes>

  )

}

export default PublicRouter