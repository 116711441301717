// This file contains the logic to create the chart data for the investment chart

export const createPIChartData = (years = 30, i = 0.08) => {

    const prices = [];
    for (let x = 0; x <= years; x++) {
        prices.push(Math.pow(1 + i, x));
    }
    // console.log(prices);

    const graphPoints = [];
    for (let index = 0; index < prices.length; index++) {
        if (index !== prices.length - 1) {
            const start = prices[index];
            const end = prices[index + 1];
            const diff = end - start;
            const step = diff / 12;
            for (let j = 0; j < 12; j++) {
                const y = start + j * step;
                graphPoints.push(y);
            } // end inner for
        } // end if
    } // end outer for
    graphPoints.push(prices[prices.length - 1]);

    // graphPoints.forEach((value, index) => console.log(`Index: ${index}, y: ${value}`));
    // console.log("Length: " + graphPoints.length);

    return graphPoints;
}


// ===
// * createCustomChartData is a function that takes three parameters, y_Min, years, and i
// * y_Min is the minimum value of Y, 0.6 => fall by 40%
// * years is the number of years, 30 => 30 years
// * i is the interest rate, 0.08 => 8% p.a.
// * createCustomChartData returns an array of graph points
// ===
export const createCustomChartData = (y_Min = 0.6, years = 30, i = 0.07) => {

    const cycle = years >= 20 ? 3 : 2; // downturn cycle, 3 => 3 cycles

    let y_End = Math.pow(1 + i, years);
    // console.log("yEnd: ", y_End.toFixed(2));

    const result = getAlphaBeta(y_Min, y_End, cycle);
    // console.table(result);

    // const graphPoints = getGraphPoints(result.alpha, result.beta, years, cycle);
    // console.log("Length: " + graphPoints.length);

    // graphPoints.forEach((value, index) => console.log(`Index: ${index}, y: ${value}`));

    return getGraphPoints(result.alpha, result.beta, years, cycle);
}

const getAlphaBeta = (y_Min, y_End, cycle) => {

    // y = −αsin(2x) + βx + 1, where x is in radiant & 0<=x<=2π 
    // when x = 2π, y = y_End
    // β = (y_End - 1)/2π
    const beta = (y_End - 1) / (2 * Math.PI);

    for (let alpha = 0.01; alpha <= 100; alpha += 0.01) { // test value of alpha from 0.01 to 100

        for (let x = 0; x <= 2 * Math.PI; x += 0.01) { // test value of x from 0 to 2π

            // y = −αsin(cycle * x) + βx + 1
            // y = −αsin(cycle * x) + [(y_End - 1)/ (2 * Math.PI)]x + 1
            let y_Current = -alpha * Math.sin(cycle * x) + beta * x + 1;

            if (y_Current <= y_Min) { // hit the minimum value
                return { alpha, beta, cycle, x, y: y_Current };
            }
        }
    }
    // catch all
    console.log("No value found for alpha and x.");
    return { alpha: 0, beta: 0, cycle: 0, x: 0, y: 0 };
}

const getGraphPoints = (alpha, beta, years, cycle) => {

    let graphPoints = [];

    for (let index = 0; index <= years * 12; index++) {
        const x = index * (2 * Math.PI / (years * 12));
        const y = -alpha * Math.sin(cycle * x) + beta * x + 1;
        graphPoints.push(y);
    }
    return graphPoints;
}
