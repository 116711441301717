import { useState, useLayoutEffect } from 'react';

const getScreenSize = () => {
    return {width: window.innerWidth, height: window.innerHeight}
}

const useScreenSize = () => {
    // state to store the screen size
    const [screenSize, setScreenSize] = useState(getScreenSize());

    // useLayoutEffect to run whenever resize event is detected
    useLayoutEffect(() => {
      const handleScreenResize = () => setScreenSize(getScreenSize());
      window.addEventListener('resize', handleScreenResize);

      // unmount to remove the event listener
      return () => window.removeEventListener('resize', handleScreenResize);
    }, []);

    return screenSize;
}

export default useScreenSize