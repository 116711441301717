const userContextReducer = (state, action) => {

  const { type, value } = action;
  let temp;

  // console.log("============================")
  //console.log("type @ userContextReducer.js", type ?? "empty");
  //console.log("value @ userContextReducer.js", value ?? "empty");
  //console.log("============================")

  //========================
  // user context reducer
  //========================

  switch (type) {

    //========================
    // comparison functions
    //========================
    case "COMPARISON_SET_INSURED":
      const { insured } = value;
      // console.log("reducer -> insured", insured);
      return { ...state, comparisonInsured: insured }

    case "COMPARISON_SET_DISPLAY_SETTINGS":
      // console.log("value", value)

      // temp to store state's comparisonInsurerDisplaySettings
      temp = state.comparisonInsurerDisplaySettings;

      // find the index of the comparison type
      const index = temp.findIndex(ele => ele.comparisonType === value?.comparisonType);

      if (index < 0) {
        temp.push(value);
      } else {
        temp[index].onlyDisplayInsurers = value?.onlyDisplayInsurers;
      }
      return { ...state, comparisonInsurerDisplaySettings: temp }


    //========================
    // user update functions
    //========================
    case "UPDATE_USERINFO":
      const { name, mobile } = value;
      const newInfoState = { ...state };
      newInfoState.userDetails.mobile = mobile;
      newInfoState.userDetails.name = name;
      return newInfoState;

    case "UPDATE_USERPICS":
      const { arr, type } = value;
      const updatePicState = { ...state };
      updatePicState.userDetails[type] = arr;
      return updatePicState;

    case "ADD_USERPICS":
      const newPicState = { ...state };
      newPicState.userDetails[value.type].push(value.url);
      return newPicState;

    //========================
    // update social media accounts
    //========================
    case "UPDATE_SOCIAL":
      const newSocialState = { ...state };
      if (value) {
        try {
          newSocialState.comparisonDetails.socialMediaAccounts = value;
        } catch (error) {
          console.log(error)
        }
      }
      return newSocialState;

    //========================
    // login / logout functions
    //========================
    case "SET_JWT":
      return { ...state, userJWT: value }

    // for valid otp log in (after keying in 2FA)
    // clean up userID, qrcode in context
    case "VALID_LOGIN":
      // set login session to 2 hours
      const sessionEndsAt = new Date(Date.now() + 2 * 60 * 60 * 1000);

      if (state.qrCodeSetupReq || state.userID) {
        const newState = { ...state, validLogin: true, ...value, sessionEndsAt };
        delete newState.userID;
        delete newState.qrCodeSetupReq;
        delete newState.qrCodeDataURL;
        return newState
      }
      else
        return { ...state, validLogin: true, ...value, sessionEndsAt }

    // for successful log in user (before keying in 2FA) 
    case "VALID_USER":
      return { ...state, validUser: true, userID: value }

    // to set up 2FA for new user
    case "SETUP_2FA":
      return { ...state, validUser: true, ...value }

    // to log out
    case "LOGOUT":
      return { validUser: false };


    //========================
    // default case
    //========================
    default:
      return state;
  }
}

export default userContextReducer;
