import React, { useContext, useState } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'

// prime react
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

// context
import { UserContext } from 'contexts/userContext';

// custom components
import LoadingScreen from 'components/LoadingScreen';

// settings.js
import { comparison, clientComparisonURL } from 'settings';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// helper function  
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field

// custom helper function
const parseStringToDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const date = new Date();
    return date.setFullYear(year, month - 1, day);
};

// const regex = /^[A-Za-z0-9 .,'!&()->\n]+$/;
const regex = /^[A-Za-z0-9 .,'!&()\n-]+$/;

const findInvalidChars = (str) => {
    const invalidChars = [];
    for (let char of str) {
        if (!regex.test(char)) {
            invalidChars.push(char);
        }
    }
    return invalidChars;
};

const validationSchema = yup.object().shape({
    advisorRecommendation: yup.string().required('Advisor recommendation is required')
        .test('is-valid', function (value) {
            const { path, createError } = this;
            const invalidChars = findInvalidChars(value || '');
            if (invalidChars.length > 0) {
                return createError({ path, message: () => `Invalid characters: ${invalidChars.join(', ')}` });
            }
            return true;
        }),
    clientDescription: yup.string().required('Client description is required')
        .test('is-valid', function (value) {
            const { path, createError } = this;
            const invalidChars = findInvalidChars(value || '');
            if (invalidChars.length > 0) {
                return createError({ path, message: () => `Invalid characters: ${invalidChars.join(', ')}` });
            }
            return true;
        }),
});

// component
const DataTableFooter = (props) => {

    const { type, query } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [comparisonURL, setComparisonURL] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [buttonText, setButtonText] = useState("Copy Link");
    const [dialogFormState, setDialogFormState] = useState(true);

    // user context
    const user = useContext(UserContext);

    // get the comparisonInsurerDisplaySettings from user context for the current comparison type based on props.type
    let allowedInsurersArr = user?.comparisonInsurerDisplaySettings.find(ele => ele?.comparisonType === comparison[`userContext_${type}`])?.onlyDisplayInsurers;
    if (!allowedInsurersArr || allowedInsurersArr.length === 0) {
        allowedInsurersArr = comparison[`insurer_${type}`]; // if allowedInsurersArr is empty, display all insurers
    }

    // =======
    // formik
    // =======

    // formik
    const initialValues = {
        advisorRecommendation: "I recommend [insurer] because\n\n1) Comprehensive coverage\n2) Competitive pricing\n3) Good customer service and claim experience",
        clientDescription: `${user?.comparisonInsured?.name || "Client's name"} - [source]`
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleUpload(values)
    })

    /* 
     console.log("==================DataTableFooter==================");
     const { comparisonInsured } = user;
     console.log("user", user);
     console.log("type", type);
     console.log("query", query);
     console.log("RNF", user?.userDetails?.rnf);
     console.log("comparisonInsured", comparisonInsured);
     console.log("allowedInsurersArr", allowedInsurersArr);
     console.log("====================================");
     */

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // console.log("user?.comparisonInsured?.dob", user?.comparisonInsured?.dob);
    // console.log("@DataTableFooter dob:", (user?.comparisonInsured?.dob) ? new Date(parseStringToDate(user.comparisonInsured.dob)) : "null");

    const handleUpload = async (values) => {

        setLoading(true);

        const { advisorRecommendation, clientDescription } = values;

        const upload = {
            rnf: user?.userDetails?.rnf,
            type,
            allowedInsurersArr,
            clientName: user?.comparisonInsured?.name,
            clientGender: user?.comparisonInsured?.gender.charAt(0).toUpperCase(),
            clientSmoker: (user?.comparisonInsured?.smokingStatus === "Non-Smoker") ? false : true,
            clientDOB: (user?.comparisonInsured?.dob) ? new Date(parseStringToDate(user.comparisonInsured.dob)) : null,
            clientALB: user?.comparisonInsured?.ALB,
            colorScheme: user?.userUiSettings?.colorScheme ? user?.userUiSettings?.colorScheme : "light",
            componentTheme: user?.userUiSettings?.componentTheme ? user?.userUiSettings?.componentTheme : "blue",
            advisorRecommendation,
            clientDescription,
            ...query,
        }
        // console.table(upload);

        try {
            const res = await axiosInstance.post(`dashboard/comparisonUpload/${type}`, upload);
            // console.log("res.data ->", res.data)
            // console.log("user", user);

            if (res?.data && res.data?.success) {
                const onlineComparisonID = res.data?.id;
                setLoading(false);

                // todo: to change for production
                setComparisonURL(`${clientComparisonURL}/${user?.userDetails?.rnf}/${onlineComparisonID}`);

                setShowDialog(true);
            }
            setDialogFormState(false);
            setLoading(false);
        } catch (error) {
            // console.log(error);
            setDialogFormState(false);
            setLoading(false);
            alert("Error in connection. Please try again later.");
        }

        // setLoading(false);
        // setComparisonURL(`https://comparison.techvisor.io/${type}/${onlineComparisonID}`);
        // setShowDialog(true);
    }

    const handleHide = () => {
        setShowDialog(false);
        setDialogFormState(true);
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(comparisonURL);
        setButtonText("Copied!");
        setInterval(() => {
            setButtonText("Copy Link");
        }, 3000);
    }

    const handleVisit = () => {
        // open a new tab and go to link 
        const newWindow = window.open(comparisonURL, "_blank", "noreferrer");
        if (newWindow) {
            newWindow.focus();
        }
    }

    const dialogHeader = () => <div className="flex align-items-center">
        <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style={{ width: '32px', height: '32px' }}>
            <i className={dialogFormState ? `pi pi-pencil text-lg` : `pi pi-check text-lg`}></i>
        </span>
        <span className="font-medium text-2xl text-900">{dialogFormState ? "Enter Details" : "Upload"}</span>
    </div>

    const dialogFooter = () => <div className=" border-top-1 surface-border pt-3">
        {
            dialogFormState
                ? <Button icon="pi pi-external-link" onClick={formik.handleSubmit} label="Submit" />
                : <>
                    <Button icon="pi pi-copy" onClick={handleCopy} label={buttonText} className="p-button-text" />
                    <Button icon="pi pi-external-link" onClick={handleVisit} label="Visit Link" />
                </>
        }
    </div >

    return <>
        <div className="flex flex-row-reverse flex-wrap">
            <Button icon="pi pi-cloud-upload" onClick={() => setShowDialog(true)} label="Upload Online" />
        </div>
        <Dialog visible={showDialog} header={dialogHeader} onHide={handleHide} footer={dialogFooter} modal breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }}>
            {
                dialogFormState
                    ? <div className="p-3">
                        {displayFormikLabel("clientDescription", "Enter client's description for advisor's reference", formik.touched, formik.errors)}
                        <InputText id="clientDescription" value={formik.values.clientDescription} onChange={(e) => formik.setFieldValue("clientDescription", e.target.value)} className='w-full' />
                        <div className='mt-3'></div>
                        {displayFormikLabel("advisorRecommendation", "Enter a short recommendation for client", formik.touched, formik.errors)}
                        <InputTextarea value={formik.values.advisorRecommendation} onChange={(e) => formik.setFieldValue("advisorRecommendation", e.target.value)} rows={5} className='w-full' />
                    </div>
                    : <ul>
                        <li><p className="line-height-3 p-0 m-0">Comparison is uploaded online and is valid for 8 days</p></li>
                        <li><p className="line-height-3 p-0 m-0">Expires on {new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p></li>
                    </ul>
            }
        </Dialog>
        {loading && <LoadingScreen />}
    </>
}

export default DataTableFooter