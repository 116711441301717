import React, { useState, useContext, useRef } from 'react'
import { useFormik } from 'formik';

// prime react
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Messages } from 'primereact/messages';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// user context
import { UserContext, DispatchUserContext } from 'contexts/userContext';

// custom helper method 
import scrollToTop from 'helpers/scrollToTop';


const FormInsurerDisplayedOption = (props) => {

    // props
    const { comparisonType, insurerList } = props;

    // states
    const [displayCurrentInsurers, setDisplayCurrentInsurers] = useState(false); // false => display all insurers update form
    
    // useRef for Message
    const messages = useRef(null);
    const fieldSet = useRef(null);

    // context
    const user = useContext(UserContext);
    const dispatch = useContext(DispatchUserContext);
    // console.log('user', user);

    // extracting the insurers array that are allow to be displayed
    // if array is empty, all insurers will be displayed
    let onlyDisplayInsurerArray = [];
    if (user.comparisonInsurerDisplaySettings) {
        const currentSetting = user.comparisonInsurerDisplaySettings.find(setting => setting.comparisonType === comparisonType);
        if (currentSetting) {
            onlyDisplayInsurerArray = currentSetting.onlyDisplayInsurers;
        }     
    }

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    const uploadInsurerDisplaySettings = async (values) => {
        try {
            const res = await axiosInstance.post('dashboard/profile/comparisonDisplaySettings', values);
            if (res?.data) {

                // update context
                dispatch({ type: "COMPARISON_SET_DISPLAY_SETTINGS", value: values });

                // hide form
                setDisplayCurrentInsurers(false);

                // display success message
                messages.current.clear();
                messages.current.show({ severity: 'success', summary: '', detail: 'Update is successful!' })

                setTimeout(() => {
                    scrollToTop()
                }, 1000);

            }
        } catch (error) {
            // display unsuccessful message
            messages.current.clear();
            messages.current.show({ severity: 'error', summary: '', detail: 'Updated failed, please try again later' })
            console.log('error @ uploadInsurerDisplaySettings', error);
        }
    }

    // ===
    // display current insurers allowed 
    // ===

    const displayCurrentInsuresAllowed = () => <>
        <p>Only the following insurers are displayed:</p>
        <ul>
            {
                (onlyDisplayInsurerArray.length > 0 ? onlyDisplayInsurerArray : insurerList).map((insurer, index) => {
                    return <li key={index}>{insurer}</li>
                })
            }
        </ul>
        <div className='grid mt-3'>
            <div className="col-8"></div>
            <div className="col-4">
                <Button type="button" label="Update" aria-label="Update" className='w-full' onClick={() => setDisplayCurrentInsurers(prev => !prev)} />
            </div>
        </div>
    </>

    // ===
    // display insurers update form
    // ===

    // create formik's initial values
    const initialValues = {}
    insurerList.forEach(insurer => {
        initialValues[insurer] = onlyDisplayInsurerArray.includes(insurer);
    });

    // formik's submit function
    const handleSubmit = async () => {

        // console.log('formik values @ handleSubmit', formik.values);

        // count the number of true in formik.values, make sure more than 1 insurer is selected
        const countTrue = Object.values(formik.values).filter(value => value === true).length;
        if (countTrue === 0) {
            alert('Please select at least 1 insurer');
            return;
        }

        const value = {
            comparisonType,
            onlyDisplayInsurers: Object.keys(formik.values).filter(key => formik.values[key])
        }
        // console.log('value @ handleSubmit', value);

        // upload to server
        await uploadInsurerDisplaySettings(value);
    }

    // formik
    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit
    })

    const displayAllInsurers = () => <>

        <p>Select at least 1 insurer to display</p>
        {
            insurerList.map((insurer, index) => {
                return (
                    <div key={index + 100} className="field-checkbox ml-3">
                        <Checkbox
                            inputId={index + 100}
                            value={formik.values[insurer]}
                            checked={formik.values[insurer]}
                            onChange={e => formik.setFieldValue(insurer, !formik.values[insurer])}
                        />
                        <label htmlFor={index + 100}>{insurer}</label>
                    </div>
                )
            })
        }
        <div className='grid mt-3'>
            <div className="col-6">
                <Button type="button" label="Cancel" aria-label="Cancel" className='w-full p-button-outlined' onClick={() => setDisplayCurrentInsurers(prev => !prev)} />
            </div>
            <div className="col-6">
                <Button type="button" label="Confirm" aria-label="Confirm" className='w-full' onClick={handleSubmit} />
            </div>
        </div>
    </>

    return (
        <div className='card'>
            <h5>Settings</h5>

            <br></br>
            <Fieldset ref={fieldSet} legend="Display Options" toggleable collapsed={true}>
                <Messages ref={messages}></Messages>
                {
                    !displayCurrentInsurers
                        ? displayCurrentInsuresAllowed()
                        : displayAllInsurers()
                }
            </Fieldset>
        </div >
    )
}

export default FormInsurerDisplayedOption