import React, { useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';

// PrimeReact
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages';

// custom component
import LoadingScreen from 'components/LoadingScreen';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// custom method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

const initialValues = {
    email: "",
    otp: ""
}

// formik schema
const validationSchema = yup.object({
    email: yup.string().required('Email is required').email('Invalid email format'),
    otp: yup.string().required('2FA is required'),
});

// component
const ForgetPassword = () => {

    // state
    const [loading, setLoading] = useState(false);

    // hook to navigate to other page
    const navigate = useNavigate();

    // Ref for toast
    const messages = useRef(null);

    // custom hook
    const axiosInstance = useAxiosAuth();

    // formik instance  
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    // function to go back to sign in page
    const handleSignIn = () => {
        navigate("/");
    }

    // function to reset password
    const handleSubmit = async (values) => {

        // console.log("values", values);
        // display loading screen
        setLoading(true);
        try {
            const res = await axiosInstance.post('non-auth/resetPassword', values);
            if (res) {
                setLoading(false);
                messages.current.show({ severity: 'success', detail: `Redirecting to login page in 5 sec, you will receive an email in 5 mins`, sticky: true })
                setTimeout(() => navigate("/"), 5000);
            }
        } catch (error) {
            setLoading(false);
            primeMesagesErrorCatcher(error, messages);
        }
    }

    return (
        <div className='flex h-screen align-items-center justify-content-center'>
            <div className="surface-card p-4 shadow-2 border-round w-11 lg:w-6">
                <Messages ref={messages}></Messages>
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Forget Password</div>
                    <span className="text-600 font-medium line-height-3">We will email you a link to reset password if your credentials are correct</span>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div>
                        {displayFormikLabel("email", "Work Email", formik.touched, formik.errors)}
                        <InputText id="email" type="text" value={formik.values.email} onChange={formik.handleChange} className="w-full mb-2" />

                        {displayFormikLabel("otp", "2FA", formik.touched, formik.errors)}
                        <InputText id="otp" type="password" value={formik.values.otp} onChange={formik.handleChange} className="w-full mb-2" />

                        <Button label="Reset & Email Password" icon="pi pi-send" className="w-full mt-5 mb-1" />

                        <div className="flex align-items-center justify-content-end mb-6">
                            <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={handleSignIn} href="/">Back to Sign In</a>
                        </div>
                    </div>
                </form>
            </div>
            {loading && <LoadingScreen />}
        </div>
    )
}

export default ForgetPassword