import React, { useEffect, useContext, useState, useCallback, Suspense, useRef } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from 'primereact/selectbutton';
import { Messages } from 'primereact/messages';

// user context
import { UserContext } from 'contexts/userContext';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import formatDollar from 'helpers/formatDollar';
import scrollToTop from 'helpers/scrollToTop';

// Whole life insurance comparison
import { insurer_universalLifeIndex, userContext_universalLifeIndex } from 'settings';

// dropdown values
const sumInsured = [
  { label: "USD 1M", sumInsured: "1000K" },
  { label: "USD 5M", sumInsured: "5000K" },
];

const premiumTerm = [
  { label: "Single Premium", premiumTerm: 1 },
  { label: "3 Pay", premiumTerm: 3 },
  // { label: "5 Pay", premiumTerm: 5 },
  // { label: "10 Pay", premiumTerm: "10" },
];

const allocationList = [
  { label: '100% Index', indexAccountAllocation: 100 },
  // { label: '75% Index & 25 Fixed', indexAccountAllocation: 75 },
  { label: '50% Index & 50 Fixed', indexAccountAllocation: 50 },
  // { label: '25% Index & 75 Fixed', indexAccountAllocation: 25 },
  // { label: '100% Fixed', indexAccountAllocation: 0 },
]

// formik
const initialValues = {
  sumInsured: "1000K",
  premiumTerm: 1,
  allocationList: [100, 50],
}

// formik validator with yup to check the value is not an empty string array
const validationSchema = yup.object({
  sumInsured: yup.string().required("Please select a sum insured"),
  premiumTerm: yup.number().required("Please select a premium term"),
  allocationList: yup.array().of(yup.number()).required("Please select at least one allocation option").min(1, "Please select at least one allocation option"),
});

// options for the select view buttons
const viewOptions = [
  { icon: 'pi pi-book', value: 'default' },
  { icon: 'pi pi-chart-line', value: 'indexAcc' },
  { icon: 'pi pi-shield', value: 'fixedGeneralAcc' },
  { icon: 'pi pi-dollar', value: 'values' },
];

// helper function
const getAgePointsBefore100 = (age) => {

  const arr = []
  for (let i = age; i <= 100; i += 20) {
    i < 100 && arr.push({ name: `Around Age ${i}`, value: i })
  }
  // remove the first element in arr
  arr.shift();
  arr.push({ name: `Around Age 100`, value: 100 });
  return arr;
}


const CompareUniversalLifeIndex = () => {

  // Ref for messages
  const messages = useRef(null);

  // state
  const [query, setQuery] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [readyToSearch, setReadyToSearch] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
  const [loading, setLoading] = useState(false);
  const [viewSelectAge, setViewSelectAge] = useState(100);
  const [viewAgeOptions, setViewAgeOptions] = useState([{ name: `Around Age 100`, value: 100 }])

  // to select display policy values or KPIs
  const [view, setView] = useState('default');

  // data-table header states
  const [expandedRows, setExpandedRows] = useState(null);
  const [mobileScreen, setMobileScreen] = useState(false)
  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const [filters2, setFilters2] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // user context
  const user = useContext(UserContext);

  // useEffect to check if insured details is in comparison context
  const navigate = useNavigate();

  // custom axios instance
  const axiosInstance = useAxiosAuth();

  // check screen size to decide what label to display
  const screenSize = useScreenSize();

  // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
  useEffect(() => {
    setMobileScreen(screenSize.width <= 600);
  }, [screenSize.width])

  useEffect(() => {
    // return to insured details page if insured details are not entered
    if (!user.comparisonInsured) {
      return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  // to activate after form is completed
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.post('dashboard/comparison/universalLifeIndexed', query);

        if (res.data) {
          let premiumArr = res.data.result[0]; // array that contains all premium data point from mongo
          premiumArr = sortArrayOfObjectsByKey(premiumArr, "insurer", "asc"); // sort by insurer name

          premiumArr = premiumArr.map(entry => {
            // premiumArr array contains has a summary array that store the different indexAccountAllocation, we need to fitler out the summary array based on values in query.allocationList
            entry.summary = entry.summary.filter(summary => query.allocationList.includes(summary.indexAccountAllocation));
            return entry
          });
          // console.log('premiumArr length:', premiumArr.length);
          // premiumArr.forEach((premium, index) => console.log(index, premium));

          const productArr = res.data.result[1];  // array that contains all product info for this category 
          // console.log('productArr length:', productArr.length);
          // productArr.forEach((product, index) => console.log(index, product));

          // ====
          // combine the premium data with the product info
          // ====
          const combineArrData = premiumArr.map(entry => {

            // getting the insurer & plan name to match the corresponding product info
            const { insurer, planName } = entry;
            // console.log(`insurer: ${insurer}, plan: ${planName}`);

            // to find the corresponding
            const entryInfo = productArr.filter(p => p.insurerName === insurer && p.planName === planName)// end filter
            // console.log('entryInfo length', entryInfo.length);
            // console.log('entryInfo', entryInfo);

            return entryInfo.length > 0
              ? { ...entry, details: entryInfo[0], promo: entryInfo[0]?.customerPromo?.valid }
              : { ...entry, details: {}, promo: false, }
          }) // end map
          // console.log('combineArrData:', combineArrData);

          const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_universalLifeIndex)?.onlyDisplayInsurers;
          // console.log("allowedInsurers", allowedInsurers);

          const displayArr = [];
          combineArrData.forEach(entry => {
            for (let i = 0; i < entry.summary.length; i++) {
              const obj = { ...entry, ...entry.summary[i] };
              delete obj.summary;
              if (allowedInsurers.includes(obj.insurer)) { // only display insurers that are allowed
                displayArr.push(obj);
              }
            }
          });
          // console.log('displayArr length:', displayArr.length);
          // displayArr.forEach((entry, index) => console.log(index, entry));
          setDisplayData(displayArr);
          setLoading(false);
        }
      } catch (error) {
        // console.log(error);
        messages.current.show({ severity: 'error', detail: `Error: Backend server is down`, sticky: true });
        setLoading(false);
      }
    } // end getData
    query.anb && getData();

  }, [axiosInstance, navigate, query, user]);

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => handleSubmit(values)
  })

  // checkbox formilk functions
  // check if the box's value is selected based on formik values (formik.values.allocationList)
  const isChecked = amt => (formik.values.allocationList).some((item) => item === amt);

  // check if the select amount is in the list
  const handleListChange = amt => (formik.values.allocationList).some((item) => item === amt)
    ? formik.setFieldValue("allocationList", formik.values.allocationList.filter((item) => item !== amt)) // if yes, remove from list
    : formik.setFieldValue("allocationList", [...formik.values.allocationList, amt]); // if no, add to list

  const handleSubmit = (values) => {
    // console.log('values', values);
    // const { sumInsured, premiumTerm, allocationList } = values;
    setQuery({
      anb: user.comparisonInsured.ANB,
      ageNearestBirthday: user.comparisonInsured.AnB,
      gender: user?.comparisonInsured?.gender?.charAt(0),
      smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
      ...values
    });

    setViewAgeOptions(getAgePointsBefore100(user.comparisonInsured.ANB - 1));
    setReadyToSearch(false);
    setShowEditButton(true);
    scrollToTop();
  }

  const handleReset = () => {
    setDisplayData([]);
    // formik.handleReset();
    formik.setFieldValue("sumInsured", initialValues.sumInsured);
    formik.setFieldValue("premiumTerm", initialValues.premiumTerm);
    formik.setFieldValue("allocationList", []);
    setReadyToSearch(true);
    setShowEditButton(false);
  }

  const handleEdit = () => {
    setReadyToSearch(true);
    setShowEditButton(false);
  }

  const generateComparison = useCallback(() => {

    // functions to format data in columns
    const formatSA = (value) => "$" + formatDollar(value.sumInsured);
    const formatCurrency = (value) => value.premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    const formatPromo = (value) => value.promo ? "Y" : "";
    const formatIndexAcc = (value) => value?.indexAccountAllocation + "%";
    const formatFixedAcc = (value) => value?.fixedAccountAllocation + "%";
    const formatLapseAge = (value) => "Around Age " + value?.lapseAge;
    const formatPremiumTerm = (value) => value?.premiumTerm === 1 ? "Single Pay" : value?.premiumTerm + " Pay";

    const formatfixedGeneralAccCurrent = (value) => value?.fixedGeneralAccCurrent.toFixed(2) + "% p.a.";
    const formatFixedGeneralAccMin = (value) => value?.fixedGeneralAccMin.toFixed(2) + "% p.a.";

    const formatIndexAccCurrent = (value) => value?.indexAccCurrent.toFixed(2) + "% p.a.";
    const formatIndexAccMin = (value) => value?.indexAccMin.toFixed(2) + "% p.a.";
    const formatIndexAccMax = (value) => value?.indexAccMax.toFixed(2) + "% p.a.";

    // const formatGuaranteedSurrenderValue = (value) => "$" + formatDollar(value?.guaranteedSurrenderValue);
    const formatGuaranteedSurrenderValue = (value) => {
      const { illustration } = value;
      const data = illustration.find(i => i.age === viewSelectAge);
      return `$${formatDollar(data?.guaranteedSurrenderValue)}`;
    }

    const formatCurrentSurrenderValue = (value) => {
      const { illustration } = value;
      const data = illustration.find(i => i.age === viewSelectAge);
      return `$${formatDollar(data?.currentSurrenderValue)}`;
    }

    // function to expand all and collapse all expanders
    const collapseAll = () => setExpandedRows(null);

    const expandAll = () => {
      let _expandedRows = {};
      displayData.forEach(p => _expandedRows[`${p._id}`] = true);
      setExpandedRows(_expandedRows);
    }

    // filter function for key search
    const onGlobalFilterChange2 = (e) => {
      const value = e.target.value;
      let _filters2 = { ...filters2 };
      _filters2['global'].value = value;

      setFilters2(_filters2);
      setGlobalFilterValue2(value);
    }

    // function to display icon for select button
    const displayIcon = (option) => {
      return <i className={option.icon}></i>;
    }

    const renderHeader = () => <>
      <div className="flex justify-content-between">
        <span>
          <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
          <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder={mobileScreen ? "Search" : "Keyword Search"}
            className={mobileScreen ? "p-inputtext-sm" : ""}
            value={globalFilterValue2}
            onChange={onGlobalFilterChange2}
          />
        </span>
        <span>
          <SelectButton value={view} onChange={e => setView(() => e?.target?.value)} itemTemplate={displayIcon} optionLabel="value" options={viewOptions} className='inline' unselectable={false} />
        </span>
      </div>
      {
        view === "values" && <div className="flex justify-content-end mt-2">
          <Dropdown value={viewSelectAge} onChange={(e) => setViewSelectAge(e.value)} options={viewAgeOptions} optionLabel="name" placeholder="Compare at Age" className="w-full md:w-12rem" />
        </div>
      }
    </>

    const rowExpansionTemplate = (data) => {
      // console.log('rowExpansionTemplate data:', data);
      return <DisplayProductDetails data={data} />
    }

    return <div className='col-12'>
      <div className='card'>
        <DisplayComparisonTitle type="Indexed Universal Life" />
        <Suspense fallback={<div>Loading Comparison Table...</div>}>
          <DataTable value={displayData}
            stripedRows
            responsiveLayout="scroll"

            // header
            header={renderHeader()}

            // footer
            footer={<DataTableFooter type="universalLifeIndex" query={query} />}

            // expander props
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="_id"

            // keyword search props
            filters={filters2} filterDisplay="row"
          >
            <Column expander style={{ width: '3em' }} />
            <Column field="insurer_shortName" header="Insurer" sortable></Column>
            {view === 'default' && <Column field="plan_shortName" header="Plan" sortable></Column>}
            <Column field="sumInsured" header="Insured Amt (USD)" body={formatSA} sortable></Column>

            {(view === 'default' || view === 'values') && <Column field="indexAccountAllocation" header="Index Acc" sortable body={formatIndexAcc}></Column>}
            {(view === 'default' || view === 'values') && <Column field="fixedAccountAllocation" header="Fixed Acc" sortable body={formatFixedAcc}></Column>}
            {view === 'default' && <Column field="lapseAge" header="Worst Case Lapse Age" sortable body={formatLapseAge}></Column>}

            {view === 'indexAcc' && <Column field="indexAccType" header="Index Acc Type" sortable ></Column>}
            {view === 'indexAcc' && <Column field="indexAccCurrent" header="Index Acc (PI Projection)" sortable body={formatIndexAccCurrent}></Column>}
            {view === 'indexAcc' && <Column field="indexAccMin" header="Index Acc (Min. Guaranteed)" sortable body={formatIndexAccMin}></Column>}
            {view === 'indexAcc' && <Column field="indexAccMax" header="Index Acc (Capping)" sortable body={formatIndexAccMax}></Column>}

            {view === 'fixedGeneralAcc' && <Column field="fixedGeneralAccCurrent" header="Fixed/General Acc (Current Rate)" sortable body={formatfixedGeneralAccCurrent} ></Column>}
            {view === 'fixedGeneralAcc' && <Column field="fixedGeneralAccMin" header="Fixed/General Acc (Min.)" sortable body={formatFixedGeneralAccMin}></Column>}

            <Column field="premiumTerm" header="Premium Term" body={formatPremiumTerm} sortable></Column>
            <Column field="premium" header="Annual Premium (USD)" body={formatCurrency} sortable></Column>

            {view === 'values' && <Column header={`Min. Surrender Value (Age ${viewSelectAge})`} sortable body={formatGuaranteedSurrenderValue}></Column>}
            {view === 'values' && <Column header={`Current Projected Value (Age ${viewSelectAge})`} sortable body={formatCurrentSurrenderValue}></Column>}

            <Column field="promo" header="Promo" body={formatPromo} sortable></Column>
          </DataTable>
        </Suspense>
      </div>
    </div >

  }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, view, viewAgeOptions, viewSelectAge, query])

  // console.log('formik', formik.values);
  // console.log('query', query);
  // console.log('view', view);
  // console.log('displayData', displayData);
  // console.log("viewSelectAge", viewSelectAge);

  return (
    <div className='grid'>
      {
        !readyToSearch && generateComparison()
      }
      <div className='col-12 md:col-6'>
        <div className='card'>

          <Messages ref={messages} />
          
          <h5>Select sum insured</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.sumInsured}</p>}
          <Dropdown value={formik.values.sumInsured} options={sumInsured} onChange={e => formik.setFieldValue("sumInsured", e.value)} optionLabel="label" optionValue="sumInsured" className='w-full' disabled={!readyToSearch} />

          <h5>Select premiumTerm</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.premiumTerm}</p>}
          <Dropdown value={formik.values.premiumTerm} options={premiumTerm} onChange={e => formik.setFieldValue("premiumTerm", e.value)} optionLabel="label" optionValue="premiumTerm" className='w-full' disabled={!readyToSearch} />

          <h5>Select Index & Fixed account allocation</h5>
          {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.allocationList}</p>}
          {allocationList.map((allocation) => {
            return (
              <div key={allocation.indexAccountAllocation} className="field-checkbox">
                <Checkbox
                  inputId={allocation.indexAccountAllocation}
                  value={allocation.indexAccountAllocation}
                  checked={isChecked(allocation.indexAccountAllocation)}
                  disabled={!readyToSearch}
                  onChange={e => handleListChange(e.value)}
                />
                <label htmlFor={allocation.indexAccountAllocation} className="ml-2">
                  {allocation.label}
                </label>
              </div>
            );
          })}

          <div className='mt-5'>
            <hr></hr>
            <div className='grid mt-3'>
              <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
              </div>
              {
                showEditButton && <div className='col-12 md:col-4'>
                  <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                </div>
              }
              <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                <Button type="submit" label="Search" aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 md:col-6'>
        <DisplayInsuredDetails />
        <FormInsurerDisplayedOption comparisonType={userContext_universalLifeIndex} insurerList={insurer_universalLifeIndex} />
      </div>
      {loading && <LoadingScreen />}
    </div>
  )
}

export default CompareUniversalLifeIndex