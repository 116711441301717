import React from 'react';

const DasboardFooter = () => {

    const year = new Date().getFullYear();
    
    return (
        <div className="layout-footer flex-row-reverse">
            <span className="copyright">&#169; Techvisor - {year}</span>
        </div>
    );
};

export default DasboardFooter;
