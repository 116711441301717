import date from 'date-and-time';
import { getCurrentTime } from 'helpers/getCurrentTime';

const investFlex = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.log("Start of investFlex")
    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod, createExcel });

    // ====
    // * Validation
    // ====

    const allPremiumPaymentTerm = [5, 10, 15, 20];
    // console.log("allPremiumPaymentTerm: ", allPremiumPaymentTerm);
    if (!allPremiumPaymentTerm.includes(premiumPaymentTerm)) {
        return { availablity: false }
    }

    const allMinimumPremiums = [
        { premiumPaymentTerm: 5, type: "Fixed", minPremium: 800 },
        { premiumPaymentTerm: 10, type: "Fixed", minPremium: 500 },
        { premiumPaymentTerm: 15, type: "Flexible", minPremium: 300 },
        { premiumPaymentTerm: 20, type: "Flexible", minPremium: 200 },
    ]

    const minPremium = allMinimumPremiums.find(item => item.premiumPaymentTerm === premiumPaymentTerm).minPremium;
    // console.log("minPremium: ", minPremium);

    if (monthlyPremium < minPremium) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file
    const excelFileName = `Income Invest Flex ${getCurrentTime()}.xlsx`;

    // Header for the Excel file
    const excelHeader = [
        // One account model
        { fontWeight: 'bold', value: 'Month (Index)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Monthly Premium', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', width: 28, align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Allocation Amount', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'UT Price (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Account Units (Beg)', width: 28, align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ IUA Units Purchased', width: 28, align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Investment Bonus Units', width: 28, align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Promotion Bonus Units', width: 28, align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Loyalty Bonus Units', width: 28, align: "center" }, // one day after begining of the month (after minus of policy fee charge)

        { fontWeight: 'bold', value: '- Policy Fee Charge', width: 28, align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: 'Account Units (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'UT Price (End)', width: 28, align: "center" },

        { fontWeight: 'bold', value: 'Account Value', width: 28, align: "center" }
    ];

    const excelData = [excelHeader];
    const excelSchema = excelHeader.map(item => ({ width: 28 })) // create the Excel schema

    // const annualPremium = Math.round(monthlyPremium * 12);
    // const totalMonths = Math.round(investmentPeriod * 12);
    // const totalPremiumMonths = Math.round(premiumPaymentTerm * 12);

    let totalInvestedPremium = 0;
    // console.log(`annualPremium: ${annualPremium} | totalMonths: ${totalMonths} | totalPremiumMonths: ${totalPremiumMonths}`);

    // ====
    // * Bonus
    // ====

    const getAllocationAmount = (monthlyPremium, policyMonthIndex, mip) => {

        const allocationRate = (policyMonthIndex < 120)
            ? 1
            : (policyMonthIndex <= 240) ? 1.02 : 1.05;

        return Math.floor(monthlyPremium * allocationRate);
    }

    const getInvestmentBonusrate = (monthlyPremium, mip, policyMonthIndex) => {

        // console.table({monthlyPremium, mip, policyMonthIndex});

        if (policyMonthIndex >= 12) { // no start up bonus after the first year
            return 0;
        }

        const annualPremium = Math.floor(monthlyPremium * 12);
        const allInvestmentBonusData = [
            { mip: 5, cutOff: 9600, belowCuttOff: 0, aboveCuttOff: 0.06 },
            { mip: 10, cutOff: 9600, belowCuttOff: 0.1, aboveCuttOff: 0.25 },
            { mip: 15, cutOff: 9600, belowCuttOff: 0.15, aboveCuttOff: 0.45 },
            { mip: 20, cutOff: 9600, belowCuttOff: 0.3, aboveCuttOff: 0.6 },
        ];

        const data = allInvestmentBonusData.find(item => item.mip === mip);
        const rate = (annualPremium < data.cutOff) ? data.belowCuttOff : data.aboveCuttOff;
        return rate;
    }

    const getLoyaltyBonusRate = (mip, policyMonthIndex) => {

        if (policyMonthIndex < 120 || policyMonthIndex < (mip * 12 - 1) || policyMonthIndex % 12 !== 0) {
            return 0;
        }

        /*
        const loyaltyBonusData = [
            { mip: 5, rate: 0.005 },
            { mip: 10, rate: 0.005 },
            { mip: 15, rate: 0.005 },
            { mip: 20, rate: 0.005 },
        ];
        const data = loyaltyBonusData.find(item => item.mip === mip);
    
        return data.rate;
        */

        return 0.005;
    }

    // ====
    // todo: promotion dates is 1 july 2024 to 31 July 2024
    // ====
    const getPromotionBonusRate = (mip, monthlyPremium, policyMonthIndex) => {

        if (policyMonthIndex > 12) {
            return 0;
        }

        if ((mip === 15 || mip === 20) && monthlyPremium >= 550) {
            return 0.05; // 5%
        } else if ((mip === 5 || mip === 10) && monthlyPremium >= 550) {
            return 0.04; // 4%
        } else if ((mip === 15 || mip === 20) && monthlyPremium >= 350) {
            return 0.03; // 3%
        } else {
            return 0;
        }

    }
    // ====
    // * Calculation
    // ====

    const calculationForpolicyAccount = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, excelData, createExcel) => {

        let previousMonthEndUnitBalance = 0; // one account only
        const totalMonths = investmentPeriod * 12;

        for (let i = 0; i < totalMonths; i++) {

            let excelRow = [];

            // ====
            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate start up bonus units at the beginning of the month
            // Allocate customer promo bonus units at the beginning of the month
            // Minus policy account charges at the beginning of the month
            // ====

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // monthly premium
            if (i < premiumPaymentTerm * 12) {
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,##', align: "center" });
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
            }

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,##', align: "center" });
            // console.log("totalInvestedPremium: ", totalInvestedPremium);

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate);

            // allocation amount
            const allocationAmount = (i < premiumPaymentTerm * 12) ? getAllocationAmount(monthlyPremium, i, premiumPaymentTerm) : 0;
            createExcel && excelRow.push({ type: Number, value: allocationAmount, format: '$#,##0.00', align: "center" });
            // console.log("allocationAmount: ", allocationAmount);

            // UT price at the beginning of the month
            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance = previousMonthEndUnitBalance;
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance: ", openingBalance);

            // plus purchase of the units at the beginning of month during premium payment term
            const premiumPaymentMonths = premiumPaymentTerm * 12;
            if (i < premiumPaymentMonths) {
                const unitsPurchased = allocationAmount / UTprice;
                createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
                openingBalance += unitsPurchased;
                // console.log("unitsPurchased: ", unitsPurchased);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // investment bonus for 12 policy momths
            if (i < 12) {
                const investmentBonusRate = getInvestmentBonusrate(monthlyPremium, premiumPaymentTerm, i);
                const investmentBonusUnits = investmentBonusRate * (monthlyPremium / UTprice);
                // console.log("investmentBonus: ", investmentBonus, " | UTprice: ", UTprice, " | investmentBonusUnits: ", investmentBonusUnits);
                createExcel && excelRow.push({ type: Number, value: investmentBonusUnits, format: '#,##0.000000', align: "center" });
                openingBalance += investmentBonusUnits;
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // ================
            // todo: Update promotion bonus for 12 policy months
            // ================
            if (i < 12) {
                const promotionBonusRate = getPromotionBonusRate(premiumPaymentTerm, monthlyPremium, i + 1); // +1 because the first month index is zero
                const promotionBonusUnits = promotionBonusRate * monthlyPremium / UTprice;
                createExcel && excelRow.push({ type: Number, value: promotionBonusUnits, format: '#,##0.000000', align: "center" });
                openingBalance += promotionBonusUnits;
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // loyalty bonus starts immediately after MIP. Pay out yearly at the beginning of the policy year
            let loyaltyBonusRate = getLoyaltyBonusRate(premiumPaymentTerm, i);
            let loyaltyBonusUnits = openingBalance * loyaltyBonusRate;
            createExcel && excelRow.push({ type: Number, value: loyaltyBonusUnits, format: '#,##0.000000', align: "center" });
            // * Note: Loyalty bonus is not added because need to add all other charges that is also deducted from the beginning of policy month 

            // policy fee charge of 2.5% p.a. from year 1 to 10, year 11 onwards is 0.6% p.a. of the account value at the beginning of the month
            const policyFeeCharge = openingBalance * (i < 120 ? 0.025 : 0.006) / 12;
            createExcel && excelRow.push({ type: Number, value: -policyFeeCharge, format: '#,##0.000000', align: "center" });
            // * Note: administrative charge is not added because need to add all other charges that is also deducted from the beginning of policy month 

            // adding loyalty bonus units - administrative charge - supplementary charge (all at the beginning of the month)
            openingBalance += loyaltyBonusUnits - policyFeeCharge;

            // update the end of month unit balance
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });

            // calculate the date of the end of the month
            const currentMonthEndDate = date.addMonths(currentMonth1st, i + 1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate);

            // UT price at the end of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // calculate the account value at the end of the month
            const accountValue = openingBalance * UTprice;
            createExcel && excelRow.push({ type: Number, value: accountValue, format: '$#,##0.00', align: "center" });

            // store the excel row
            createExcel && excelData.push(excelRow);

            // update the previous month end unit balance
            previousMonthEndUnitBalance = openingBalance;

        } // end for loop

        return { balance: previousMonthEndUnitBalance, excelData };
    }

    // ====
    // One Account only (Regular Premium Account)
    // ====

    const result = calculationForpolicyAccount(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, excelData, createExcel);
    const { balance } = result;
    let totalValue = balance * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00
    // console.log("totalValue @ eTiQa_InvestSmart: ", totalValue);
    // console.log("excelData @ eTiQa_InvestSmart: ", excelData);

    return { insurer: "Income", planName: `Invest Flex`, availablity: true, totalInvestedPremium, balance, totalValue, excelSchema, excelFileName, excelData: result.excelData }

} // end of investFlex

export default investFlex;