import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// css
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

// user context
import { UserContext } from 'contexts/userContext';

// custom component 
import Dashboard from '_dashboard/Dashboard';
import AuthRouter from '_auth/AuthRouter';
import PublicRouter from '_public/PublicRouter';

const App = () => {

    // ==========================
    // always scroll to the top
    // ==========================
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    // ==========================
    // user context
    // hard code the user details in userContext.js's initalValues
    // ==========================
    const user = useContext(UserContext);

    // ==========================
    // state and useEffect to check user browser. Only allow Safari & Chrome
    // ==========================
    const [supportedBrowser, setSupportedBrowser] = useState(true);
    useEffect(() => {

        let abortController = new AbortController();

        const browser = window.navigator.userAgent;
        if (browser.indexOf("Safari") < 0 && browser.indexOf("Chrome") < 0) {
            setSupportedBrowser(false);
        }
        return () => {
            abortController.abort();
        }
    }, []);

    if (!supportedBrowser) {
        return <h1>Unsupported Browser!!! Please use Chrome or Safari.</h1>
    }

    // ==========================
    // display router based on logged in status
    // ==========================
    const displayRouter = () => {
        return user.validUser
            ? (user.validLogin ? <Dashboard /> : <AuthRouter />)
            : <PublicRouter />
    }

    // ==========================
    // dev use
    // ==========================
    // console.log("user context @ app.js", user);

    return <>
        {
            displayRouter()
        }
    </>
}

export default App