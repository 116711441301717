/**
 * Sorts an array of objects by a specified key in ascending or descending order.
 *
 * @param {Array} array - The array of objects to sort.
 * @param {string} key - The key to sort the objects by.
 * @param {string} [order='asc'] - The order to sort the objects in. Can be 'asc' or 'desc'.
 * @returns {Array} - The sorted array of objects.
 */
const sortArrayOfObjectsByKey = (array, key = "", order = "asc") => {
    return array.sort((a, b) => {
        let x = a[key];
        let y = b[key];
        return (order.toLowerCase() === 'asc') 
            ? ((x < y) ? -1 : ((x > y) ? 1 : 0))
            : ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}
export default sortArrayOfObjectsByKey;