import date from 'date-and-time';
import { getCurrentTime } from 'helpers/getCurrentTime';

const wealthVoyage = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.log("Start of Wealth Voyage")
    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod, createExcel });

    // ====
    // * Validation
    // ====

    const allPremiumPaymentTerm = [15, 20, 25];
    if (!allPremiumPaymentTerm.includes(premiumPaymentTerm)) {
        return { availablity: false }
    }

    if (monthlyPremium < 300) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file 
    const excelFileName = `HSBC Wealth Voyage ${getCurrentTime()}.xlsx`

    // Header for the Excel file
    const excelHeader = [
        // One account model
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Invested Amount', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT Price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'Account Units (Beg)', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ IUA Units Purchased', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Start Up Bonus Units', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '+ Customer Promo Units', align: "center" }, // beginning of the month
        { fontWeight: 'bold', value: '- Account Maintenance Fee', align: "center" }, // beginning of the month

        { fontWeight: 'bold', value: '+ Power-up Bonus Units', align: "center" }, // end of the month
        { fontWeight: 'bold', value: '+ Loyalty Bonus Units', align: "center" }, // end of the month
        { fontWeight: 'bold', value: 'Account Units (End)', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT Price (End)', align: "center" },
        { fontWeight: 'bold', value: 'Account Value', align: "center" }
    ];

    const excelData = [excelHeader];
    const excelSchema = excelHeader.map(item => ({ width: 28 })) // create the Excel schema

    // const annualPremium = Math.round(monthlyPremium * 12);
    const totalMonths = Math.round(investmentPeriod * 12);
    const totalPremiumMonths = Math.round(premiumPaymentTerm * 12);

    let totalInvestedPremium = 0;
    // console.log(`annualPremium: ${annualPremium} | totalMonths: ${totalMonths} | totalPremiumMonths: ${totalPremiumMonths} | totalInvestedPremium: ${totalInvestedPremium}`)


    // ====
    // * Bonus 
    // ====

    const getStartUpBonus = (monthlyPremium, mip, policyMonthIndex) => {

        // * (1st 2 years only) Start up bonus calculation: Start up bonus rate * regular premium

        // console.table({monthlyPremium, mip, policyMonthIndex});
        if (policyMonthIndex > 24) { // no start up bonus after 2 years
            // console.log("policyMonthIndex > 24: ", policyMonthIndex);
            return 0;
        }

        const allMIP = [15, 20, 25];
        if (!allMIP.includes(mip)) {
            // console.log("mip not in allMIP: ", mip);
            return 0;
        }

        const monthlyPremiumLessThanl1000 = monthlyPremium < 1000;
        const policyYear = Math.floor(policyMonthIndex / 12) + 1;
        // console.log("monthlyPremiumLessThanl1000:", monthlyPremiumLessThanl1000, " | policyYear: ", policyYear);

        const startUpBonusData = [
            { monthlyPremiumLessThanl1000: true, policyYear: 1, mip15: 0.15, mip20: 0.2, mip25: 0.25 },
            { monthlyPremiumLessThanl1000: true, policyYear: 2, mip15: 0.15, mip20: 0.25, mip25: 0.45 },
            { monthlyPremiumLessThanl1000: false, policyYear: 1, mip15: 0.2, mip20: 0.2, mip25: 0.30 },
            { monthlyPremiumLessThanl1000: false, policyYear: 2, mip15: 0.2, mip20: 0.4, mip25: 0.5 },
        ]

        const startUpBonusRow = startUpBonusData.find(item => item.monthlyPremiumLessThanl1000 === monthlyPremiumLessThanl1000 && item.policyYear === policyYear);
        // console.log("startUpBonusRow: ", startUpBonusRow);

        // Start up bonus calculation: Annual premium * start up bonus rate
        return Math.floor(monthlyPremium * startUpBonusRow[`mip${mip}`]);
    }

    const getPowerUpBonusRate = (mip, policyMonthIndex) => {

        // * Power-up Bonus starting from the end of the first Policy Month of the respective Policy Year   
        // * Power-up Bonus calculation: Power-up Bonus rate / 12 * Account Value at the end of the month

        const policyYear = Math.floor(policyMonthIndex / 12) + 1;
        // console.table({ mip, policyMonthIndex, policyYear });

        if (policyYear <= 3 || policyYear > 25) { // no start up bonus before 3 years and after 25 years
            // console.log("policyYear < 3 || policyYear > 25: ", policyYear);
            return 0;
        }

        const allMIP = [15, 20, 25];
        if (!allMIP.includes(mip)) {
            // console.log("mip not in allMIP: ", mip);
            return 0;
        }

        const powerUpBonusData = [
            { policyYear: 3, mip15: 0, mip20: 0.001, mip25: 0.001 }, // no power up bonus in 3rd year
            { policyYear: 4, mip15: 0, mip20: 0.001, mip25: 0.001 },
            { policyYear: 5, mip15: 0, mip20: 0.001, mip25: 0.001 },
            { policyYear: 6, mip15: 0.002, mip20: 0.003, mip25: 0.004 }, // power up bonus starts from 6th year onwards mip15: 0.2%, mip20: 0.3%, mip25: 0.4%
            { policyYear: 7, mip15: 0.002, mip20: 0.003, mip25: 0.004 },
            { policyYear: 8, mip15: 0.002, mip20: 0.003, mip25: 0.004 },
            { policyYear: 9, mip15: 0.002, mip20: 0.003, mip25: 0.004 },
            { policyYear: 10, mip15: 0.002, mip20: 0.003, mip25: 0.004 },
            { policyYear: 11, mip15: 0.002, mip20: 0.005, mip25: 0.007 }, // power up bonus increases from 11th year onwards mip15: 0.2%, mip20: 0.5%, mip25: 0.7%
            { policyYear: 12, mip15: 0.002, mip20: 0.005, mip25: 0.007 },
            { policyYear: 13, mip15: 0.002, mip20: 0.005, mip25: 0.007 },
            { policyYear: 14, mip15: 0.002, mip20: 0.005, mip25: 0.007 },
            { policyYear: 15, mip15: 0.002, mip20: 0.005, mip25: 0.007 },
            { policyYear: 16, mip15: 0, mip20: 0.007, mip25: 0.008 }, // power up bonus increases from 16th year onwards mip15: 0%, mip20: 0.7%, mip25: 0.8%
            { policyYear: 17, mip15: 0, mip20: 0.007, mip25: 0.008 },
            { policyYear: 18, mip15: 0, mip20: 0.007, mip25: 0.008 },
            { policyYear: 19, mip15: 0, mip20: 0.007, mip25: 0.008 },
            { policyYear: 20, mip15: 0, mip20: 0.007, mip25: 0.008 },
            { policyYear: 21, mip15: 0, mip20: 0, mip25: 0.01 }, // power up bonus increases from 21st year onwards mip15: 0%, mip20: 0%, mip25: 1%
            { policyYear: 22, mip15: 0, mip20: 0, mip25: 0.01 },
            { policyYear: 23, mip15: 0, mip20: 0, mip25: 0.01 },
            { policyYear: 24, mip15: 0, mip20: 0, mip25: 0.01 },
            { policyYear: 25, mip15: 0, mip20: 0, mip25: 0.01 },
        ];

        const powerUpBonusRow = powerUpBonusData.find(item => item.policyYear === policyYear);
        // console.log("powerUpBonus:", powerUpBonusRow[`mip${mip}`]);

        return powerUpBonusRow[`mip${mip}`];
    }

    const getLoyaltyBonusRate = (mip, policyMonthIndex) => {

        const policyYear = Math.floor(policyMonthIndex / 12) + 1;
        // console.table({ policyMonthIndex, policyYear, mip });

        if (policyYear <= mip) {
            return 0;
        }

        const loyaltyBonusData = [
            { mip: 15, rate: 0.01 },
            { mip: 20, rate: 0.011 },
            { mip: 25, rate: 0.012 },
        ];
        // console.log("loyaltyBonusRate: ", loyaltyBonusData.find(item => item.mip === mip).rate);

        return loyaltyBonusData.find(item => item.mip === mip).rate;
    }

    // ====
    // * Charges
    // ====

    const getAccountMaintenanceFee = (monthlyPremium, mip, policyMonthIndex) => {

        const policyYear = Math.floor(policyMonthIndex / 12) + 1;
        // console.table({ policyMonthIndex, policyYear, mip });

        const duringMIP = (policyYear <= mip);

        const accountMaintenanceFeeData = [
            { duringMIP: true, mip15: 0.023, mip20: 0.0215, mip25: 0.0215 },
            { duringMIP: false, mip15: 0.01, mip20: 0.01, mip25: 0.01 },
        ];

        const accountMaintenanceFeeRate = accountMaintenanceFeeData[duringMIP ? 0 : 1][`mip${mip}`];
        // console.log("accountMaintenanceFeeRate: ", accountMaintenanceFeeRate);

        // AMF calculation: AMF rate/12 * (Higher of annual premium @ commencement or current year) * (lower of policy year or MIP)
        return accountMaintenanceFeeRate * monthlyPremium * Math.min(policyYear, mip);
    }

    // ====
    // * Calculation
    // ====

    const calculationForpolicyAccount = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, excelData, createExcel) => {

        let previousMonthEndUnitBalance = 0; // one account only

        for (let i = 0; i < totalMonths; i++) {

            let excelRow = [];

            // ====
            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate start up bonus units at the beginning of the month
            // Allocate customer promo bonus units at the beginning of the month
            // Minus policy account charges at the beginning of the month
            // ====

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            if (i < totalPremiumMonths) {
                // monthly investment
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,###', align: "center" });
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            } else {
                // monthly investment
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
            }

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,###', align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate);

            // UT price at the beginning of the month
            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance = previousMonthEndUnitBalance;
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance: ", openingBalance);

            // plus purchase of the units at the beginning of month during premium payment term
            const premiumPaymentMonths = premiumPaymentTerm * 12;
            if (i < premiumPaymentMonths) {
                const unitsPurchased = monthlyPremium / UTprice;
                createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
                openingBalance += unitsPurchased;
                // console.log("unitsPurchased: ", unitsPurchased);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // plus start up bonus units only for 1st 2 year
            if (i < 24) {
                const startUpBonusInDollars = getStartUpBonus(monthlyPremium, premiumPaymentTerm, i);
                const startUpBonusInUnits = startUpBonusInDollars / UTprice;
                // console.log("startUpBonusInDollars: ", startUpBonusInDollars, " | UTprice: ", UTprice, " | startUpBonusInUnits: ", startUpBonusInUnits);
                createExcel && excelRow.push({ type: Number, value: startUpBonusInUnits, format: '#,##0.000000', align: "center" });
                openingBalance += startUpBonusInUnits;

            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // plus customer promo bonus units only. No customer promo bonus units for Wealth Voyage at this time
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });

            // minus account maintenance fee
            const accountMaintenanceFee = getAccountMaintenanceFee(monthlyPremium, premiumPaymentTerm, i);
            const accountMaintenanceFeeInUnits = -accountMaintenanceFee / UTprice;
            // console.log("accountMaintenanceFee: ", accountMaintenanceFee, " | accountMaintenanceFeeInUnits: ", accountMaintenanceFeeInUnits);
            createExcel && excelRow.push({ type: Number, value: accountMaintenanceFeeInUnits, format: '#,##0.000000', align: "center" });
            openingBalance += accountMaintenanceFeeInUnits;

            // ====
            // * At end of the month
            // Allocate power-up bonus units at the end of the month
            // Allocate loyalty bonus units at the end of the month
            // ====

            UTprice = fundPriceData[i + 1]; // UT price at the end of the month

            // plus power-up bonus units
            const powerUpBonusRates = getPowerUpBonusRate(premiumPaymentTerm, i);
            // console.log("powerUpBonusRates: ", powerUpBonusRates);
            const powerUpBonusInUnits = openingBalance * powerUpBonusRates / 12;
            // console.log("powerUpBonusInUnits: ", powerUpBonusInUnits);
            createExcel && excelRow.push({ type: Number, value: powerUpBonusInUnits, format: '#,##0.000000', align: "center" });
            openingBalance += powerUpBonusInUnits;

            // plus loyalty bonus units
            const loyaltyBonusRate = getLoyaltyBonusRate(premiumPaymentTerm, i);
            // console.log("loyaltyBonusRate: ", loyaltyBonusRate);
            const loyaltyBonusInUnits = openingBalance * loyaltyBonusRate / 12;
            // console.log("loyaltyBonusInUnits: ", loyaltyBonusInUnits);
            createExcel && excelRow.push({ type: Number, value: loyaltyBonusInUnits, format: '#,##0.000000', align: "center" });
            openingBalance += loyaltyBonusInUnits;

            // update the end of month unit balance
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });

            // calculate the date of the end of the month
            const currentMonthEndDate = date.addMonths(currentMonth1st, i + 1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate);

            // UT price at the end of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // calculate the account value at the end of the month
            const accountValue = openingBalance * UTprice;
            createExcel && excelRow.push({ type: Number, value: accountValue, format: '$#,##0.00', align: "center" });

            // store the excel row
            createExcel && excelData.push(excelRow);

            // update the previous month end unit balance
            previousMonthEndUnitBalance = openingBalance;

        }
        return { balance: previousMonthEndUnitBalance, excelData };
    }

    // ====
    // One Account only (Regular Premium Account)
    // ====

    const result = calculationForpolicyAccount(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, excelData, createExcel);
    const { balance } = result;
    let totalValue = balance * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00
    // console.log("totalValue @ eTiQa_InvestSmart: ", totalValue);
    // console.log("excelData @ eTiQa_InvestSmart: ", excelData);

    return { insurer: "HSBC Life", planName: "Wealth Voyage", availablity: true, totalInvestedPremium, balance, totalValue, excelFileName, excelSchema, excelData: result.excelData }

} // end of wealthVoyage

export default wealthVoyage;
