import date from 'date-and-time';
import { getCurrentTime } from "helpers/getCurrentTime";

const goClassic = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod });

    // ====
    // * Validation
    // ====

    const premiumTerm_Min = 5;
    const premiumTerm_Max = 25;
    const minimumMonthlyPremium = 630;

    // check if premiumPaymentTerm is within the available range
    if (premiumPaymentTerm < premiumTerm_Min || premiumPaymentTerm > premiumTerm_Max) {
        return { availablity: false }
    }

    // check if premium is within the available range
    if (monthlyPremium < minimumMonthlyPremium) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file 
    const excelFileName = `TM goClasic ${getCurrentTime()}.xlsx`

    const excelHeader = [
        // Investment Unit Account (IUA)
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Monthly Premium', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },
        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (Beg)', align: "center" },
        { fontWeight: 'bold', value: '+ IUA Units purchased', align: "center" },
        { fontWeight: 'bold', value: '+ Initial Bonus units', align: "center" },
        { fontWeight: 'bold', value: '+ Promo Booster units', align: "center" },
        { fontWeight: 'bold', value: '- IUA Charge', align: "center" },
        { fontWeight: 'bold', value: '- Policy Charge', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', align: "center" },
        { fontWeight: 'bold', value: '$ Value (IUA)', align: "center" },
        { fontWeight: 'bold', value: '', align: "center" }, // space

        // Accumulation Unit Account (AUA)
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Monthly Premium', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },
        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'AUA units (Beg)', align: "center" },
        { fontWeight: 'bold', value: '+ AUA Units purchased', align: "center" },
        { fontWeight: 'bold', value: '- Policy Charge', align: "center" },
        { fontWeight: 'bold', value: '+ Loyalty Bonus', align: "center" },
        { fontWeight: 'bold', value: '+ Additional Bonus', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', align: "center" },
        { fontWeight: 'bold', value: '$ Value (AUA)', align: "center" },
        { fontWeight: 'bold', value: '', align: "center" }, // space

        // Total Value
        { fontWeight: 'bold', value: '$ Value (Total)', align: "center" },
    ]

    const excelData = [excelHeader];
    const createExcelSchema = () => excelHeader.map((item, index) => ({ width: [15, 30].includes(index) ? 8 : 28 }));

    /*
    const annualPremium = Math.round(monthlyPremium * 12);
    const totalMonths = Math.round(investmentPeriod * 12);
    const totalPremiumMonths = Math.round(premiumPaymentTerm * 12);
    */

    let totalInvestedPremium = 0;
    // console.log(`annualPremium: ${annualPremium} | totalMonths: ${totalMonths} | totalPremiumMonths: ${totalPremiumMonths} | totalInvestedPremium: ${totalInvestedPremium}`)

    // ====
    // * Bonus
    // ====

    const getInitalBonusRate = (monthlyPremium, premiumPaymentTerm) => { // year 1 to 3

        // This caluclation is for SGD plan onlys
        let rewardBand = 0;

        if (monthlyPremium < 1000) rewardBand = 1;
        else if (monthlyPremium >= 1000 && monthlyPremium < 2000) rewardBand = 2;
        else if (monthlyPremium >= 2000 && monthlyPremium < 3000) rewardBand = 3;
        else if (monthlyPremium >= 3000 && monthlyPremium < 4000) rewardBand = 4;
        else if (monthlyPremium >= 4000) rewardBand = 5;
        else rewardBand = 0;

        // console.log("rewardBand: ", rewardBand);

        let boosterBonusRate = 0;

        if (premiumPaymentTerm >= 5 && premiumPaymentTerm <= 9) boosterBonusRate = [0, 0.02, 0.04, 0.05, 0.07, 0.09][rewardBand];
        else if (premiumPaymentTerm >= 10 && premiumPaymentTerm <= 14) boosterBonusRate = [0, 0.05, 0.09, 0.12, 0.17, 0.20][rewardBand];
        else if (premiumPaymentTerm >= 15 && premiumPaymentTerm <= 19) boosterBonusRate = [0, 0.09, 0.12, 0.17, 0.22, 0.25][rewardBand];
        else if (premiumPaymentTerm === 20) boosterBonusRate = [0, 0.12, 0.22, 0.27, 0.29, 0.32][rewardBand];
        else if (premiumPaymentTerm === 21) boosterBonusRate = [0, 0.12, 0.22, 0.27, 0.32, 0.35][rewardBand];
        else if (premiumPaymentTerm === 22) boosterBonusRate = [0, 0.12, 0.22, 0.30, 0.34, 0.37][rewardBand];
        else if (premiumPaymentTerm === 23) boosterBonusRate = [0, 0.12, 0.22, 0.34, 0.35, 0.39][rewardBand];
        else if (premiumPaymentTerm === 24) boosterBonusRate = [0, 0.12, 0.22, 0.35, 0.37, 0.40][rewardBand];
        else if (premiumPaymentTerm === 25) boosterBonusRate = [0, 0.15, 0.25, 0.42, 0.44, 0.47][rewardBand];
        else boosterBonusRate = 0;

        // console.log("boosterBonusRate", boosterBonusRate);
        return boosterBonusRate;
    }

    const getCustomerBonusRate = (monthlyPremium, premiumPaymentTerm) => {

        return 0;
    }

    const getLoyaltyBonusRate = () => { // year 4 onwards till surender

        // * A Loyalty Bonus will be paid at the END of each policy year, throughout the policy term, starting from the 4th policy year.
        return 0.005; // 0.05% per annum
    }

    const getAdditionalBonusRate = (premiumPaymentTerm, currentPolicyYear) => { // no customer promo

        // * An Additional Bonus will be paid at the END of each policy year, throughout the policy term, starting from the 4th policy year.
        // all premium must be paid to get this bonus
        return 0.002; // 0.02% per annum
    }

    // ===
    // * Charges
    // ===

    const getInitialAccountCharges = () => {

        // * An initial charge of 5.4% p.a. of Initial Units Account value will be deducted monthly on each policy monthiversary from the Initial Units Account.
        return 0.054 // 5.4%
    }

    const getPoliciesCharges = () => {

        // * A policy charge of 1.35% p.a. of the policy value is deducted monthly on each policy monthiversary from  BOTH Initial Units Account and Accumulation Units Account.
        return 0.0135 // 1.35%;
    }

    // ====
    // * 1st 24 months calculation (Initial Investment Account only)
    // ====

    const calculationForInitialAccountFirst24Months = (monthlyPremium, premiumPaymentTerm, fundPriceData, createExcel) => {

        let previousMonthEndUnitBalance_IUA = 0; // Initial Unit Account value at be 0 at Month 1

        // Booster Bonus = Booster Bonus rate x regular premium received in the first 24 months
        const boosterBonusRate = getInitalBonusRate(monthlyPremium, premiumPaymentTerm); // 0.23 means 23% of the premium
        // console.log("boosterBonusRate:", boosterBonusRate);

        for (let i = 0; i < 24; i++) { // first 24 months

            let excelRow = [];

            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate welcome bonus units at the beginning of the month
            // Allocate customer propm bonus at the beginning of the month
            // Minus initial account charges at the beginning of the month

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // monthly premium
            createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '#,##', align: "center" });

            // cumulative premium
            totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '#,##', align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // excelRow.push({ type: String, value: date.format(currentMonthStartDate, 'DD/MM/YYYY', false), align: "center"});

            // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance_IUA = previousMonthEndUnitBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance_IUA: ", openingBalance_IUA);

            // plus purchase of the units at the beginning of month
            const unitsPurchased = monthlyPremium / UTprice;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += unitsPurchased;
            // console.log("unitsPurchased: ", unitsPurchased);

            // plus welome bonus (booster units)
            const boosterUnits = monthlyPremium * boosterBonusRate / UTprice;
            createExcel && excelRow.push({ type: Number, value: boosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += boosterUnits;
            // console.log("boosterUnits: ", boosterUnits);

            // plus cusomter promo bonus (additional booster)
            const additonalBoosterUnits = monthlyPremium * getCustomerBonusRate() / UTprice;
            createExcel && excelRow.push({ type: Number, value: additonalBoosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += additonalBoosterUnits;
            // console.log("additonalBoosterUnits: ", additonalBoosterUnits);

            // minus initial account charges
            const initialAccountCharges = getInitialAccountCharges();
            const monthlyInitialAccountCharges_Units = initialAccountCharges / 12 * openingBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: monthlyInitialAccountCharges_Units * -1, format: '#,##0.000000', align: "center" });
            // console.log("monthlyInitialAccountCharges_Units: ", monthlyInitialAccountCharges_Units);

            // minus policy charges
            const policyCharges = getPoliciesCharges();
            const monthlyPolicyCharges_Units = policyCharges / 12 * openingBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: monthlyPolicyCharges_Units * -1, format: '#,##0.000000', align: "center" });
            // console.log("monthlyPolicyCharges_Units: ", monthlyPolicyCharges_Units);

            openingBalance_IUA -= monthlyInitialAccountCharges_Units + monthlyPolicyCharges_Units;


            // * At end of month
            // month end date 
            const currentMonthEndDate = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate.toString());

            // UT price at the end of current month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // units at end of month (IUA end units)
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("endingBalance_IUA: ", openingBalance_IUA);

            const value_IUA = openingBalance_IUA * UTprice;
            createExcel && excelRow.push({ type: Number, value: value_IUA, format: '#,##0.000000', align: "center" });
            // console.log("value_IUA: ", value_IUA);
            // console.log("=======");

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // ===
            // * Accumulation Unit Account (AUA) calculation
            // ===

            // filling the month index and date for Accumulation Unit Account (AUA)
            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // monthly premium
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // calculate the date of the current month
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

            // insert the UT price at the beginning of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // filling the rest of the AUA columns with 0
            const zerosToAdd = 7;
            for (let j = 0; j < zerosToAdd; j++) {
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" }); // adding 0 to the remaining columns (AUA)        
            }

            // ut price at the end of the month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // units at end of month (AUA end units)
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // total value IUA + AUA, AUA value is 0 at the end of 24 months
            createExcel && excelRow.push({ type: Number, value: value_IUA, format: '$#,##0.000000', align: "center" });

            // store the excel row
            createExcel && excelData.push(excelRow);

            // storing IUA current end month value as the beginning IUA value of the next month
            previousMonthEndUnitBalance_IUA = openingBalance_IUA;

        } // end for loop for 1st

        return previousMonthEndUnitBalance_IUA;
    }


    // ====
    // * From 25th month to end of premium payment term (Initial Investment Account + Accumulation Account)
    // ====

    const calculationTillEndPremiumTerm = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, unitBalance_IUA, createExcel) => {

        let previousMonthEndUnitBalance_IUA = unitBalance_IUA;
        let previousMonthEndUnitBalance_AUA = 0; // Accumulation Unit Account value at be 0 at Month 1

        const month_preimumPaymentTerm = +(premiumPaymentTerm * 12).toFixed(0);
        const month_InvestmentPeriod = +(investmentPeriod * 12).toFixed(0);

        for (let i = 24; i < month_InvestmentPeriod; i++) { // from 25th month to end of premium payment term

            let excelRow = [];

            // ===
            // * Investment Unit Account (IUA) calculation section
            // * At beginning of the month
            // No purchas of units in IUA after 24 months
            // No welcome bonus units after 24 months
            // No allocate customer propm bonus after 24 months
            // Minus initial account charges at the beginning of the month
            // ===

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // monthly premium
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: fundPriceData[i], format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: **** ", fundPriceData[i]);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance_IUA = previousMonthEndUnitBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance_IUA: ", openingBalance_IUA);

            // no purchase of the units 
            const unitsPurchased = 0;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += unitsPurchased;
            // console.log("unitsPurchased: ", unitsPurchased);

            // plus welome bonus (booster units)
            if (i < 36) {
                const boosterBonusRate = getInitalBonusRate(monthlyPremium, premiumPaymentTerm); // 0.23 means 23% of the premium
                const boosterUnits = monthlyPremium * boosterBonusRate / UTprice;
                createExcel && excelRow.push({ type: Number, value: boosterUnits, format: '#,##0.000000', align: "center" });
                openingBalance_IUA += boosterUnits;
                // console.log("boosterUnits: ", boosterUnits);
            } else {
                // no welcome bonus units after 36 months
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // no cusomter promo bonus (additional booster)
            const additonalBoosterUnits = 0;
            createExcel && excelRow.push({ type: Number, value: additonalBoosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += additonalBoosterUnits;
            // console.log("additonalBoosterUnits: ", additonalBoosterUnits);

            // minus initial account charges
            const initialAccountCharges = getInitialAccountCharges();
            const monthlyInitialAccountCharges_Units = initialAccountCharges / 12 * openingBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: monthlyInitialAccountCharges_Units * -1, format: '#,##0.000000', align: "center" });
            // console.log("monthlyInitialAccountCharges_Units: ", monthlyInitialAccountCharges_Units);

            // minus policy charges
            const policyCharges = getPoliciesCharges();
            const monthlyPolicyCharges_Units = policyCharges / 12 * openingBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: monthlyPolicyCharges_Units * -1, format: '#,##0.000000', align: "center" });
            // console.log("monthlyPolicyCharges_Units: ", monthlyPolicyCharges_Units);

            openingBalance_IUA -= monthlyInitialAccountCharges_Units + monthlyPolicyCharges_Units;

            // * At end of month
            // month end date
            const currentMonthEndDate = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate.toString());

            // UT price at the end of current month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // units at end of month (IUA end units)
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("endingBalance_IUA: ", openingBalance_IUA);

            // value of IUA at the end of the month
            const value_IUA = openingBalance_IUA * UTprice;
            createExcel && excelRow.push({ type: Number, value: value_IUA, format: '$#,##0.000000', align: "center" });
            // console.log("value_IUA: ", value_IUA);

            // storing IUA current end month value as the beginning IUA value of the next month
            previousMonthEndUnitBalance_IUA = openingBalance_IUA;

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // ===
            // Accumulation Unit Account (AUA) calculation
            // * At beginning of the month
            // Minus accuulation account charges at the beginning of the month
            // Plus Loyalty Bonus at the end of every policy year (from the end of 3rd policy year till end of investment period). Use function => getLoyaltyBonusRate
            // Plus Accumulation Bonus at the end of specified policy years based on premium payment term. Use function => getAccumulationBonusRate
            // ===

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            if (i < month_preimumPaymentTerm) {
                // monthly premium
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '#,##', align: "center" });

                // cumulative premium
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
                createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '#,##', align: "center" });
            } else {
                // no premium after the premium payment term
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
                createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '#,##', align: "center" });
            }


            // calculate the date of the current month
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });

            // insert the UT price at the beginning of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // opening balace = previous month end value (monthEndValue)
            let openingBalance_AUA = previousMonthEndUnitBalance_AUA;
            createExcel && excelRow.push({ type: Number, value: openingBalance_AUA, format: '#,##0.000000', align: "center" });

            // purchase of the units in AUA if premium payment term is not over
            UTprice = fundPriceData[i];
            // console.log("UTprice: ", UTprice);

            const unitsPurchased_AUA = ((i + 1) / 12) <= premiumPaymentTerm ? monthlyPremium / UTprice : 0;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased_AUA, format: '#,##0.000000', align: "center" });
            openingBalance_AUA += unitsPurchased_AUA;
            // console.log("unitsPurchased_AUA: ", unitsPurchased_AUA);

            // minus policy charges
            const policyCharges_AUA = getPoliciesCharges();
            const monthlyPolicyCharges_Units_AUA = policyCharges_AUA / 12 * openingBalance_AUA;
            createExcel && excelRow.push({ type: Number, value: monthlyPolicyCharges_Units_AUA * -1, format: '#,##0.000000', align: "center" });
            // console.log("monthlyPolicyCharges_Units: ", monthlyPolicyCharges_Units);
            openingBalance_AUA -= monthlyPolicyCharges_Units_AUA;

            // plus Loyalty Bonus at the end of every policy year (from the end of 4th policy year till end of investment period)
            const loyaltyBonusRate = (i >= 36 && (i + 1) % 12 === 0) ? getLoyaltyBonusRate(Math.floor(i / 12) + 1, premiumPaymentTerm) : 0;
            const loyaltyBonus = openingBalance_AUA * loyaltyBonusRate;
            createExcel && excelRow.push({ type: Number, value: loyaltyBonus, format: '#,##0.000000', align: "center" });
            // console.log("loyaltyBonus: ", loyaltyBonus);

            // plus Additional Bonus at the end of every policy year (from the end of 4th policy year till end of investment period)
            const additionalBonusRate = (i >= 36 && (i + 1) % 12 === 0) ? getAdditionalBonusRate(premiumPaymentTerm, Math.floor(i / 12) + 1) : 0;
            const additionalBonus = openingBalance_AUA * additionalBonusRate;
            createExcel && excelRow.push({ type: Number, value: additionalBonus, format: '#,##0.000000', align: "center" });
            // console.log("additionalBonus: ", additionalBonus);

            // adding loyalty bonus + additiona bonus to the opening balance
            openingBalance_AUA += (additionalBonus + loyaltyBonus);


            // * At end of month
            // month end date
            const currentMonthEndDate_AUA = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate_AUA, format: 'dd/mm/yyyy', align: "center" });

            // UT price at the end of current month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // units at end of month (AUA end units)
            createExcel && excelRow.push({ type: Number, value: openingBalance_AUA, format: '#,##0.000000', align: "center" });

            // value of AUA at the end of the month
            const value_AUA = openingBalance_AUA * UTprice;
            createExcel && excelRow.push({ type: Number, value: value_AUA, format: '$#,##0.000000', align: "center" });

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // total value IUA + AUA
            createExcel && excelRow.push({ type: Number, value: value_IUA + value_AUA, format: '$#,##0.000000', align: "center" });



            // store the excel row
            createExcel && excelData.push(excelRow);

            // storing AUA current end month value as the beginning IUA value of the next month
            previousMonthEndUnitBalance_AUA = openingBalance_AUA;


        } // investment account calculation loop

        return { IUA: previousMonthEndUnitBalance_IUA, AUA: previousMonthEndUnitBalance_AUA };
    }

    // ====
    // 1st 24 month: Initial Investment Account only
    // ====
    let previousMonthEndUnitBalance_IUA = calculationForInitialAccountFirst24Months(monthlyPremium, premiumPaymentTerm, fundPriceData, createExcel);
    // console.log("Initial Investment Account period of 24 months:", previousMonthEndUnitBalance_IUA);

    // ====
    // 25th month  onwards: Initial Investment Account + Accumulation Account
    // ====

    const { IUA, AUA } = calculationTillEndPremiumTerm(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, previousMonthEndUnitBalance_IUA, createExcel); // in units
    // console.log("Initial Investment Account :", IUA);
    // console.log("Accumulation Account :", AUA);

    // calculate total and format totalValue into dollars
    let totalValue = (IUA + AUA) * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // console.log(`Total Value: ${totalValue}`);

    return { insurer: "Tokio Marine", planName: "#goClassic (Basic)", availablity: true, totalInvestedPremium, IUA, AUA, totalValue, excelFileName, excelSchema: createExcelSchema(), excelData };

} // end goClassic

export default goClassic;