import React, { useState } from 'react'
import PropTypes from 'prop-types';

// prime react
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';

const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 3,
    numScroll: 3
  },
  {
    breakpoint: '768px',
    numVisible: 2,
    numScroll: 2
  },
  {
    breakpoint: '560px',
    numVisible: 1,
    numScroll: 1
  }
];

// image fall back URL
const imageFallback = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'

// component
const DisplayCarousel = (props) => {

  // props
  const { type, values, update } = props;

  // state
  const [urls, setUrls] = useState(values);

  // change isSelecetd in state array
  const selectPic = (url) => {
    const newArray = values.map(item => {
      item.isSelected = (item.url === url) ? true : false;
      return item;
    })

    update(url);
    setUrls(newArray);
  }

  const imageTemplate = (image) => {
    return (
      <div>
        <div className="mb-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            <img src={image.url} onError={(e) => e.target.src = imageFallback} alt={type}
              style={{ width: type === "avatarURL" ? '150px' : '200px', height: '150px', objectFit: 'contain', border: '5px solid transparent' }} />
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {
            image.isSelected
              ? <Button label="In Use" disabled />
              : <Button label="Select" onClick={() => selectPic(image.url)} />
          }
        </div>
      </div>
    )
  }

  return (
    <Carousel value={urls} itemTemplate={imageTemplate} responsiveOptions={responsiveOptions} numVisible={3} numScroll={1}></Carousel>
  )
}

export default DisplayCarousel

DisplayCarousel.propTypes = {
  type: PropTypes.oneOf(['avatarURL', 'backgroundURL']),
  values: PropTypes.array,
  update: PropTypes.func
}