/**
 * Calculates the age based on the date of birth passed in the format of DD/MM/YYYY.
 *
 * @param {string} dob - The date of birth in the format of DD/MM/YYYY.
 * @returns {Object} An object containing the age in years, months, and days.
 * @example
 * calculateAge('01/01/1991'); // returns { ALB: 30, ANB: 31, AnB: 30 }
 */

const today = new Date();

// calculate the number of days from 1st Jan to the pass in month & day
const getDateIndex = (mth, day) => {
    const daysInMonth = [31, ((today.getFullYear() % 4 === 0) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return daysInMonth.reduce((preValue, curValue, i) => preValue + (i < mth ? curValue : 0), 0) + day;
}

export const calculateAge = (dob) => {

    const arr = dob.split("/");
    const dob_Date = parseInt(arr[0]);
    const dob_Month = parseInt(arr[1]) - 1;
    const dob_Year = parseInt(arr[2]);

    const dob_Index = getDateIndex(dob_Month, dob_Date);
    const today_Index = getDateIndex(today.getMonth(), today.getDate());

    const ALB = today.getFullYear() - dob_Year - (dob_Index > today_Index ? 1 : 0);
    let AnB = 0;

    if (dob_Index > today_Index) {
        // birthday not over
        AnB = ALB + (dob_Index - today_Index >= 182 ? 0 : 1);
    } else {
        //birthday over
        AnB = ALB + (today_Index - dob_Index <= 182 ? 0 : 1);
    }
    return { ALB, ANB: (ALB + 1), AnB };
}
export default calculateAge;