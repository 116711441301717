import React, { useState, useEffect } from 'react';

// Prime React
import { BlockUI } from 'primereact/blockui';
import { ProgressBar } from 'primereact/progressbar';

const LoadingScreen = (props) => {

    // props => time is the number of seconds to close the loading screen
    const { time = 1 } = props;
    // console.log("time (in mins) to display loading screen", time);

    // state to force close the loader
    const [displayBlock, setDisplayBlock] = useState(true);

    // auto close the loading screen after a pre-determine number of sec (default 180 sec)
    useEffect(() => {
        const timeOut = setTimeout(() => {
            setDisplayBlock(false);
            alert("Server is busy. Please try again!");
        }, time * 60 * 1000);

        // unmount timeOut
        return () => clearTimeout(timeOut);
    }, [time])

    return (
        <BlockUI blocked={displayBlock} fullScreen template={
            <div>
                <h3>Loading...</h3>
                <h5>Close in {`${time} minute(s)`} if server is busy</h5>
                <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
            </div>
        } />
    )
}

export default LoadingScreen;
