
/**
 * Catches errors and displays them as messages using PrimeReact's Message component.
 *
 * @param {object} error - The error object to be caught and displayed.
 * @param {object} messages - The ref object of PrimeReact's Message component.
 * @returns {void}
 */

const primeMesagesErrorCatcher = (error, messages) => {

    if (!messages || !messages?.current) {
        return
    }
    messages.current.clear();
    if (error.response) {
        const { message, devCode } = error.response?.data;
        messages.current.show({ severity: 'error', detail: `${message} - (Dev Code: ${devCode})`, sticky: true });
    } else {
        messages.current.show({ severity: 'error', detail: 'Warning: Unable to connect to backend server', sticky: true });
    }
}

export default primeMesagesErrorCatcher