const breakEven_aiaProAchiever3 = [
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 300,
        "breakEvenYield": 3.68,
        "totalValue": 36000.28446435435
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 3.14,
        "totalValue": 60017.7303888324
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 2.77,
        "totalValue": 90025.64152255237
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.5,
        "totalValue": 120024.32248377151
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.41,
        "totalValue": 180003.86395912568
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.41,
        "totalValue": 240005.15194550084
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.41,
        "totalValue": 360007.72791825136
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.41,
        "totalValue": 480010.3038910017
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 300,
        "breakEvenYield": 1.34,
        "totalValue": 54020.08980302233
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 500,
        "breakEvenYield": 1.06,
        "totalValue": 90000.96171255242
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 750,
        "breakEvenYield": 0.92,
        "totalValue": 135096.0973166135
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.8,
        "totalValue": 180002.96726807274
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.77,
        "totalValue": 270107.09564000746
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.77,
        "totalValue": 360142.7941866766
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.77,
        "totalValue": 540214.1912800149
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.77,
        "totalValue": 720285.5883733532
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 300,
        "breakEvenYield": 0.6,
        "totalValue": 72064.58768741462
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 500,
        "breakEvenYield": 0.41,
        "totalValue": 120068.69262430564
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 0.33,
        "totalValue": 180084.56012542496
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.27,
        "totalValue": 240082.60334958587
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.25,
        "totalValue": 360077.616836226
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.25,
        "totalValue": 480103.4891149687
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.25,
        "totalValue": 720155.233672452
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.25,
        "totalValue": 960206.9782299374
    }
];

exports.breakEven_aiaProAchiever3 = breakEven_aiaProAchiever3;