import React, { useRef, useContext } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

// PrimeReact
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages';

// context
import { DispatchUserContext } from 'contexts/userContext';

// custom method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// custom axios hook
import useAxiosAuth from 'hooks/useAxiosAuth'

// regex for password
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

// fromik inital values
const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    otp: ""
}

// formik validation schema
const validationSchema = yup.object({
    currentPassword: yup.string().required("Please enter the current password"),
    newPassword: yup.string().required("Please enter the new password")
        .matches(passwordRegExp, "Your password must contain at least 8 characters with 1 uppercase, 1 lowercase, 1 number and 1 special character")
        .notOneOf([yup.ref('currentPassword')], 'New Password must be different from current password'),

    confirmNewPassword: yup.string().required('Please confirm your new password')
        .oneOf([yup.ref('newPassword')], 'Passwords do not match')
});

// component
const FormChangePassword = () => {

    // useRef for Message
    const messages = useRef(null);

    // context dispatch function to update user context
    const dispatch = useContext(DispatchUserContext);

    // axios instance
    const axiosInstance = useAxiosAuth();

    // formik form to change password
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => changePassword(values)
    });

    const changePassword = async (values) => {

        // display loading screen
        dispatch({ type: "LOADING", value: true });

        const input = { ...values } // create a copy of values and name it as input
        delete input.confirmNewPassword;

        try {
            const res = await axiosInstance.post("dashboard/profile/updatePassword", input);
            const { status, data } = res;

            if (status === 200 & data?.success) {
                messages.current.clear();
                messages.current.show({ severity: 'success', detail: `Password is updated`, sticky: true })
                formik.resetForm();
            } else {
                messages.current.clear();
                messages.current.show({ severity: 'error', detail: res.data.msg, sticky: true })
            }
        } catch (error) {
            primeMesagesErrorCatcher(error, messages);
        }

        // close loading screen
        dispatch({type: "LOADING", value: false});
    }

    return (
        <div className='field card'>
            <Messages ref={messages}></Messages>
            <h5>Change Password</h5>
            <p>Steps to change password</p>
            <ol>
                <li>Enter the current password</li>
                <li>Enter the new password. Your password must contain</li>
                    <ul>
                        <li>at least 8 characters</li>
                        <li>min 1 uppercase</li>
                        <li>min 1 lowercase</li>
                        <li>min 1 number</li>
                        <li>min 1 special character</li>
                    </ul>
                <li>Enter the new password again to confirm</li>
                <li>Enter your OTP and click [Change Password]</li>
            </ol>
            <br></br>
            <div>
                {displayFormikLabel("currentPassword", "Key in your current password.", formik.touched, formik.errors)}
                <InputText id="currentPassword" type="password" className="w-full mb-3" value={formik.values.currentPassword} onChange={(e) => formik.setFieldValue("currentPassword", e.target.value)} />

                {displayFormikLabel("newPassword", "Key in your new password.", formik.touched, formik.errors)}
                <InputText id="newPassword" type="password" className="w-full mb-3" value={formik.values.newPassword} onChange={(e) => formik.setFieldValue("newPassword", e.target.value)} />

                {displayFormikLabel("confirmNewPassword", "Confirm your new password.", formik.touched, formik.errors)}
                <InputText id="confirmNewPassword" type="password" className="w-full mb-3" value={formik.values.confirmNewPassword} onChange={(e) => formik.setFieldValue("confirmNewPassword", e.target.value)} />

                {displayFormikLabel("otp", "Enter your 2FA password.", formik.touched, formik.errors)}
                <InputText id="otp" className="w-full mb-3" value={formik.values.otp} onChange={(e) => formik.setFieldValue("otp", e.target.value)} />

                <div className='grid mt-2'>
                    <div className="col-12 md:col-6">
                        <Button type="button" label="Cancel" aria-label="Cancel" className="p-button-outlined w-full" onClick={formik.resetForm} />
                    </div>
                    <div className="col-12 md:col-6">
                        <Button type="submit" label="Change Password" aria-label="Change Password" className="w-full" onClick={formik.handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormChangePassword