import React, { useContext } from 'react'
import PropTypes from 'prop-types';

import { UserContext } from 'contexts/userContext';

const DisplayComparisonTitle = (props) => {

    // user context
    const user = useContext(UserContext);

    return (
        <div className='card'>
            <h5>{props.type} Comparison for {user?.comparisonInsured?.name}</h5>
            <h6>Current Age: {user?.comparisonInsured?.ALB}, {user?.comparisonInsured?.gender}, {user?.comparisonInsured?.smokingStatus}</h6>
            <div className='flex justify-content-end '>
                <p>Prepared By: {user?.userDetails?.name} | <i className="pi pi-phone" style={{ fontSize: "0.8rem" }}></i>: {user?.userDetails?.mobile} | RNF: {user?.userDetails?.rnf}</p>
            </div>
            <div className='flex justify-content-end '>
                <p></p>
            </div>
        </div>
    )
}

export default DisplayComparisonTitle

DisplayComparisonTitle.propTypes = {
    type: PropTypes.string.isRequired,
}