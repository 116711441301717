import date from 'date-and-time';
import { getCurrentTime } from 'helpers/getCurrentTime';

const investSmartFlex = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod, createExcel });

    // ====
    // * Validation
    // ====

    const allPremiumPaymentTerm = [10, 15, 20];
    if (!allPremiumPaymentTerm.includes(premiumPaymentTerm)) {
        return { availablity: false }
    }

    if (premiumPaymentTerm === 10 && monthlyPremium < 400) {
        return { availablity: false }
    }

    if (premiumPaymentTerm === 15 && monthlyPremium < 300) {
        return { availablity: false }
    }

    if (premiumPaymentTerm === 20 && monthlyPremium < 200) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file 
    const excelFileName = `eTiQa Invest Smart Flex ${getCurrentTime()}.xlsx`

    const excelHeader = [
        // One account model
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Month Premium', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (Beg)', align: "center" },
        { fontWeight: 'bold', value: '+ IUA Units purchased', align: "center" },
        { fontWeight: 'bold', value: '+ Start Up Bonus units', align: "center" },
        { fontWeight: 'bold', value: '+ Special Bonus units', align: "center" },
        { fontWeight: 'bold', value: '+ Customer Promo units', align: "center" },
        { fontWeight: 'bold', value: '+ Loyalty Bonus units', align: "center" },
        { fontWeight: 'bold', value: '- Policy Charge', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', align: "center" },

        { fontWeight: 'bold', value: '$ Value (IUA)', align: "center" },
    ]
    const excelData = [excelHeader];

    const createExcelSchema = () => excelHeader.map(item => ({ width: 28 }));

    let totalInvestedPremium = 0;

    // ====
    // * Bonus
    // ====

    const getStartUpBonus = (monthlyPremium, premiumPaymentTerm) => {

        switch (premiumPaymentTerm) {
            case 10:
                return (monthlyPremium * 12 < 9600 ? 0.15 : 0.3);
            case 15:
                return (monthlyPremium * 12 < 7200 ? 0.25 : 0.5);
            case 20:
                return (monthlyPremium * 12 < 4800 ? 0.4 : 0.8);
            default:
                return 0;
        }
    }

    const getSpecialBonus = (premiumPaymentTerm, currentPolicyYear) => {

        const rate = 0.05;

        const allSpecialspecialBonus = [
            { premiumPaymentTerm: 10, specialBonus: [6, 7, 8, 9, 10].map(policyYear => ({ policyYear, rate })) },
            { premiumPaymentTerm: 15, specialBonus: [10, 11, 12, 13, 14, 15].map(policyYear => ({ policyYear, rate })) },
            { premiumPaymentTerm: 20, specialBonus: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(policyYear => ({ policyYear, rate })) },
        ]

        const specialBonussForPremiumTerm = allSpecialspecialBonus.find(rate => rate.premiumPaymentTerm === premiumPaymentTerm).specialBonus;
        const specialBonusForCurrentYear = specialBonussForPremiumTerm.find(rate => rate.policyYear === currentPolicyYear);

        // console.log("specialBonusForCurrentYear: ", specialBonusForCurrentYear);
        return specialBonusForCurrentYear ? specialBonusForCurrentYear.rate : 0;   // 0.02 means 2% bonus of accumulation account value, 0.03 bonus of accumulation account value, 0 means no bonus
    }

    const getLoyaltyBonusRate = (premiumPaymentTerm, currentPolicyYear) => {

        // Loyalty Bonus will be created at the begining of the policy year immediately after the premium payment term ends/
        // One condition is the there is no partial withdrawal in the previous policy year

        return currentPolicyYear > premiumPaymentTerm ? 0.002 : 0;
    }

    // ===
    // * Charges
    // ===

    const getPolicyCharges = (currentPolicyYear) => {

        const charges = [];
        for (let i = 1; i <= 10; i++) {
            charges.push({ policyYear: i, policyCharge: 0.02 });
        }
        for (let i = 11; i <= 20; i++) {
            charges.push({ policyYear: i, policyCharge: 0.016 });
        }
        for (let i = 21; i <= 30; i++) {
            charges.push({ policyYear: i, policyCharge: 0.006 });
        }

        const policyCharge = charges.find(charge => charge.policyYear === currentPolicyYear);
        return policyCharge ? policyCharge.policyCharge : 0;
    }

    // ====
    // * Calculation
    // ====

    const calculationForpolicyAccount = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel) => {

        let previousMonthEndUnitBalance = 0; // one account only

        const totalMonths = investmentPeriod * 12;

        for (let i = 0; i < totalMonths; i++) {

            let excelRow = [];

            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate start up bonus units at the beginning of the month
            // Allocate special bonus units at the beginning of the month
            // Allocate customer promo bonus units at the beginning of the month
            // Allocate loyality bonus at the beginning of the month
            // Minus policy account charges at the beginning of the month

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            if (i < premiumPaymentTerm * 12) {
                // monthly premium
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,##', align: "center" });
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            } else {
                // monthly premium
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
            }

            // cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,##', align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // excelRow.push({ type: String, value: date.format(currentMonthStartDate, 'DD/MM/YYYY', false), align: "center"});

            // calculate the UT price at the beginning of the month
            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance = previousMonthEndUnitBalance;
            createExcel && excelRow.push({ type: Number, value: openingBalance, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance_IUA: ", openingBalance_IUA);

            // plus purchase of the units at the beginning of month during premium payment term
            const premiumPaymentMonths = premiumPaymentTerm * 12;
            if (i < premiumPaymentMonths) {
                const unitsPurchased = monthlyPremium / UTprice;
                createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
                openingBalance += unitsPurchased;
                // console.log("unitsPurchased: ", unitsPurchased);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // plus start up bonus units only for 1st year
            if (i < 12) {
                const startUpBonus = getStartUpBonus(monthlyPremium, premiumPaymentTerm) * monthlyPremium / UTprice;
                createExcel && excelRow.push({ type: Number, value: startUpBonus, format: '#,##0.000000', align: "center" });
                openingBalance += startUpBonus;
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }
            // console.log("startUpBonus: ", startUpBonus);

            // plus special bonus units
            const specialBonus = getSpecialBonus(premiumPaymentTerm, Math.floor(i / 12 + 1)) * monthlyPremium / UTprice;
            createExcel && excelRow.push({ type: Number, value: specialBonus, format: '#,##0.000000', align: "center" });
            openingBalance += specialBonus;
            // console.log("specialBonus: ", specialBonus);

            // plus customer promo bonus units
            const customerPromoBonus = 0;
            createExcel && excelRow.push({ type: Number, value: customerPromoBonus, format: '#,##0.000000', align: "center" });
            openingBalance += customerPromoBonus;
            // console.log("customerPromoBonus: ", customerPromoBonus);

            // plus loyality bonus units at the beginning of the policy year
            if (Math.floor(i % 12) === 0) {
                const loyalityBonus = getLoyaltyBonusRate(premiumPaymentTerm, Math.floor(i / 12) + 1) * openingBalance;
                createExcel && excelRow.push({ type: Number, value: loyalityBonus, format: '#,##0.000000', align: "center" });
                openingBalance += loyalityBonus;
                // console.log("loyalityBonus: ", loyalityBonus);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
            }

            // minus policy account charges
            const policyCharge = getPolicyCharges(Math.floor(i / 12 + 1)) / 12 * openingBalance; // policy charge is annual, so divide by 12
            createExcel && excelRow.push({ type: Number, value: (0 - policyCharge), format: '#,##0.000000', align: "center" });
            openingBalance -= policyCharge;
            // console.log("policyCharge: ", policyCharge);

            // calculate the date of the current month
            const currentMonthEndDate = date.addMonths(currentMonth1st, i + 1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // excelRow.push({ type: String, value: date.format(currentMonthEndDate, 'DD/MM/YYYY', false), align: "center"});

            // calculate the UT price at the end of the month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // calculate the end of month balance
            const endOfMonthBalance = openingBalance;
            createExcel && excelRow.push({ type: Number, value: endOfMonthBalance, format: '#,##0.000000', align: "center" });
            // console.log("endOfMonthBalance: ", endOfMonthBalance);

            // calculate the end of month value
            const endOfMonthValue = endOfMonthBalance * UTprice;
            createExcel && excelRow.push({ type: Number, value: endOfMonthValue, format: '$#,##0.00', align: "center" });
            // console.log("endOfMonthValue: ", endOfMonthValue);


            // store the excel row
            createExcel && excelData.push(excelRow);

            // update the previous month end unit balance
            previousMonthEndUnitBalance = openingBalance;

        } // end for totalMonths

        return { balance: previousMonthEndUnitBalance }
    }

    // ====
    // *One Account only
    // ====
    const { balance } = calculationForpolicyAccount(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel);
    // console.log("Initial Investment Account period of 24 months:", previousMonthEndUnitBalance_IUA);

    let totalValue = balance * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00

    return { insurer: "ETIQA", planName: "Invest Smart Flex", availablity: true, totalInvestedPremium, balance, totalValue, excelFileName, excelSchema: createExcelSchema(), excelData };

} // end investSmartFlex

export default investSmartFlex;