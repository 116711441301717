import React, { useContext } from 'react';

// primereact
import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import { Timeline } from 'primereact/timeline';

// userContext
import { UserContext } from "contexts/userContext";

// custom
import DashboardNotes from '_dashboard/DashboardNotes';

const events = [
    {
        status: '1st Version Launched (Personal Use)', date: '2023 Jul', icon: 'pi pi-desktop', color: '#9C27B0',
        text: "Homepage, Calculator, Contact Card, Comparison: Integrated Shield Plan, Long Term Care, MultiPayCI Plan, LifetimeIncome Plan, Term Plan"
    },
    {
        status: 'New Comparsion', date: '2023 Sep', icon: 'pi pi-dollar', color: '#89CFF0',
        text: "Limited Payment Whole Life endowment"
    },
    {
        status: 'New Comparison', date: '2023 Dec', icon: 'pi pi-dollar', color: '#89CFF0',
        text: "Limited Payment Whole Life Comparison"
    },
    {
        status: 'IPP Beta Testing', date: '2024 May', icon: 'pi pi-desktop', color: '#9C27B0',
        text: "50 Beta uses with favorable feedback. Added new functions such as insurer display filtering"
    },
    {
        status: 'New Comparison', date: '2024 Aug', icon: 'pi pi-dollar', color: '#89CFF0',
        text: "Indexed Universal Life Comparison"
    },
    {
        status: 'Unique Comparison URL', date: '2024 Oct', icon: 'pi pi-cloud-upload', color: '#929000',
        text: "Generate a unique URL for each comparison to share with clients"
    },

    {
        status: 'Coverage Calculator', date: 'In the pipeline', icon: 'pi pi-calculator', color: '#FF9800',
        text: "To calculate insurance needs for clients"
    },
    {
        status: 'Simple Retirement Calculator', date: 'In the pipeline', icon: 'pi pi-chart-bar', color: '#0096ff',
        text: "To calculate how much you need to retire, the effect of planning retirement 3 years and 5 years later"
    },
    {
        status: 'Policy Summary', date: 'In the pipeline (Big item)', icon: 'pi pi-check', color: '#009051',
        text: "Quick policy summary for clients"
    },
    {
        status: 'Retirement Simulation', date: 'In the pipeline (Big item)', icon: 'pi pi-check', color: '#009051',
        text: "Detail chart to show client's retirement simulation in both yearly and MONTHLY mode"
    },
    {
        status: 'Simple Will Writing', date: 'Considering. May not happen', icon: 'pi pi-question', color: '#607D8B',
        text: "Simple click and fill in the bkank for a simple will, "
    },
];

const HomePage = () => {

    const user = useContext(UserContext);
    // console.log(user);

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {
        return (
            <Card title={item.status} subTitle={item.date}>
                <p>{item.text}</p>
            </Card>
        );
    };

    return (
        <div className='grid'>
            <div className='col-12'>
                <TabView>
                    <TabPanel header="Notes">
                        <DashboardNotes />
                    </TabPanel>
                    <TabPanel header="Timeline">
                        <div className='col-12'>
                            <br />
                            <Card title="Timeline">
                                <Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                            </Card>
                        </div>
                    </TabPanel>
                    <TabPanel header="Message">
                        <br />
                        <Card title={`Hello ${user?.userDetails?.name}!`}>
                            <h5>I want to extend my heartfelt gratitude for being a part of the inaugural group of testers for Techvisor.io. You are the first batch of beta testers, and your involvement is truly invaluable to me. Your feedback, insights, and dedication will play an instrumental role in shaping our online tool for financial advisors.</h5>
                            <h5>I am immensely grateful for your willingness to embark on this journey. Your input and suggestions will refine and improve Techvisor.io, ensuring that it meets the needs and expectations of financial advisors like you.</h5>
                            <h5>As I continue to enhance and evolve our platform, I want to express my sincere appreciation for your trust and confidence in me. Thank you for your invaluable support and for pioneering Techvisor.io's beta testing phase. </h5>
                            <h5>I couldn't have reached this significant milestone without your involvement. I am excited to continue this journey together and create an exceptional tool for financial advisors in Singapore.</h5>
                            <h5>- Yan &#128591;&#128591;&#128591;</h5>
                        </Card>
                    </TabPanel>
                </TabView>
            </div>

        </div>
    )
}

export default HomePage