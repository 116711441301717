/**
 * Returns a Date object from a string in the format "DD/MM/YYYY".
 *
 * @param {string} dateString - The date string in the format "DD/MM/YYYY".
 * @returns {Date} - A Date object representing the input date string.
 */

const getPrimeDate = (dateString) => {

    //dateString e.g. "1/1/2000"
    const arr = dateString.split('/');
    /*
    const myDate = new Date(arr[2], (+arr[1]-1), arr[0])
  // console.log(myDate)
    */
    return new Date(arr[2], (+arr[1] - 1), arr[0])
}

export default getPrimeDate;