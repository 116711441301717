import date from 'date-and-time';
import { getCurrentTime } from 'helpers/getCurrentTime';

const investGoalX = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.log('Invest Goal X');
    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod, createExcel });

    // ====
    // * Validation
    // ====

    if (premiumPaymentTerm !== 10) {
        // console.log('Invest Goal X has a MIP of 10 years');
        return { availablity: false }
    }

    if (premiumPaymentTerm > investmentPeriod) {
        // console.log('Investment period should be greater than or equal to premium payment term');
        return { availablity: false }
    }

    if (monthlyPremium < 500) {
        // console.log('Minimum monthly premium is 500');
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file 
    const excelFileName = `FWD Invest Goal X ${getCurrentTime()}.xlsx`

    const excelHeader = [
        // Investment Unit Account (IUA) for regular monthly investment
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Monthly Premium', align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (Beg)', align: "center" },
        { fontWeight: 'bold', value: '+ IUA Units purchased', align: "center" },
        { fontWeight: 'bold', value: '+ Booster Bonus units', align: "center" },
        { fontWeight: 'bold', value: '+ Promo Booster units', align: "center" },
        { fontWeight: 'bold', value: '+ Loyalty Bonus units', align: "center" },
        { fontWeight: 'bold', value: '- IUA Charge', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', align: "center" },
        { fontWeight: 'bold', value: '$ Value (IUA)', align: "center" },
        { fontWeight: 'bold', value: '', align: "center" }, // space

        // Accumulation Unit Account (AUA) for top up
        { fontWeight: 'bold', value: 'Month (Index)', align: "center" },
        { fontWeight: 'bold', value: 'Date (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', align: "center" },
        { fontWeight: 'bold', value: 'AUA units (Beg)', align: "center" },
        { fontWeight: 'bold', value: '+ Top Up Units', align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', align: "center" },
        { fontWeight: 'bold', value: 'AUA units (End)', align: "center" },
        { fontWeight: 'bold', value: '$ Value (AUA)', align: "center" },
        { fontWeight: 'bold', value: '', align: "center" }, // space

        // total value
        { fontWeight: 'bold', value: '$ Value (Total)', align: "center" },
    ]

    const excelData = [excelHeader];
    const createExcelSchema = () => excelHeader.map((item, index) => ({ width: [15, 25].includes(index) ? 8 : 28 }));

    const annualPremium = Math.round(monthlyPremium * 12);
    const totalMonths = Math.round(investmentPeriod * 12);
    const totalPremiumMonths = Math.round(premiumPaymentTerm * 12);
    
    let totalInvestedPremium = 0;
    // console.log(`annualPremium: ${annualPremium} | totalMonths: ${totalMonths} | totalPremiumMonths: ${totalPremiumMonths} | totalInvestedPremium: ${totalInvestedPremium}`)

    let previousMonthEndUnitBalance_IUA = 0, previousMonthEndUnitBalance_AUA = 0;

    // ====
    // * Bonus Rates
    // ====

    // Booster Bonus = Booster Bonus rate x regular premium received in the first 36 months
    const boosterBonusRate = annualPremium >= 12000 ? 0.14 : 0.04;
    // console.log('boosterBonusRate: ', boosterBonusRate);

    // Promo Booster Bonus = Promo Booster Bonus rate x regular premium received in the first 12 months
    const promoBoosterBonusRate = annualPremium > 36000 ? 0.10 : (annualPremium >= 12000 ? 0.05 : 0);
    // console.log('promoBoosterBonusRate: ', promoBoosterBonusRate);

    // Loyalty Bonus received annually from the 11th policy year onwards
    const loyaltyBonusRate = 0.02;

    // ====
    // * IUA Charges Rate
    // ====

    // IUA charges = 2.3% p.a the IUA value /12 months, at the beginning of the month
    const IUAChargesRate = 0.023 / 12;

    // ====
    // * IUA and AUA Calculations (AUA is only for top up so all zeros)
    // ====

    for (let i = 0; i < totalMonths; i++) {

        let excelRow = [];

        // ====
        // *Initial Investment Account (regular monthly investment)
        // ====

        // * At beginning of the month
        // Purchase units at the beginning of the month
        // Allocate welcome bonus units at the beginning of the month (36 months)
        // Allocate customer propm bonus at the beginning of the month (12 months)
        // Minus initial account charges at the beginning of the month (throughout the policy term)

        // insert the month index
        createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

        if (i < totalPremiumMonths) {
            // monthly investment
            createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,###', align: "center" });
            totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
        } else {
            // monthly investment
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
        }

        // cumulative premium
        createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,###', align: "center" });

        // calculate the date of the current month
        const currentMonthStartDate = date.addMonths(currentMonth1st, i);
        createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
        // excelRow.push({ type: String, value: date.format(currentMonthStartDate, 'DD/MM/YYYY', false), align: "center"});

        // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

        let UTprice = fundPriceData[i];
        createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
        // console.log("UTprice at month start: ", UTprice);

        // opening balace = previous month end value (monthEndValue)
        let openingBalance_IUA = previousMonthEndUnitBalance_IUA;
        createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
        // console.log("openingBalance_IUA: ", openingBalance_IUA);

        // plus purchase of the units at the beginning of month
        if (i < totalPremiumMonths) {
            const unitsPurchased = monthlyPremium / UTprice;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += unitsPurchased;
            // console.log("unitsPurchased: ", unitsPurchased);
        } else {
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
        }

        // plus booster bonus (booster units) for the first 36 months
        if (i < 36) {
            const boosterUnits = monthlyPremium * boosterBonusRate / UTprice;
            createExcel && excelRow.push({ type: Number, value: boosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += boosterUnits;
            // console.log("boosterUnits: ", boosterUnits);
        } else {
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
        }

        // plus promo booster bonus (promo booster units) for the first 12 months
        if (i < 12) {
            const promoBoosterUnits = monthlyPremium * promoBoosterBonusRate / UTprice;
            createExcel && excelRow.push({ type: Number, value: promoBoosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += promoBoosterUnits;
            // console.log("promoBoosterUnits: ", promoBoosterUnits);
        } else {
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
        }

        // plus loyalty bonus units from the 11th policy year onwards
        if (i >= 120 && (i % 12 === 0)) {
            const loyaltyBonusUnits = openingBalance_IUA * loyaltyBonusRate;
            createExcel && excelRow.push({ type: Number, value: loyaltyBonusUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += loyaltyBonusUnits;
            // console.log("loyaltyBonusUnits: ", loyaltyBonusUnits);
        } else {
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });
        }

        // minus IUA charges
        const IUACharges = openingBalance_IUA * IUAChargesRate;
        createExcel && excelRow.push({ type: Number, value: IUACharges, format: '#,##0.000000', align: "center" });
        openingBalance_IUA -= IUACharges;
        // console.log("IUACharges: ", IUACharges);

        // * At end of month
        // month end date 
        const currentMonthEndDate = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
        createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
        // console.log("currentMonthEndDate: ", currentMonthEndDate.toString());

        // UT price at the end of current month
        UTprice = fundPriceData[i + 1];
        createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
        // console.log("UTprice at month end: ", UTprice);

        // units at end of month (IUA end units)
        createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
        // console.log("endingBalance_IUA: ", openingBalance_IUA);

        const value_IUA = openingBalance_IUA * UTprice;
        createExcel && excelRow.push({ type: Number, value: value_IUA, format: '#,##0.000000', align: "center" });
        // console.log("value_IUA: ", value_IUA);
        // console.log("=======");

        // add empty cell
        createExcel && excelRow.push({ type: String, value: '', align: "center" });

        // storing IUA current end month value as the beginning IUA value of the next month
        previousMonthEndUnitBalance_IUA = openingBalance_IUA;

        // ====
        // *Accumulation Unit Account (top up)
        // ====

        // filling the month index and date for Accumulation Unit Account (AUA)
        // insert the month index
        createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

        // calculate the date of the current month
        createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
        // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

        // ut price at the beginning of the month
        UTprice = fundPriceData[i];
        createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });


        // filling the rest of the AUA columns with 0
        const zerosToAdd = 2;
        for (let j = 0; j < zerosToAdd; j++) {
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" }); // adding 0 to the remaining columns (AUA)        
        }

        // * At end of month
        // month end date 
        createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
        // console.log("currentMonthEndDate: ", currentMonthEndDate.toString());

        // ut price at the end of the month
        UTprice = fundPriceData[i + 1];
        createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

        // units at end of month (AUA end units)
        createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });

        const value_AUA = 0;
        createExcel && excelRow.push({ type: Number, value: value_AUA, format: '#,##0.000000', align: "center" });

        // add empty cell
        createExcel && excelRow.push({ type: String, value: '', align: "center" });

        // total value IUA + AUA, AUA value is 0 at the end of 24 months
        createExcel && excelRow.push({ type: Number, value: value_IUA, format: '$#,##0.000000', align: "center" });

        // store the excel row
        createExcel && excelData.push(excelRow);

    } // end for loop

    // calculate total and format totalValue into dollars
    let totalValue = (previousMonthEndUnitBalance_IUA + previousMonthEndUnitBalance_AUA) * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00
    // console.log(`Total Value: ${totalValue}`);

    return { insurer: "FWD", planName: "Invest Goal X", availablity: true, totalInvestedPremium, IUA: previousMonthEndUnitBalance_IUA, AUA: previousMonthEndUnitBalance_AUA, totalValue, excelFileName, excelSchema: createExcelSchema(), excelData }

} // end investGoalX

export default investGoalX;