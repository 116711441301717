const format2D = (text) => String("0" + text).slice(-2);

const getCurrentTime = (fileName) => {
    const now = new Date();

    const month = format2D(now.getMonth() + 1);
    const day = format2D(now.getDate());
    const hr = format2D(now.getHours());
    const min = format2D(now.getMinutes());

    const currentTime = `(${now.getFullYear()}-${month}-${day} ${hr}-${min})`

    return fileName
        ? `${fileName} ${currentTime}`
        : currentTime
}

exports.getCurrentTime = getCurrentTime;