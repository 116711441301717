/**
 * Divides a given value by 100 and returns the result.
 * This will prevent floating point errors.
 * @param {number} value - The value to be divided by 100.
 * @returns {number} The result of dividing the given value by 100.
 */

const divideBy100 = (value) => {

    const textValue = "00" + (value >= 0 ? value.toString() : (value * -1).toString());
    const array = textValue.split(".");

    array[0] = array[0].substring(0, array[0].length - 2) + "." + array[0].substring(array[0].length - 2, array[0].length);
    // console.log(array[0])
    // console.log((array.length === 1) ? parseFloat(array[0]) : parseFloat(array[0].concat(array[1])))

    const tempResult = (array.length === 1) ? parseFloat(array[0]) : parseFloat(array[0].concat(array[1]));
    return (value >= 0) ? tempResult : tempResult * -1;
}

export default divideBy100