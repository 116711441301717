import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

// prime react 
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';

// user context
import { UserContext } from 'contexts/userContext';

// custom component 
import DisplayCarousel from '_dashboard/modules/card/DisplayCarousel';
import FormCardContent from '_dashboard/modules/card/FormCardContent';

// custom function
const getDisplayArray = (arr) => {

    // if arr is undefined or null, return empty array
    if (!arr || arr?.length === 0) {
        return [];
    }

    return arr.map((item, i) => {
        return {
            url: item,
            isSelected: i === 0 ? true : false,
            dataURL: "" // to store the data url for html2image
        }
    })
}

// component
const CreateContactCard = () => {

    // user context
    const user = useContext(UserContext);

    // ref (since no need to update so use ref to store the value -> only render user.userDetails once)
    const profilePicArray = useRef(getDisplayArray(user?.userDetails?.avatarURL));
    const backgroundArray = useRef(getDisplayArray(user?.userDetails?.backgroundURL));

    // states
    const [profilePic, setProfilePic] = useState(profilePicArray?.current[0]?.url);
    const [backgroundPic, setBackgroundPic] = useState(backgroundArray?.current[0]?.url);

    // navigate
    const navigate = useNavigate();
    const goToProfile = () => {
        navigate('/profile');
    }
    
    // write the code for modal
    // open modal to inform user to update min 1 profile pic and 1 background pic
    if (profilePicArray?.current?.length === 0 || backgroundArray?.current?.length === 0) {
        return (
            <div className='grid'>
                <div className='col-12'>
                    <div className='card'>
                        <h6>Please upload at least 1 profile picture and 1 background picture before using this function</h6>
                        <div>
                            <Button label="Submit" onClick={goToProfile}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='grid'>
            <div className='col-12'>
                <div className='card'>
                    <h5>Appt Card</h5>
                    <p>Create appointment cards to better communicate with clients</p>
                    <ol>
                        <li>Select your preferred profile picture</li>
                        <li>Select your preferred background picture</li>
                        <li>Enter the title of the communication card</li>
                        <li>Key in the sub-title</li>
                        <li>Input the contents</li>
                        <li>Click [Generate Card]</li>
                    </ol>
                </div>
            </div>
            <div className='col-12'>
                <div className='card'>
                    <TabView>
                        <TabPanel header="Profile Pic">
                            <p>Choose the profile picture for this card</p>
                            <div>
                                <DisplayCarousel type="avatarURL" values={profilePicArray.current} update={setProfilePic} />
                            </div>
                        </TabPanel>
                        <TabPanel header="Background Pic">
                            <p>Choose the background picture for this card</p>
                            <div>
                                <DisplayCarousel type="backgroundURL" values={backgroundArray.current} update={setBackgroundPic} />
                            </div>
                        </TabPanel>
                    </TabView>
                    <FormCardContent profileURL={profilePic} backgroundURL={backgroundPic} />
                </div>
            </div>
        </div>
    )
}

export default CreateContactCard