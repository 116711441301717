import React from 'react'
import PropTypes from 'prop-types';

// customer helper
import { isoTimeStampToDate } from 'helpers/isoTimeStampToDateFormat';

// custom helper method 
import unescapeSpecialChar from 'helpers/unescapeSpecialChar';

// component
const DisplayILP101Details = (props) => {


    // extract data, details, customerPromo from props and set to {} if undefined
    const data = props.data || {};
    const details = data.details || {};
    const customerPromo = details.customerPromo || {};

    // function to display customer Prom
    const displayPromo = () => {
        return customerPromo?.valid
            ? <>
                <h5>Promotion Details</h5>
                <ul>
                    <li>Promotion Name: {customerPromo.promoName}</li>
                    {customerPromo.promoDetails.map((detail, i) => detail !== "" && <li key={i}>{unescapeSpecialChar(detail)}</li>)}
                </ul>
            </>
            : <></>
    }

    const displayItem = (key, name) => {
        // console.log("details @ displayItem", key)
        return details && details[key] && details[key][0] !== ""
            ? <>
                <h5>{name}</h5>
                <ol>
                    {details[key].map((detail, i) => <li key={i}>{unescapeSpecialChar(detail)}</li>)}
                </ol>
            </>
            : <></>
    }

    const displayOnlineMaterial = () => {

        // console.log("material @ displayOnlineMaterial", details.material)

        return details?.material && details.material[0]?.upload
            ? <>
                <h5>Information Online</h5>
                <ol>
                    {details.material.map((detail, i) => 
                        <li key={i}><a href={detail.url} target="_blank" rel="noreferrer">{detail.fileName}</a></li>)}
                </ol>
            </>
            : <></>
    }

    return (
        <div className='col-12'>
            <div className='card'>
                <h5>{`${data.insurer} ${data.planName}`}</h5>
                <ul>
                    {data.updatedAt && <li>Premium updated on: {isoTimeStampToDate(data.updatedAt)}</li>}
                    {details.updatedAt && <li>Product info updated on: {isoTimeStampToDate(details.updatedAt)}</li>}                    
                </ul>
                {displayPromo()}
                {displayItem("features", "Features")}
                {displayItem("premiumHoliday", "Premium Holiday Details")}
                {displayItem("partialSurrender", "Partial Surrender Details")}
                {displayItem("fullSurrender", "Full Surrender Details")}
                {displayItem("loan", "Loan Details")} 
                {displayItem("notes", "Notes To Advisers & Clients")} 
                {displayOnlineMaterial()}
            </div>
        </div>
    );
}

export default DisplayILP101Details

DisplayILP101Details.propTypes = {
    data: PropTypes.object
}