const breakEven_eTiQaInvestSmartFlex = [
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 1.23,
        "totalValue": 60008.748429043626
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 1.23,
        "totalValue": 90013.1226435655
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.95,
        "totalValue": 120005.19866142655
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.95,
        "totalValue": 180007.79799213973
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.95,
        "totalValue": 240010.3973228531
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.95,
        "totalValue": 360015.59598427947
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.95,
        "totalValue": 480020.7946457062
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 300,
        "breakEvenYield": 1.31,
        "totalValue": 54003.72632702696
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 500,
        "breakEvenYield": 1.31,
        "totalValue": 90006.210545045
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 750,
        "breakEvenYield": 1.11,
        "totalValue": 135055.84541615323
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.11,
        "totalValue": 180074.46055487098
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.11,
        "totalValue": 270111.69083230646
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.11,
        "totalValue": 360148.92110974196
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.11,
        "totalValue": 540223.3816646129
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.11,
        "totalValue": 720297.8422194839
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 300,
        "breakEvenYield": 1.28,
        "totalValue": 72061.45663517762
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 500,
        "breakEvenYield": 1.1,
        "totalValue": 120100.33476175697
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 1.1,
        "totalValue": 180150.50214263558
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.1,
        "totalValue": 240200.66952351393
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.1,
        "totalValue": 360301.00428527116
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.1,
        "totalValue": 480401.33904702787
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.1,
        "totalValue": 720602.0085705423
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.1,
        "totalValue": 960802.6780940557
    }
];

exports.breakEven_eTiQaInvestSmartFlex = breakEven_eTiQaInvestSmartFlex;