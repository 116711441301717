import React from 'react'
import { Routes, Route } from 'react-router-dom';

// ===============
// user modules
// ===============

import Feedback from './modules/usersManagement/Feedback';
import UpdateUser from './modules/usersManagement/UpdateUser';
import PvFvPmtRate from './modules/calculator/PvFvPmtRate';
import IrrXirr from './modules/calculator/IrrXirr';
import CreateContactCard from './modules/card/CreateContactCard';
import FormInsuredDetails from './modules/comparison/FormInsuredDetails';
import CompareShield from './modules/comparison/type/shield/CompareShield';

import CompareLongTermCare from './modules/comparison/type/longTermCare/CompareLongTermCare';
import CompareMultiCI from './modules/comparison/type/multiPayCI/CompareMultiCI';
import CompareRetirementMonthlyIncome from './modules/comparison/type/retirementMonthlyIncome/CompareRetirementMonthlyIncome';
import LifetimeIncome from './modules/comparison/type/lifetimeIncome/CompareLifeTimeIncome';
import CompareTerm from './modules/comparison/type/term/CompareTerm';
import CompareWholeLifeEndowment from './modules/comparison/type/wholeLifeEndowment/CompareWholeLifeEndowment';
import CompareWholeLife from './modules/comparison/type/wholeLife/CompareWholeLife';
import Compare101RP from './modules/comparison/type/ilp101_RP/Compare101RP';
import CompareUniversalLifeIndex from './modules/comparison/type/universalLife_Indexed/CompareUniversalLifeIndex';
import ComparisonOverview from './modules/comparisonOnline/ComparisonOverview';

/*
import JourneyOfWealth from './modules/finerty/JourneyOfWealth';
import JourneyToRetire from './modules/finerty/JourneyToRetire';
import JourneyOfHealth from './modules/finerty/JourneyOfHealth';
import JourneyForLegacy from './modules/finerty/JourneyForLegacy';
*/

import HomePage from './HomePage';

const DashboardRoutes = () => {
    return (
        <Routes>
            {/* home */}
            <Route path="/" element={<HomePage />} />

            {/* UI Setting */}
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/profile" element={<UpdateUser />} />

            {/* calculators */}
            <Route path="/calculator/pv-fv-pmt-rate" element={<PvFvPmtRate />} />
            <Route path="/calculator/irr-xirr" element={<IrrXirr />} />

            {/* eCard */}
            <Route path="/card" element={<CreateContactCard />} />

            {/* comparisons */}
            <Route path="/comparison/insuredDetails" element={<FormInsuredDetails />} />

            <Route path="/comparison/101RP" element={<Compare101RP />} />

            <Route path="/comparison/integratedShield" element={<CompareShield />} />

            <Route path="/comparison/longTermCare" element={<CompareLongTermCare />} />
            <Route path="/comparison/multiCI" element={<CompareMultiCI />} />
            <Route path="/comparison/lifetimeIncome" element={<LifetimeIncome />} />
            <Route path="/comparison/retirementIncomePlan" element={<CompareRetirementMonthlyIncome />} />
            <Route path="/comparison/term" element={<CompareTerm />} />
            <Route path="/comparison/universalLifeIndex" element={<CompareUniversalLifeIndex />} />
            <Route path="/comparison/wholeLifeEndowment" element={<CompareWholeLifeEndowment />} />
            <Route path="/comparison/wholeLife" element={<CompareWholeLife />} />
            <Route path="/comparison/manage" element={<ComparisonOverview />} />


            {/* finerty */}
            {/*
                <Route path="/finerty/journeyOfWealth" element={<JourneyOfWealth />} />
                <Route path="/finerty/journeyToRetire" element={<JourneyToRetire />} />
                <Route path="/finerty/journeyOfHealth" element={<JourneyOfHealth />} />
                <Route path="/finerty/journeyForLegacy" element={<JourneyForLegacy />} />     
            */}

            <Route path="*" element={<HomePage />} />
        </Routes>
    )
}
export default DashboardRoutes