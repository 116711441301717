import React, { useReducer, createContext } from 'react'

// reducer functions are store in a separate file for cleaner code
import userContextReducer from "./userContextReducer";

const initialValues = {}

export const UserContext = createContext();
export const DispatchUserContext = createContext();

export const UserContextProvider = (props) => {
  const [userContext, dispatchUserContext] = useReducer(userContextReducer, initialValues);

  return (
    <UserContext.Provider value={userContext}>
      <DispatchUserContext.Provider value={dispatchUserContext}>
        {props.children}
      </DispatchUserContext.Provider>
    </UserContext.Provider>
  );
}