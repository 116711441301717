import React, {useState, useRef} from 'react'

import { TabView, TabPanel } from 'primereact/tabview';

import FormPvFvPmtRate from './FormPvFvPmtRate';

const PvFvPmtRate = () => {

    // storing the index of active tab
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    // ref to focus on form
    const inputRef = useRef(null);

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Calculate PV | FV | Rate</h5>
                    <p>Each new user will require the following information</p>
                    <ol>
                        <li>All fields are required</li>
                        <li>All calculations are in the beginning mode</li>
                        <li>Key in +ve values if receive payment (inflow: a person receives)</li>
                        <li className="text-pink-500">Key in -ve values if make payment (outflow: a person makes payment or invests)</li>
                    </ol> 
                </div>
            </div>

            <div className="col-12">
                <div className="card" ref={inputRef}>
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>                                
                        <TabPanel header="Present Value (PV)" >
                            <FormPvFvPmtRate type={"PV"}/>
                        </TabPanel>
                        <TabPanel header="Future Value (FV)">
                            <FormPvFvPmtRate type={"FV"}/>
                        </TabPanel>   
                        <TabPanel header="Payment (PMT)">
                            <FormPvFvPmtRate type={"PMT"}/>
                        </TabPanel>                             
                        <TabPanel header="Rate (%)">
                            <FormPvFvPmtRate type={"Rate"}/>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    )
}

export default PvFvPmtRate