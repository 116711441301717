import { useContext, useEffect } from 'react'
import { UserContext } from 'contexts/userContext'

// custom hook to get the new jwt token from backend
import useRefreshToken from 'hooks/useRefreshToken';

// Axios Auth
import { axiosAuth } from 'helpers/axios';

// to return the refresh token from back
const useAxiosAuth = () => {

    /// check if type is json or form data ()

    const user = useContext(UserContext);
    const getRefreshToken = useRefreshToken();

    useEffect(() => {

        const requestIntercept = axiosAuth.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${user.userJWT}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosAuth.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await getRefreshToken();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosAuth(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosAuth.interceptors.request.eject(requestIntercept);
            axiosAuth.interceptors.response.eject(responseIntercept);
        }
    }, [getRefreshToken, user])

    return axiosAuth;
}

export default useAxiosAuth;
