import React, { useCallback, useContext, useState, useEffect } from 'react'

// user context
import { UserContext } from 'contexts/userContext';

// prime react
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom components
import LoadingScreen from 'components/LoadingScreen';
import ComparisonDetails from '_dashboard/modules/comparisonOnline/ComparisonDetails';

// custom helper function
import { isoTimeStampToDate } from 'helpers/isoTimeStampToDateFormat';

// helper function
const comparisonTypes = {
  integratedshield: "Shield Plan",
  term: "Term Plan",
  retirementincome: "Retirement Income",
  longtermcare: "Long Term Care",
  multipayci: "MultiPay CI",
  lifetimeincome: "Lifetime Income Plan",
  universallifeindex: "Indexed UL",
  wholelifeendowment: "Whole Life Endowment",
  wholelife: "Whole Life Plan",
  "101": "101 ILP"

}

// component
const ComparisonOverview = () => {

  // state
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [firstRefresh, setFirstRefresh] = useState(true);

  // data-table header states
  const [expandedRows, setExpandedRows] = useState(null);
  const [mobileScreen, setMobileScreen] = useState(false)
  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const [filters2, setFilters2] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // user context
  const user = useContext(UserContext);
  // console.log("@ ComparisonOverview -> user:", user);

  const RNF = user?.userDetails?.rnf
  // console.log("@ ComparisonOverview -> RNF:", RNF);

  // axios instance
  const axiosInstance = useAxiosAuth();

  // check screen size to decide what label to display
  const screenSize = useScreenSize();

  // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
  useEffect(() => {
    setMobileScreen(screenSize.width <= 600);
  }, [screenSize.width]);

  const handleRefresh = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post('/dashboard/comparisonUpload/getAllComparisonByAdvisor', { RNF })

      // if response is valid
      if (res?.data?.success) {
        setTableData(res.data?.comparison)
      } else {
        setLoading(false);
        alert("Error fetching data")
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Error fetching data")
      console.log("error", error);
    }
    setFirstRefresh(false);
  };


  // =====
  // * generete overview table
  // =====
  const generateOverviewTable = useCallback(() => {

    // functions to format data in columns
    const formatCreatedAt = (rowData) => isoTimeStampToDate(rowData.createdAt, " ");
    const formatExpiredOn = (rowData) => isoTimeStampToDate(rowData.expiresAt, " ");
    const formatLastViewed = (rowData) => (rowData.lastViewed.length > 0) ? isoTimeStampToDate(rowData.lastViewed.at(-1), " ", true) : "Not viewed yet"
    const formatComparisonType = (rowData) => (comparisonTypes[rowData?.comparisonType.toLowerCase().trim()] || "") || "Unknown Type";
    const formatStatus = (rowData) => rowData.disabled ? "Disabled" : (rowData.expiresAt > Date.now() ? "Expired" : "Live");

    // function to expand all and collapse all expanders
    const collapseAll = () => setExpandedRows(null);

    const expandAll = () => {
      let _expandedRows = {};
      tableData.forEach(p => _expandedRows[`${p._id}`] = true);
      setExpandedRows(_expandedRows);
    }

    // filter function for key search
    const onGlobalFilterChange2 = (e) => {
      const value = e.target.value;
      let _filters2 = { ...filters2 };
      _filters2['global'].value = value;

      setFilters2(_filters2);
      setGlobalFilterValue2(value);
    }

    const renderHeader = () => <>
      <div className="flex justify-content-between">
        <span>
          <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
          <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder={mobileScreen ? "Search" : "Keyword Search"}
            className={mobileScreen ? "p-inputtext-sm" : ""}
            value={globalFilterValue2}
            onChange={onGlobalFilterChange2}
          />
        </span>
      </div>
    </>

    const rowExpansionTemplate = (data) => <ComparisonDetails data={data}/>

    return <div className='mb-3 mt-5'>
      <DataTable value={tableData}
        stripedRows
        responsiveLayout="scroll"
        dataKey="_id"
        paginator

        // header
        header={renderHeader()}

        // keyword search props
        filters={filters2} filterDisplay="row"

        // expander props
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}

        // display rows options
        rows={25}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="clientDescription" header="Client" sortable style={{ width: '20%' }}></Column>
        <Column field="comparisonType" header="Type" body={formatComparisonType} sortable style={{ width: '20%' }}></Column>
        <Column field="createdAt" header="Created At" body={formatCreatedAt} sortable style={{ width: '15%' }}></Column>
        <Column field="expiresAt" header="Expired On" body={formatExpiredOn} sortable style={{ width: '15%' }}></Column>
        <Column field="lastViewed" header="Last View" body={formatLastViewed} sortable style={{ width: '20%' }}></Column>
        <Column field="lastViewed" header="Status" body={formatStatus} sortable style={{ width: '10%' }}></Column>
      </DataTable>
    </div>

  }, [expandedRows, filters2, globalFilterValue2, mobileScreen, tableData]);

  // console.log("@ ComparisonOverview -> tableData:", tableData);

  return (
    <div className='grid'>
      <div className='col-12'>
        <div className='card'>
          <h5>Online Comparisons Link Manager</h5>
          <p>The module helps you effectively manage your online insurance comparison links. Here’s what you can do with this tool:</p>
          <ol>
            <li><strong>View All Active Links:</strong> Easily see a list of all the insurance comparison links you have generated for your clients. Keep track of which comparisons are currently accessible online.</li>
            <li><strong>Track Link Activity:</strong> Get insights on when each link was clicked by your clients. This helps you understand client engagement and follow up at the right time.</li>
            <li><strong>Terminate Links Instantly:</strong> If needed, you can immediately disable any online insurance comparison link. This ensures that outdated or sensitive comparisons are no longer accessible.</li>
            <li><strong>Extend Link Expiry:</strong> By default, each insurance comparison link is active for 8 days. If you need more time, you can extend the link for another 8 days with just one click.
            </li>
          </ol>
          <p>This module is designed to help you manage your comparison links easily, giving you better control and insights while providing a seamless experience for your clients.</p>
        </div>
      </div>
      <div className='col-12'>
        <div className='card'>
          <div className='flex flex flex-row-reverse'>
            <Button label={firstRefresh ? "Display All Live Comparison" : "Refresh Comparison List"} className="p-button-raised p-button-primary" onClick={handleRefresh} />
          </div>
          <Divider />
          {generateOverviewTable()}
        </div>
      </div>
      {loading && <LoadingScreen />}
    </div>
  )
}

export default ComparisonOverview