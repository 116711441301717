import React, { useState, useEffect, useContext, useCallback, Suspense, useRef } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SelectButton } from 'primereact/selectbutton';
import { Messages } from 'primereact/messages';

// user context
import { UserContext } from 'contexts/userContext';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';
// import LoadingScreen from 'components/LoadingScreen';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import scrollToTop from 'helpers/scrollToTop';
// import formatDollar from 'helpers/formatDollar';

// Whole Life Endowment comparison insurers
import { insurer_wholeLifeEndowment, userContext_wholeLifeEndowment } from 'settings';

const totalPremium = [
    { label: '$300,000', amt: 300000 },
    { label: '$150,000', amt: 150000 },
    { label: '$100,000', amt: 100000 },
];

const PremiumTermList = [
    { label: 'Single Premuim', term: "1Payment", key: "1Payment" },
    { label: '5 Pay', term: "5Payment", key: "5Payment" },
    { label: '10 Pay', term: "10Payment", key: "10Payment" },
    { label: '15 Pay', term: "15Payment", key: "15Payment" },
    { label: '20 Pay', term: "20Payment", key: "20Payment" },
]

// formik
const initialValues = {
    totalPremium: 300000,
    premiumTermList: ['1Payment'],
}

// options for the select view buttons
const viewOptions = [
    { icon: 'pi pi-book', value: 'default' },
    { icon: 'pi pi-percentage', value: 'kpis' },
];

const viewYear = [
    { name: 'Compare at 60 Yrs', value: 60 },
    { name: 'Compare at 50 Yrs', value: 50 },
    { name: 'Compare at 40 Yrs', value: 40 },
    { name: 'Compare at 30 Yrs', value: 30 },
    { name: 'Compare at 20 Yrs', value: 20 }
];

const currencyLocaleOption = { style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: 'USD' };

const CompareWholeLifeEndowment = () => {

    // Ref for messages
    const messages = useRef(null);

    // state
    const [data, setData] = useState([]); // data from mongoDB
    const [displayData, setDisplayData] = useState([]);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
    const [lastSearchParameter, setLastSearchParameter] = useState({});
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState({})

    // to select display policy values or KPIs
    const [view, setView] = useState('default');
    const [viewSelectAge, setViewSelectAge] = useState(40);

    // data-table header states
    const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // user context
    const user = useContext(UserContext);

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width]);

    // loading all data once when the page is loaded
    useEffect(() => {

        // return to insured details page if insured details are not entered
        if (!user.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_compareWholeLifeEndowment" });
        }

        const getData = async () => {
            // console.log(user)
            setLoading(true);
            try {
                const res = await axiosInstance.post('dashboard/comparison/wholeLifeEndowment', {
                    anb: user?.comparisonInsured?.ANB,
                    ageNearestBirthday: user?.comparisonInsured?.AnB,
                    gender: user?.comparisonInsured?.gender?.charAt(0),
                    smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
                });
                // console.log("res.data" + res.data);

                if (res.data) {
                    // console.log(res.data);
                    let premiumArr = res.data.result[0]; // array that contains all premium data point from mongo
                    const productArr = res.data.result[1];  // array that contains all product info for this category 

                    // sort premiumArr by premium (descending) follow by insurer name (ascending) name
                    premiumArr = sortArrayOfObjectsByKey(premiumArr, "type", "desc");
                    premiumArr = sortArrayOfObjectsByKey(premiumArr, "insurer", "asc");

                    const combineArrData = premiumArr.map(entry => {

                        // getting the insurer & plan name to match the corresponding product info
                        const insurer = entry.insurer.trim();
                        const plan = entry.planName.trim();

                        // to find the corresponding
                        const entryInfo = productArr.find(p => p.insurerName.trim() === insurer && p.planName.trim() === plan)// end filter
                        return entryInfo
                            ? { ...entry, details: entryInfo, promo: entryInfo?.customerPromo?.valid }
                            : { ...entry, details: {}, promo: false, }

                    }) // end map
                    // console.log("combineArrData @ useEffect", combineArrData);

                    setData(combineArrData);
                    setLoading(false);
                }
            } catch (error) {
                // console.log(error);
                messages.current.show({ severity: 'error', detail: `Error: Backend server is down`, sticky: true });
                setLoading(false);
            }
        }
        getData();
    }, []); // eslint-disable-line

    // formik validator with yup to check the value is not an empty string array based on active tab index
    const validationSchema = yup.object({
        totalPremium: yup.number().typeError("Please select a total premium amount").required("Please select a total premium amount"),
        premiumTermList: yup.array().test('is-not-empty', 'Please select at least one sum insured amount', value => value.length > 0)
    });

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    // checkbox formilk functions
    // check if the box's value is selected based on formik values (formik.values.premiumTermList)
    const isChecked = amt => (formik.values.premiumTermList).some((item) => item === amt);

    // check if the select amount is in the list
    const handleListChange = amt => (formik.values.premiumTermList).some((item) => item === amt)
        ? formik.setFieldValue("premiumTermList", formik.values.premiumTermList.filter((item) => item !== amt)) // if yes, remove from list
        : formik.setFieldValue("premiumTermList", [...formik.values.premiumTermList, amt]); // if no, add to list

    // handle form submit
    const handleSubmit = (values) => {

        // saving the search parameter to be used in upload 
        setQuery({
            anb: user?.comparisonInsured?.ANB,
            ageNearestBirthday: user?.comparisonInsured?.AnB,
            gender: user?.comparisonInsured?.gender[0],
            smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
            ...values,
        });

        // filter data based on total premium in comparison
        let result = data.filter(entry => entry.comparisonTotalPremium === values.totalPremium);

        const { premiumTermList } = values;
        if (premiumTermList.length > 0) {
            const temp = premiumTermList.map(entry => +entry.replace("Payment", ""));
            // using the values in temp array and check if they appears in data's premiumTerm
            result = result.filter(entry => temp.includes(entry.premiumTerm));
        }

        // sort result insurer name (ascending) name
        result = sortArrayOfObjectsByKey(result, "insurer", "asc");

        const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_wholeLifeEndowment)?.onlyDisplayInsurers;
        // console.log("allowedInsurers", allowedInsurers);

        // filter out the insurers that are not allowed to be displayed
        // of the allowedInsurers is empty, display all insurers
        (allowedInsurers && allowedInsurers.length > 0)
            ? setDisplayData(() => result.filter(ele => allowedInsurers.includes(ele.insurer)))
            : setDisplayData(() => result); // use the mongoData if allowedInsurers.length === 0 (display all insurers)

        // setDisplayData(result);
        setReadyToSearch(false);
        setShowEditButton(true);

        // console.log(JSON.stringify(lastSearchParameter));
        // console.log(JSON.stringify(values));
        (JSON.stringify(lastSearchParameter) !== JSON.stringify(values)) && setLastSearchParameter(values);
        scrollToTop();
    }

    useEffect(() => {
        // only update the displayData if the lastSearchParameter is not empty (search more than once)
        (Object.keys(lastSearchParameter).length > 0) && handleSubmit(lastSearchParameter);
    }, [user]) // eslint-disable-line

    const handleReset = () => {
        // formik.handleReset();
        formik.setFieldValue("premiumTermList", []);
        formik.setFieldValue("totalPremium", null);
        setDisplayData([]);
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    const handleEdit = () => {
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    const generateComparison = useCallback(() => {

        // functions to format data in columns
        const formatPremiumTerm = (value) => `${value.premiumTerm} ${+value.premiumTerm === 1 ? "Yr" : "Yrs"}`;
        const formatBreakEvenYearTotal = (value) => value[`breakEvenYear_Total`] + " Yrs";
        const formatBreakEvenYearGtd = (value) => value[`breakEvenYear_Guaranteed`] + " Yrs";

        const formatSurrenderValueGtd = (value) => value[`surrenderValue${viewSelectAge}Gtd`].toLocaleString('en-US', currencyLocaleOption);
        const formatSurrenderValueTotal = (value) => value[`surrenderValue${viewSelectAge}Total`].toLocaleString('en-US', currencyLocaleOption);
        const formatGtdYield = (value) => `${viewSelectAge ? value.xirr40_gtd : value.xirr60_gtd}% p.a.`;
        const formatTotalYield = (value) => `${viewSelectAge ? value.xirr40_total : value.xirr60_total}% p.a.`;

        const formatAnnual = (value) => value.annualPremium.toLocaleString('en-US', currencyLocaleOption);
        const formatPromo = (value) => value.promo ? "Yes" : "";

        // function to expand all and collapse all expanders
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p._id}`] = true);
            setExpandedRows(_expandedRows);
        }

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        // function to display icon for select button
        const displayIcon = (option) => {
            return <i className={option.icon}></i>;
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                <span>
                    <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                    <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
                <span>
                    <SelectButton value={view} onChange={e => setView(() => e?.target?.value)} itemTemplate={displayIcon} optionLabel="value" options={viewOptions} className='inline' unselectable={false} />
                </span>
            </div>
            {
                view !== "default" && <div className="flex justify-content-end mt-2">
                    <Dropdown value={viewSelectAge} onChange={(e) => setViewSelectAge(e.value)} options={viewYear} optionLabel="name" placeholder="Compare At Year" className="w-full md:w-12rem" />
                </div>
            }
        </>

        const rowExpansionTemplate = (data) => <DisplayProductDetails data={data} />

        return <div className='col-12'>
            <div className='card'>
                <DisplayComparisonTitle type="Whole Life Endowment" />
                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        // footer
                        footer={<DataTableFooter type="wholeLifeEndowment" query={query} />}

                        // expander props
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="_id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="insurer_shortName" header="Insurer" sortable></Column>
                        <Column field="plan_shortName" header="Plan" sortable></Column>
                        <Column field="premiumTerm" header="Premium Term" body={formatPremiumTerm} sortable></Column>

                        {view === "default" && <Column field="breakEvenYear_Total" header={`Break Even Yr (Total)`} body={formatBreakEvenYearTotal} sortable></Column>}
                        {view === "default" && <Column field="breakEvenYear_Guaranteed" header={`Break Even Yr (Gtd)`} body={formatBreakEvenYearGtd} sortable></Column>}

                        {view === "kpis" && <Column field={`surrenderValue${viewSelectAge}Gtd`} header={`Gtd Surr Value (${viewSelectAge} Yrs)`} body={formatSurrenderValueGtd} sortable></Column>}
                        {view === "kpis" && <Column field={`surrenderValue${viewSelectAge}Total`} header={`Total Surr Value (${viewSelectAge} Yrs)`} body={formatSurrenderValueTotal} sortable></Column>}
                        {view === "kpis" && <Column field={`xirr${viewSelectAge}_gtd`} header={`Gtd Yield (${viewSelectAge} Yrs)`} body={formatGtdYield} sortable></Column>}
                        {view === "kpis" && <Column field={`xirr${viewSelectAge}_total`} header={`Total Yield (${viewSelectAge} Yrs)`} body={formatTotalYield} sortable></Column>}

                        <Column field="annualPremium" header="Annual Premium" body={formatAnnual} sortable></Column>
                        {view === 'default' && <Column field="promo" header="Promo" body={formatPromo} sortable></Column>}
                    </DataTable>
                </Suspense>
            </div>
        </div>

    }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, viewSelectAge, view, query])

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }
            <div className='col-12 md:col-6'>
                <div className='card'>

                    <Messages ref={messages} />

                    <h5>Select total premium</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.totalPremium}</p>}
                    <Dropdown value={formik.values.totalPremium} disabled={!readyToSearch} options={totalPremium}
                        onChange={e => formik.setFieldValue("totalPremium", e.value)} optionLabel="label" optionValue="amt" className='w-full' />

                    <h5>Select coverage amount</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.premiumTermList}</p>}
                    {PremiumTermList.map((premiumTerm, index) => {
                        return (
                            <div key={premiumTerm.key} className="field-checkbox">
                                <Checkbox
                                    inputId={premiumTerm.key}
                                    value={premiumTerm.term}
                                    checked={isChecked(premiumTerm.term)}
                                    disabled={!readyToSearch}
                                    onChange={e => handleListChange(e.value)} />
                                <label htmlFor={premiumTerm.key} className="ml-2">
                                    {premiumTerm.label}
                                </label>
                            </div>
                        );
                    })}

                    <hr></hr>
                    <div className='grid mt-3'>
                        <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                            <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                        </div>
                        {
                            showEditButton && <div className='col-12 md:col-4'>
                                <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                            </div>
                        }
                        <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                        <Button type="submit" label={loading ? "Loading Data..." : "Search"} aria-label="Search" className='w-full' disabled={loading || !readyToSearch} onClick={formik.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <DisplayInsuredDetails />
                <FormInsurerDisplayedOption comparisonType={userContext_wholeLifeEndowment} insurerList={insurer_wholeLifeEndowment} />
            </div>
            {
                // loading && <LoadingScreen />
            }
        </div>
    )
}

export default CompareWholeLifeEndowment