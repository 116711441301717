import React, { useEffect, useContext, useState, useCallback, Suspense } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";
import date from 'date-and-time';
import writeXlsxFile from 'write-excel-file';

// prime react
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SelectButton } from 'primereact/selectbutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';

// user context
import { UserContext } from 'contexts/userContext';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import FundPriceChart from 'components/FundPriceChart';
import ILP101ChartAndExcel from 'components/ILP101ChartAndExel';
import LoadingScreen from 'components/LoadingScreen';
import DisplayILP101Details from '_dashboard/modules/comparison/type/ilp101_RP/component/DisplayILP101Details';
import Display101ExtraInfo from '_dashboard/modules/comparison/type/ilp101_RP/component/Display101ExtraInfo';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import scrollToTop from 'helpers/scrollToTop';
import { insurerColour } from 'helpers/insurerColour';
import { createCustomChartData, createPIChartData } from 'helpers/investmentChart';

// 101 comparison insurers
import { insurer_101, userContext_101 } from 'settings';

// 101 Plans
import aia_proAchiever3 from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_aia_ProAchiever3';
import eTiqa_investSmartFlex from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_eTiQa_InvestSmartFlex';
import fwd_investFirstMax from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_fwd_InvestFirstMax';
import fwd_investGoalX from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_fwd_InvestGoalX';
import tm_goClassic from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_tm_goClassic';
import singlife_savvyInvestII from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_singlife_savvyInvest_II';
import hsbc_wealthVoyage from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_hsbc_wealthVoyage';
import income_investFlex from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/plan_income_investFlex';

// 101 breakeven yields
import { breakEven_aiaProAchiever3 } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_AIA_ProAchiever3';
import { breakEven_eTiQaInvestSmartFlex } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_eTiQa_InvestSmartFlex';
import { breakEven_fwdInvestFirstMax } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_FWD_InvestFirstMax';
import { breakEven_fwdInvestGoalX } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_FWD_InvestGoalX';
import { breakEven_tmgoClassic } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_TM_goClassic';
import { breakEven_singlifeSavvyInvest } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_singlife_savvyInvest_II';
import { breakEven_hsbcWealthVoyage } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_hsbc_wealthVoyage';
import { breakEven_incomeInvestFlex } from '_dashboard/modules/comparison/type/ilp101_RP/ilp101Data/breakEvenYield_Income_investFlex';

// import displayFormikLabel from 'helpers/displayFormikLabel';

// options for the select view buttons
const viewOptions = [
    /*{ icon: 'pi pi-book', value: 'default' },*/
    { icon: 'pi pi-dollar', value: 'default' },
    { icon: 'pi pi-percentage', value: 'kpis' },
];

// dropdown options
const generateDropDownValues = () => {

    const investmentPeriod = [], policyPremiumTerm = [];

    for (let i = 30; i >= 10; i -= 5) {
        investmentPeriod.push({ label: `${i} Years`, investmentPeriod: i, key: i });
        policyPremiumTerm.push({ label: `${i} Years`, policyPremiumTerm: i, key: i });
    }

    // temporary removed these values
    /*
    policyPremiumTerm.push({ label: `------`, policyPremiumTerm: "------", key: null });
    for (let i = 2; i <= 30; i++) {
        (i !== 4) && policyPremiumTerm.push({ label: `${i} Years`, policyPremiumTerm: i, key: i });
    }
    */
    return { investmentPeriod, policyPremiumTerm };
}
const { investmentPeriod, policyPremiumTerm } = generateDropDownValues();

const monthlyInvestmentAmount = [/*4000, 3000,*/ 2000, 1500, 1000, 500, 300].map(i => ({ label: `$${i} / mth`, monthlyInvestmentAmount: i, key: i }));

const fundGrowthPA = [15, 10, 9, 8, 7, 6, 5].map(i => ({ label: `${i}% p.a.`, fundGrowthPA: +(i / 100).toFixed(2), key: i }));

const fundInitalDrop = [25, 30, 40, 50].map(i => ({ label: `Drop by ${i}%`, fundInitalDrop: +((100 - i) / 100).toFixed(2), key: i }));

const growthType = [
    { label: `PI's Value (@ 8% p.a.)`, growthType: "PI", key: "PI" },
    { label: `Custom Growth (Only Policy Charge)`, growthType: "Custom", key: "Custom" }
]

// formik
const initialValues = {
    investmentPeriod: 20,
    policyPremiumTerm: 20,
    monthlyInvestmentAmount: 1000,
    growthType: "PI",
    fundGrowthPA: 0.09,
    fundInitalDrop: 0.75
}

// generate chart.js data, labels and options
const generateChartDataLabelsOptions = (result = [], displayTitleText = "Surrender Values") => {

    const labels = result.map(ele => `${ele?.insurer} - ${ele.planName}`);

    const data = {
        labels,
        datasets: [
            {
                label: "Projected Values",
                legend: false,
                data: result.map(ele => Math.round(ele.totalValue)),
                backgroundColor: result.map(ele => insurerColour(ele.insurer).rgba),
                borderColor: result.map(ele => insurerColour(ele.insurer).rgb),
                /*
                backgroundColor: [
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgb(255, 205, 86)',
                    'rgb(255, 159, 64)',
                    'rgb(54, 162, 235)',
                ],
                */
                borderWidth: 1
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: displayTitleText
            }
        }
    };

    return { data, labels, options };
}

// combine PI data and plan details for table display
const generateCombinedDisplayData = (data, details) => {
    const temp = data.map(ele => {
        const correspondingDetail = details.find(detail => detail.planName === ele.planName);
        return { ...ele, details: correspondingDetail };
    });

    // Special case for Singlife if Siglife is in the list of insurers
    // getting all index of data that contains Savings Invest II    
    const singlifeIndex = data.reduce((result, ele, index) => {
        ele.planName.startsWith("Savvy Invest II") && result.push(index);
        return result;
    }, []);
    // console.log("Singlife Index @ generateCombinedDisplayData", singlifeIndex);

    if (singlifeIndex.length > 0) { // result constains Singlife
        singlifeIndex.forEach(index => {
            temp[index] = { ...temp[index], details: details.find(detail => detail.planName === "Savvy Invest II") }
        }); // end of forEach
    } // end of if

    return temp;
}

// ====
// main component
// ====
const Compare101RP = () => {

    // state
    const [query, setQuery] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done

    const [mongoPIData, setMongoPIData] = useState([]); // to store PI data from mongoDB
    const [mongoPlanDetails, setMongoPlanDetails] = useState([]); // store plan details from mongoDB
    const [customCalculateChartData, setCustomCalculateChartData] = useState([]); // to store custom calculate chart data E.g. PI's growth, custom growth @ 10% p.a.

    const [activeTabIndex, setActiveTabIndex] = useState(0); // 0 for PI's illustration growth, 1 for custom growth
    const [displayFundChart, setDisplayFundChart] = useState(false); // to display fund chart
    const [fundChartData, setFundChartData] = useState([]); // chart data for <FundPriceChart />

    const [barChartData, setBarChartData] = useState({});
    const [barChartOption, setBarChartOption] = useState({});

    const [loading, setLoading] = useState(false); // to display loading screen

    // to select display policy values or KPIs
    const [view, setView] = useState('default');

    // data-table header states
    const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // user context
    const user = useContext(UserContext);

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width])

    useEffect(() => {
        if (!user.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // formik validator with yup to check the value is not an empty string array based on active tab index
    const validationSchema = yup.object({
        monthlyInvestmentAmount: yup.number().required("Please select a monthly investment amount").typeError("Please select a monthly investment amount"),
        policyPremiumTerm: yup.number().required("Please select the premium payment term").typeError("Please select the premium payment term"),
        investmentPeriod: yup.number().required("Please select the investment period").typeError("Please select the investment period")
            .when('policyPremiumTerm', (policyPremiumTerm, schema) => {
                return schema.min(policyPremiumTerm, 'Investment period cannot be shorter than policy premium payment term');
            }),
    });

    // =======
    // formik
    // =======
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    // to update display chart and table if the insurer display settings is changed
    useEffect(() => {

        // filter result with the insurer based on the list of insurer allowed. If the list is empty, then all insurer is allowed
        const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_101)?.onlyDisplayInsurers;
        // console.log("allowedInsurers in useEffect", allowedInsurers);
        // console.log("activeTabIndex: @ useEffect", formik.values?.growthType);

        let result = [];
        if (formik.values?.growthType === "PI") {
            result = (allowedInsurers && allowedInsurers.length > 0) ? mongoPIData.filter(ele => allowedInsurers.includes(ele.insurer)) : mongoPIData;
        } else { // custom growth
            result = (allowedInsurers && allowedInsurers.length > 0) ? customCalculateChartData.filter(ele => allowedInsurers.includes(ele.insurer)) : customCalculateChartData;
        }

        result = sortArrayOfObjectsByKey(result, "insurer");

        const { data, options } = generateChartDataLabelsOptions(result, formik.values?.growthType === "PI" ? "Surrender Values" : "Account Values");

        setBarChartData(data);
        setBarChartOption(options);
        setDisplayData(result);

    }, [formik?.values?.growthType, mongoPIData, customCalculateChartData, user]);

    // =======
    // comparison table
    // =======
    const generateComparison = useCallback(() => {

        const currencyWholeNumber = { style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: 'USD' }

        const formatTotalValue = (value) => value?.totalValue?.toLocaleString('en-US', currencyWholeNumber);
        const formatGrowthRate = () => `${formik.values?.growthType === "PI" ? "8" : (formik.values?.fundGrowthPA * 100).toFixed(0)}% p.a. ${(formik.values?.growthType === "Custom" && activeTabIndex === 1) ? `(With Inital Fall of ${Math.round((1 - formik?.values?.fundInitalDrop) * 100)}%)` : ""}`;
        const formatPremiumTerm = () => `${formik.values?.policyPremiumTerm} Yrs`
        const formatMonthlyInvestment = () => `${formik.values?.monthlyInvestmentAmount.toLocaleString('en-US', currencyWholeNumber)}/mth`;
        const formatInvestmentPeroid = () => `${formik.values?.investmentPeriod} Yrs`
        const formatPromo = (value) => value?.details?.customerPromo?.valid ? "Y" : "";
        const formatBreakEvenYield = value => value?.breakEvenYield ? `${value.breakEvenYield}% p.a. for ${formik.values?.policyPremiumTerm} yrs` : ""

        // function to expand all and collapse all expanders
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p.id}`] = true);
            setExpandedRows(_expandedRows);
        }

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        // function to display icon for select button
        const displayIcon = (option) => {
            return <i className={option.icon}></i>;
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                <span>
                    <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                    <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
                {
                    formik.values?.growthType === "Custom" && activeTabIndex === 0 && <span>
                        <SelectButton value={view} onChange={e => setView(() => e?.target?.value)} itemTemplate={displayIcon} optionLabel="value" options={viewOptions} className='inline' unselectable={false} />
                    </span>
                }
            </div>
        </>

        const rowExpansionTemplate = (data) => <>
            <DisplayILP101Details data={data} />
            {formik?.values?.growthType === "Custom" && <ILP101ChartAndExcel data={data} />}
        </>

        return <div className='col-12'>

            <div className='card'>
                <DisplayComparisonTitle type="Regular Monthly Investment Plan" />

                <Suspense fallback={<div>Loading Chart...</div>}>
                    <div className="card">
                        <Chart type="bar" data={barChartData} options={barChartOption} />
                    </div>
                </Suspense>

                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        // footer
                        // footer={<DataTableFooter type="101" query={query} />}
                        
                        // expander props
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"
                    >

                        <Column expander style={{ width: '3em' }} />
                        <Column field="insurer" header="Insurer" sortable></Column>
                        <Column field="planName" header="Plan" sortable></Column>

                        {view === "default" && <Column field="growthRate" header={`${formik.values?.growthType === "PI" ? "PI's Growth Rate" : "Growth (excl. Fund Mgt Charges)"}  `} sortabl body={formatGrowthRate}></Column>}
                        <Column field="monthlyInvestment" header="Monthly" body={formatMonthlyInvestment}></Column>
                        <Column field="monthlyInvestment" header="Premium Term" body={formatPremiumTerm}></Column>
                        {view === "default" && <Column field="investmentPeriod" header="Investment Period" body={formatInvestmentPeroid}></Column>}
                        {view === "default" && <Column field="totalValue" header="Projected Value" sortable body={formatTotalValue}></Column>}

                        {view === "kpis" && <Column field="breakEvenYield" header="Break Even Yield" body={formatBreakEvenYield} sortable></Column>}
                        {view === "default" && <Column field="promo" header="Promo" body={formatPromo} sortable></Column>}
                    </DataTable>
                </Suspense>
            </div>
        </div >
    }, [barChartData, barChartOption, displayData, expandedRows, filters2, view, formik.values?.growthType, formik.values?.fundGrowthPA, formik.values?.fundInitalDrop, formik.values?.policyPremiumTerm, formik.values?.monthlyInvestmentAmount, formik.values?.investmentPeriod, activeTabIndex, mobileScreen, globalFilterValue2, query]) // end of generateComparison (useCallback)

    // =======
    // surrender value and account value chart
    // =======
    const displayChartFromCustomFunction = async (values) => {

        const { monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, fundGrowthPA, fundInitalDrop } = values;

        // custom chart data
        // console.log("Active Tab", activeTabIndex);
        const createExcel = true;
        const chartData = (activeTabIndex === 0) ? createPIChartData(investmentPeriod, fundGrowthPA) : createCustomChartData(fundInitalDrop, investmentPeriod, fundGrowthPA);
        // console.log("chartData @ displayChartFromCustomFunction ", chartData[12]);

        const aia_ProAchiever3 = await aia_proAchiever3(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (aia_ProAchiever3.availablity) {
            const aia_ProAchiever3_BreakEvenYield = breakEven_aiaProAchiever3.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            aia_ProAchiever3.breakEvenYield = aia_ProAchiever3_BreakEvenYield[0].breakEvenYield;
            aia_ProAchiever3.id = aia_ProAchiever3.insurer + " - " + aia_ProAchiever3.planName;
        }
        // console.log("aia_ProAchiever3", aia_ProAchiever3.excelData);

        const eTiQa_InvestSmartFlex = await eTiqa_investSmartFlex(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (eTiQa_InvestSmartFlex.availablity) {
            const eTiQa_InvestSmartFlex_BreakEvenYield = breakEven_eTiQaInvestSmartFlex.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            eTiQa_InvestSmartFlex.breakEvenYield = eTiQa_InvestSmartFlex_BreakEvenYield[0].breakEvenYield;
            eTiQa_InvestSmartFlex.id = eTiQa_InvestSmartFlex.insurer + " - " + eTiQa_InvestSmartFlex.planName;
        }
        // console.log("eTiQa_InvestSmartFlex", eTiQa_InvestSmartFlex.excelData);

        const FWD_InvestFirstMax = await fwd_investFirstMax(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (FWD_InvestFirstMax.availablity) {
            const FWD_InvestFirstMax_BreakEvenYield = breakEven_fwdInvestFirstMax.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            FWD_InvestFirstMax.breakEvenYield = FWD_InvestFirstMax_BreakEvenYield[0].breakEvenYield;
            FWD_InvestFirstMax.id = FWD_InvestFirstMax.insurer + " - " + FWD_InvestFirstMax.planName;
        }
        // console.log("FWD_InvestFirstMax", FWD_InvestFirstMax.excelData);

        const FWD_InvestGoalX = await fwd_investGoalX(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (FWD_InvestGoalX.availablity) {
            const FWD_InvestGoalX_BreakEvenYield = breakEven_fwdInvestGoalX.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            FWD_InvestGoalX.breakEvenYield = FWD_InvestGoalX_BreakEvenYield[0].breakEvenYield;
            FWD_InvestGoalX.id = FWD_InvestGoalX.insurer + " - " + FWD_InvestGoalX.planName;
        }
        // console.log("FWD_InvestGoalX", FWD_InvestGoalX.excelData);

        const HSBC_WealthVoyage = await hsbc_wealthVoyage(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (HSBC_WealthVoyage.availablity) {
            const HSBC_WealthVoyage_BreakEvenYield = breakEven_hsbcWealthVoyage.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            HSBC_WealthVoyage.breakEvenYield = HSBC_WealthVoyage_BreakEvenYield[0].breakEvenYield;
            HSBC_WealthVoyage.id = HSBC_WealthVoyage.insurer + " - " + HSBC_WealthVoyage.planName;
        }
        // console.log("HSBC_WealthVoyage", HSBC_WealthVoyage.excelData);

        const Income_InvestFlex = await income_investFlex(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (Income_InvestFlex.availablity) {
            const Income_InvestFlex_BreakEvenYield = breakEven_incomeInvestFlex.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            Income_InvestFlex.breakEvenYield = Income_InvestFlex_BreakEvenYield[0].breakEvenYield;
            Income_InvestFlex.id = Income_InvestFlex.insurer + " - " + Income_InvestFlex.planName;
        }
        // console.log("Income_InvestFlex", Income_InvestFlex.excelData);

        // Singlife Savvy Invest II (Fixed)
        const Singlife_SavvyInvestII_Fixed = await singlife_savvyInvestII(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData, createExcel, "Fixed");
        if (Singlife_SavvyInvestII_Fixed.availablity) {
            const Singlife_SavvyInvestII_Fixed_BreakEvenYield = breakEven_singlifeSavvyInvest.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount && ele.type === "Fixed");
            Singlife_SavvyInvestII_Fixed.breakEvenYield = Singlife_SavvyInvestII_Fixed_BreakEvenYield[0].breakEvenYield;
            Singlife_SavvyInvestII_Fixed.id = Singlife_SavvyInvestII_Fixed.insurer + " - " + Singlife_SavvyInvestII_Fixed.planName;
        }

        // Singlife Savvy Invest II (Flexible)
        const Singlife_SavvyInvestII_Flexible = await singlife_savvyInvestII(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData, createExcel, "Flexible");
        if (Singlife_SavvyInvestII_Flexible.availablity) {
            const Singlife_SavvyInvestII_Flexible_BreakEvenYield = breakEven_singlifeSavvyInvest.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount && ele.type === "Flexible");
            Singlife_SavvyInvestII_Flexible.breakEvenYield = Singlife_SavvyInvestII_Flexible_BreakEvenYield[0].breakEvenYield;
            Singlife_SavvyInvestII_Flexible.id = Singlife_SavvyInvestII_Flexible.insurer + " - " + Singlife_SavvyInvestII_Flexible.planName;
        }

        const TM_GoClassic = await tm_goClassic(monthlyInvestmentAmount, policyPremiumTerm, investmentPeriod, chartData);
        if (TM_GoClassic.availablity) {
            const TM_GoClassic_BreakEvenYield = breakEven_tmgoClassic.filter(ele => ele?.premiumPaymentTerm === formik?.values?.policyPremiumTerm && ele?.monthlyPremium === formik?.values?.monthlyInvestmentAmount)
            TM_GoClassic.breakEvenYield = TM_GoClassic_BreakEvenYield[0].breakEvenYield;
            TM_GoClassic.id = TM_GoClassic.insurer + " - " + TM_GoClassic.planName;
        }
        // console.log("TM_GoClassic", TM_GoClassic.excelData);

        let result = [aia_ProAchiever3, eTiQa_InvestSmartFlex, FWD_InvestFirstMax, FWD_InvestGoalX, HSBC_WealthVoyage, Income_InvestFlex, Singlife_SavvyInvestII_Fixed, Singlife_SavvyInvestII_Flexible, TM_GoClassic,].filter(plan => plan.availablity === true); // filter out plans that are not available
        // console.log("result", result)

        // check if there is existing plan details available in mongoPlanDetails
        if (mongoPlanDetails.length > 0) {
            result = generateCombinedDisplayData(result, mongoPlanDetails)
            setCustomCalculateChartData(result);
            return result;
        }

        // if there is no existing plan details available in mongoPlanDetails, get the plan details from the mongoDB
        if (mongoPIData.length === 0 || values?.monthlyInvestmentAmount !== query?.monthlyInvestmentAmount || values?.policyPremiumTerm !== query?.policyPremiumTerm) {
            setLoading(true);

            try {
                const res = await axiosInstance.get('dashboard/comparison/ilp101PlanDetails');
                if (res?.data) {
                    const planDetails = res.data?.result.length > 0 ? res.data?.result : []; // plan details
                    // console.log("planDetails", planDetails.length);
                    // planDetails.forEach(ele => console.log(ele));

                    result = generateCombinedDisplayData(result, planDetails);
                    setMongoPlanDetails(planDetails); // store the plan details in state for re-use if there is no existing data

                    setLoading(false);
                    setCustomCalculateChartData(result);
                    return result;
                }
            } catch (error) {
                console.log("Error getting data from mongoDB");
                setLoading(false);
            } // end of try catch
        } // end if 
    }

    const displayChartFromMongoPI = async (values) => {

        // console.log("At displayChartFromMongoPI");
        // console.table(values);
        // console.table(query);

        let result = [], piData = [], planDetails = [];

        // only download data from mongoDB if there is no existing data, monthly investment amount or premium term / minimum investment period has changed
        if (mongoPIData.length === 0 || values?.monthlyInvestmentAmount !== query?.monthlyInvestmentAmount || values?.policyPremiumTerm !== query?.policyPremiumTerm) {
            setLoading(true);
            try {
                const { monthlyInvestmentAmount, policyPremiumTerm } = values;
                // console.log(`Getting data from mongoDB for $${monthlyInvestmentAmount} with a term of ${policyPremiumTerm} for ANB ${user?.comparisonInsured?.ANB}`)

                const res = await axiosInstance.post('dashboard/comparison/ilp101', { monthlyInvestmentAmount, policyPremiumTerm, /*anb: user?.comparisonInsured?.ANB*/ });
                if (res?.data) {
                    piData = res.data?.result[0].length > 0 ? res.data?.result[0] : []; // ilp101 data
                    planDetails = res.data?.result[1].length > 0 ? res.data?.result[1] : []; // plan details

                    // console.log("piData", piData.length);
                    // piData.forEach(ele => console.log(ele));
                    // console.log("planDetails", planDetails.length);
                    // planDetails.forEach(ele => console.log(ele));

                    result = processMongoPIData(piData, values?.investmentPeriod);
                    // console.log("result @ displayChartFromMongoPI", result);

                    setMongoPIData(generateCombinedDisplayData(result, planDetails)); // store the data in state for re-use
                    mongoPIData.length === 0 && setMongoPlanDetails(planDetails); // store the plan details in state for re-use if there is no existing data

                    setLoading(false);
                    // return result;
                    return result;
                }
            } catch (error) {
                console.log("Error getting data from mongoDB");
                setLoading(false);
            } // end of try catch
        } // end if 

        // if there is existing data downloaded from mongoDB
        return processMongoPIData(mongoPIData, values?.investmentPeriod);
    }

    // return the surrender values of the plans that have the investment period (sort by insurer)
    const processMongoPIData = (piData, investmentPeriod) => {

        // console.log("At processMongoPIData");
        // console.log("Investment Period", investmentPeriod);
        // console.table(piData);

        const result = piData.filter(obj => obj.values && Array.isArray(obj.values) && obj.values.some(value => value.endOfYear === investmentPeriod)); // filter out plans that do not have the investment period in PI data

        result.forEach(obj => { // add id and totalValue to each object for display table
            obj.availablity = true;
            obj.id = obj.insurer + " - " + obj.planName;
            obj.totalValue = obj.values.find(value => value.endOfYear === investmentPeriod).surrenderValueAt8pc;
        });

        return sortArrayOfObjectsByKey(result, "insurer"); // sort the array by insurer and return the result
    }

    const handleSubmit = async (values) => {

        //* this extra step is to set the initial drop to 0 if the custom growth type is PI without inital drop
        let inputs = { ...values };
        if (activeTabIndex === 0) {
            inputs.fundInitalDrop = 0;
        }
        // console.table(inputs);

        setQuery(inputs);
        setReadyToSearch(false);
        setShowEditButton(true);

        let result = (values?.growthType === "PI") ? await displayChartFromMongoPI(values) : await displayChartFromCustomFunction(values);
        // console.log("result @ handleSubmit:", result);

        // filter result with the insurer based on the list of insurer allowed. If the list is empty, then all insurer is allowed
        const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_101)?.onlyDisplayInsurers;
        // console.log("allowedInsurers", allowedInsurers);

        result = (allowedInsurers && allowedInsurers.length > 0)
            ? result.filter(ele => allowedInsurers.includes(ele.insurer))
            : result;

        const { data, options } = generateChartDataLabelsOptions(result, formik.values?.growthType === "PI" ? "Surrender Values" : "Account Values");
        setBarChartData(data);
        setBarChartOption(options);

        setDisplayData(result);
        scrollToTop();
    }

    const handleEdit = () => {
        setView('default');
        setReadyToSearch(true);
        setShowEditButton(false);
        setMongoPIData([]);
        setCustomCalculateChartData([]);
        setDisplayData([]);
    }

    const handleReset = () => {
        setView('default');
        setReadyToSearch(true);
        setShowEditButton(false);
        setMongoPIData([]);
        setCustomCalculateChartData([]);
        setDisplayData([]);
        formik.resetForm();
    }

    // =======
    // Fund Chart/Excel
    // =======

    const generateFundPrices = useCallback((values, tabIndex) => {

        const { investmentPeriod, fundGrowthPA, fundInitalDrop } = values;
        // console.log("Active Tab Index: ", tabIndex);
        // console.log(`Investment Period: ${investmentPeriod} | Fund Growth: ${fundGrowthPA} | Fund Inital Drop: ${fundInitalDrop}`);

        if (tabIndex === 0) {
            return createPIChartData(investmentPeriod, fundGrowthPA); // PI's growth
        } else if (tabIndex === 1) {
            return createCustomChartData(fundInitalDrop, investmentPeriod, fundGrowthPA); // Custom growth
        } else {
            alert("Invalid tab index");
            return [];
        }
    }, [])

    const generateFundChart = useCallback((values, tabIndex) => {

        const { investmentPeriod, fundGrowthPA, fundInitalDrop } = values;
        const fundChartData = generateFundPrices(values, tabIndex);
        const fundChartType = tabIndex === 0 ? "PI's Growth" : "Custom Growth";

        const combinedData = { investmentPeriod, fundGrowthPA, fundInitalDrop, fundChartData, fundChartType };
        // console.log("Combined Data", combinedData);

        setFundChartData(combinedData);
        setDisplayFundChart(true);
    }, [generateFundPrices]) // end of generateFundChart

    const createExcelFile = (values, tabIndex) => {

        const { investmentPeriod, fundGrowthPA, fundInitalDrop } = values;

        const excelFileName = `${tabIndex === 0 ? "PI" : "Custom"} Fund Price Chart (${investmentPeriod} Years @ ${Math.floor(fundGrowthPA * 100)}% p.a Growth`
            + ((tabIndex === 1) ? ` with ${Math.floor((1 - fundInitalDrop) * 100)}% Initial Drop)` : "")

        // header row in excel
        const headerRow = [
            { value: 'End of Month', fontWeight: 'bold', width: 30, align: 'center' },
            { value: 'Date', fontWeight: 'bold', width: 30, align: 'center' },
            { value: 'Fund Price', fontWeight: 'bold', width: 30, align: 'center' },
        ];

        const rowData = [headerRow]; // row data for excel and setting the first row as header
        const fundPrice = generateFundPrices(values, tabIndex); // gettings fund prices

        const currentMonth19th = new Date();
        currentMonth19th.setDate(19); // setting the start date to 19th of the current month
        for (let i = 0; i < fundPrice.length; i++) {
            rowData.push([
                { type: String, value: `${i}`, align: 'center' }, // setting the month at 1st column (column A)
                { type: Date, value: date.addMonths(currentMonth19th, i), format: 'dd/mm/yyyy', align: 'center' }, // setting the date sat 1st column (column A)
                { type: Number, value: fundPrice[i], align: 'center', format: '#,##0.0000' }, // setting the fund price at 2nd column (column B)
            ]);
        }

        const columns = [{ width: 28 }, { width: 28 }, { width: 28 }]; // setting the column width
        (async () => {
            try {
                await writeXlsxFile(rowData, { columns, fileName: `${excelFileName}.xlsx` }) // creating the excel file
                // console.log('Excel file successfully created!');
            } catch (error) {
                console.error('Error creating Excel file:', error);
            }
        })();
    }

    // console.table(formik.values);

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }
            <div className="col-12 md:col-6">
                <div className='card'>

                    <h5>Select monthly investment amount</h5>
                    <Dropdown value={formik.values.monthlyInvestmentAmount} options={monthlyInvestmentAmount} onChange={e => formik.setFieldValue("monthlyInvestmentAmount", e.value)} optionLabel="label" optionValue="monthlyInvestmentAmount" className='w-full' disabled={!readyToSearch} />

                    <h5>Select premium term / minimum investment period</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.policyPremiumTerm}</p>}
                    <Dropdown value={formik.values.policyPremiumTerm} options={policyPremiumTerm} onChange={e => formik.setFieldValue("policyPremiumTerm", e.value)} optionLabel="label" optionValue="policyPremiumTerm" className='w-full' disabled={!readyToSearch} />

                    <h5>Select investment period (Chart)</h5>
                    {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.investmentPeriod}</p>}
                    <Dropdown value={formik.values.investmentPeriod} options={investmentPeriod} onChange={e => formik.setFieldValue("investmentPeriod", e.value)} optionLabel="label" optionValue="investmentPeriod" className='w-full' disabled={!readyToSearch} />

                    <h5>Display</h5>
                    <Dropdown value={formik.values.growthType} options={growthType} onChange={e => formik.setFieldValue("growthType", e.value)} optionLabel="label" optionValue="growthType" className='w-full' disabled={!readyToSearch} />

                    {
                        formik.values.growthType === "Custom" && <div className='m-5'>
                            <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                                <TabPanel header="By standard PI's growth" disabled={!readyToSearch}>
                                    <div>
                                        <p>Select PI's average growth per annum</p>
                                        <Dropdown value={formik.values.fundGrowthPA} options={fundGrowthPA} onChange={e => formik.setFieldValue("fundGrowthPA", e.value)} optionLabel="label" optionValue="fundGrowthPA" className='w-full' disabled={!readyToSearch} />
                                    </div>
                                </TabPanel>
                                <TabPanel header="By customed fund growth" disabled={!readyToSearch}>
                                    <div className='mb-3'>
                                        <p>Select customed chart average growth per annum</p>
                                        <Dropdown value={formik.values.fundGrowthPA} options={fundGrowthPA} onChange={e => formik.setFieldValue("fundGrowthPA", e.value)} optionLabel="label" optionValue="fundGrowthPA" className='w-full' disabled={!readyToSearch} />
                                    </div>
                                    <div>
                                        <p>Select inital fall</p>
                                        <Dropdown value={formik.values.fundInitalDrop} options={fundInitalDrop} onChange={e => formik.setFieldValue("fundInitalDrop", e.value)} optionLabel="label" optionValue="fundInitalDrop" className='w-full' disabled={!readyToSearch} />
                                    </div>
                                </TabPanel>
                            </TabView>
                            <div className="flex flex-row-reverse">
                                <Button type="button" label="Chart" icon="pi pi-chart-line" aria-label="Chart" className='p-button m-1' onClick={() => generateFundChart(formik.values, activeTabIndex)} disabled={!readyToSearch} />
                                <Button type="button" label="Excel" icon="pi pi-file-excel" aria-label="Excel" className='p-button-outlined m-1' onClick={() => createExcelFile(formik.values, activeTabIndex)} disabled={!readyToSearch} />
                                {
                                    // <Button type="button" label="Excel" icon="pi pi-file-excel" aria-label="Excel" className='p-button-outlined m-1' onClick={createExcelFile} disabled />
                                }
                            </div>

                        </div>
                    }

                    <div className='mt-6'>
                        <hr></hr>
                        <div className='grid mt-3'>
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                            </div>
                            {
                                showEditButton && <div className='col-12 md:col-4'>
                                    <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                                </div>
                            }
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="submit" label={formik?.values?.growthType === "PI" ? "Search" : "Simulate"} aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6">
                <DisplayInsuredDetails />
                {activeTabIndex === 0 && <Display101ExtraInfo growthType={formik?.values?.growthType} />}
                <FormInsurerDisplayedOption comparisonType={userContext_101} insurerList={insurer_101} />
            </div>
            <div>
                <Dialog header="Fund Price Chart" visible={displayFundChart} style={{ width: '80vw' }} onHide={() => setDisplayFundChart(false)}>
                    <div className="m-0">
                        <Card className="m-0">
                            <FundPriceChart sourceData={fundChartData} />
                        </Card>
                    </div>
                </Dialog>
            </div>
            {loading && <LoadingScreen />}
        </div>
    )
}

export default Compare101RP