import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useFitText from "use-fit-text";

// covert html to react without using dangerously html
import parse from "html-react-parser";

// custom hook
import useScreenSize from 'hooks/useScreenSize';

// user context
import { UserContext } from 'contexts/userContext';

import './ContactCardToPrint.css'

// component
const ContactCardToPrint = (props) => {

    // props
    const { title, subTitle, htmlContent, profileURL, backgroundURL, design, cardStandardLength } = props;

    // user context
    const user = useContext(UserContext);

    // states
    const [cardSize, setCardSize] = useState({ width: 414, height: 736 }) // base on iphone 6+ 7+ & 8+ 621
    const [emailWidth, setEmailWidth] = useState(300)

    // screen size
    const screenSize = useScreenSize();

    // settin the width of cvard using screen size
    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        if (screenSize.width >= 1000) {
            cardStandardLength ? setCardSize({ width: 414, height: 621 }) : setCardSize({ width: 414, height: 736 })// about 18% of longer
            setEmailWidth(300)
        } else if (screenSize.width < 1000 && screenSize.width > 414) {
            cardStandardLength ? setCardSize({ width: 360, height: 540 }) : setCardSize({ width: 360, height: 640 }) // about 18% of longer
            setEmailWidth(220)
        } else {
            cardStandardLength ? setCardSize({ width: 297, height: 450 }) : setCardSize({ width: 297, height: 528 }) // about 17% of longer
            setEmailWidth(215)
        }
    }, [screenSize.width, cardStandardLength])

    // use Fit text to fit the email
    const { fontSize, ref } = useFitText();

    return (
        <div className='col-12 flex align-items-center justify-content-center'>
            <div id='contact-card' style={{
                width: `${cardSize.width}px`,   // "414px" 
                height: `${cardSize.height}px`, // "736px",

                backgroundColor: '#FBFBFB',
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '0',
                borderRadius: '5px',
            }}>
                {
                    (design === 1 || design === 2) && <img id='header01' style={{ objectFit: 'contain', borderRadius: '5px 5px 0 0' }} src={backgroundURL} alt="background" />
                }
                {
                    (design === 2) && <div className='flex justify-content-center'>
                        <img id='profile02'
                            src={profileURL}
                            alt="profic"
                            style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '60px',
                                objectFit: 'contain',
                                marginTop: '-60px'
                            }} />
                    </div>
                }

                <div className="cardHtmlContent" style={{
                    flexGrow: "1",
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    hyphens: 'auto',
                    padding: '1vw',
                    overflow: 'hidden',
                }}>

                    <div className="ql-container">
                        {title !== "" && <h1>{title}</h1>}
                        {subTitle !== "" && <h5>{subTitle}</h5>}
                        {parse(htmlContent)}
                    </div>

                </div>

                {
                    (design === 1 || design === 3) && <div id='footer03' className='grid m-0'>
                        <div className='col-3 flex align-items-center justify-content-center' >
                            <img id='profile02'
                                src={profileURL}
                                alt="profic2"
                                style={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '8px',
                                    objectFit: 'contain',

                                }} />
                        </div>
                        <div className='col-9 flex align-items-center justify-content-left px-0' >
                            <div >
                                <div className='footer-text'>MAS RNF: {user?.userDetails?.rnf} </div>
                                <div className='footer-text'>Mobile:  {user?.userDetails?.mobile}</div>
                                <div className='footer-text' ref={ref} style={{ fontSize, height: 17, width: emailWidth }}>Email: {user?.userDetails?.email} </div>
                            </div>
                        </div>

                    </div>
                }
                {
                    (design === 2) && <div id='footer01' className='grid m-0'>
                        <div className='col-12 flex align-items-center justify-content-center pt-0'>
                            <div>
                                <div className='text-center footer-text' >Email: {user?.userDetails?.email} </div>
                                <div className='text-center footer-text' >RNF: {user?.userDetails?.rnf} | Mobile: {user?.userDetails?.mobile}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>


    )
}

export default ContactCardToPrint

ContactCardToPrint.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    htmlContent: PropTypes.string,
    profileURL: PropTypes.string,
    backgroundURL: PropTypes.string,
    design: PropTypes.number
}

ContactCardToPrint.defaultProps = {
    title: "",
    subTitle: "",
    htmlContent: "",
    profileURL: "",
    backgroundURL: "",
    design: 1
};