import React, { useState } from 'react'
import Finance from 'tvm-financejs'; // tvm-financejs -> https://github.com/kgkars/tvm-financejs
import { useFormik } from 'formik';
import * as yup from 'yup'

// import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

// custom function divide by 100 to avoid js float error/precision
import divideBy100 from 'helpers/divideBy100';
import formatDollar from 'helpers/formatDollar'
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field

// custom useLocalStorge hook
import useLocalStorage from 'hooks/useLocalStorage';

// generate initial Value based on prop => pv, fv, rate
const defaultValues  = (type) => {
    const value = {
        pv: 0,
        pmt: 0,
        n: 0,
        rate: 0,
        fv: 0,
    }
    delete value[type.toLowerCase()];
    return value;
}

// generate yup schema based on prop => pv, fv, rate
const getYupObject = (type) => {
    const obj = {
        pv: yup.number()
            .required('Please enter the future value (PV)')
            .typeError('A number is required'), 
        fv: yup.number()
            .required('Please enter the future value (FV)')
            .typeError('A number is required'),             
        rate: yup.number()
            .required('Please enter the interest per annum (RATE)')
            .min(-100, 'Interest must not be lower than negative 99%')
            .typeError('A number is required'), 
        n: yup.number()
            .required('Please enter the number of years (N)')
            .positive('Year must be great than 0')
            .typeError('A number is required'), 
        pmt: yup.number()
            .required('Please enter annum payment (PMT)')
            .typeError('A number is required'), 
      }
      delete obj[type.toLowerCase()];
      return yup.object(obj);
}

const FormPvFvPmtRate = (props) => {

    // destructuring the prop
    const {type} = props;

    // custom localStorage hook
    const [fromInputs, saveFromInputs] = useLocalStorage(`calculator-${type}`, defaultValues(type));

    // states
    const [calculated, setCalculated] = useState(false)
    const [answer, setAnswer] = useState();
    const [excelFormula, setExcelForumla] = useState();
    const [copyAnswerButtonText, setAnswerButtonText] = useState("Copy")
    const [copyExcelButtonText, setExcelButtonText] = useState("Copy")

    // formik instance    
    const formik = useFormik({
        enableReinitialize: true, // allow reset form by resetting the initialValues
        initialValues: fromInputs,
        validationSchema: getYupObject(type),
        onSubmit: (values) => handleSubmit(values)
    })

    // submit function to calculate 
    const handleSubmit = async (values) => {
        
        const { pv="", pmt="", n="", rate="", fv=""} = values;
        // console.table(values);
        // console.log(`pv : ${pv}, pmt : ${pmt}, n : ${n}, rate: ${rate}, fv : ${fv} `);
        // console.log(type.toLowerCase());
        const finance = new Finance();
        switch (type.toLowerCase()) {
            case "pv":
                setAnswer(await finance.PV(divideBy100(rate), n, pmt, fv, 1));
                setExcelForumla(`=PV(${divideBy100(rate)},${n},${pmt},${fv},1)`);
                break;
            case "fv":
                setAnswer(await finance.FV(divideBy100(rate), n, pmt, pv, 1));                
                setExcelForumla(`=FV(${divideBy100(rate)},${n},${pmt},${pv},1)`);
                break;
            case "pmt":
                setAnswer(await finance.PMT(divideBy100(rate), n, pv, fv, 1));
                setExcelForumla(`=PMT(${divideBy100(rate)},${n},${pv},${fv},1)`);
                break;                  
            case "rate":
                const temp = await finance.RATE(n, pmt, pv, fv, 1);
                setAnswer(temp * 100);
                setExcelForumla(`=RATE(${n},${pmt},${pv},${fv},1)`)
                break;                  
            default:
                break;
        }
        setCalculated(true);
        saveFromInputs(values);
    }

    const handleClearForm = () => {
        if (calculated) {
            // Edit Button
            setCalculated(false);
            setAnswer();
            setExcelForumla();
        }
        else {
            // Clear Button
            saveFromInputs(defaultValues(type)); // reset the form by resetting the default values 
        }          
    }
    // handleCopy button to copy the excel formula to clipboard
    const handleCopyButton = async (type) => {

        if (type.toLowerCase() === "answer") {
            setAnswerButtonText("Copied!");
            setExcelButtonText("Copy!");
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(answer);
              } else {
                document.execCommand('copy', true, answer);
            }
            setTimeout(() => setAnswerButtonText("Copy"), 5000);
        } else if (type.toLowerCase() === "excel") {
            setExcelButtonText("Copied!");
            setAnswerButtonText("Copy");
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(excelFormula);
              } else {
                document.execCommand('copy', true, excelFormula);
            }
            setTimeout(() => setExcelButtonText("Copy"), 5000)
        }
    }

  return (
    <div className="grid">
        <div className="col-12 md:col-6">     
            <div className="field card">

                {type.toLowerCase() !== "pv" && <>
                    { displayFormikLabel("pv", "Present Value (PV)", formik.touched, formik.errors) }
                    <InputNumber inputId="pv" value={formik.values.pv} onValueChange={(e) => formik.setFieldValue("pv", e.value)}  mode="decimal" minFractionDigits={0} maxFractionDigits={2} className="w-full mb-3" disabled={calculated}/>              
                             
                </>}
                
                {type.toLowerCase() !== "pmt" && <>
                    { displayFormikLabel("pmt", "Payment per annum (PMT)", formik.touched, formik.errors) }                     
                    <InputNumber inputId="pmt" value={formik.values.pmt} onValueChange={(e) => formik.setFieldValue("pmt", e.value)}  mode="decimal" minFractionDigits={0} maxFractionDigits={2} className="w-full mb-3" disabled={calculated}/>              
                </>}

                { displayFormikLabel("n", "Number of years (N)", formik.touched, formik.errors) }  
                <InputNumber inputId="n" value={formik.values.n} onValueChange={(e) => formik.setFieldValue("n", e.value)} min={0} mode="decimal" minFractionDigits={0} maxFractionDigits={2} className="w-full mb-3" disabled={calculated}/>              

                {type.toLowerCase() !== "rate" && <>
                    { displayFormikLabel("rate", "Rate (%) | E.g. Key in 3.8 for 3.8% p.a", formik.touched, formik.errors) }             
                    <InputNumber inputId="rate" value={formik.values.rate} onValueChange={(e) => formik.setFieldValue("rate", e.value)}  mode="decimal" minFractionDigits={0} maxFractionDigits={2} className="w-full mb-3" disabled={calculated}/>              
                </>}

                {type.toLowerCase() !== "fv" && <>
                    { displayFormikLabel("fv", "Future Value (FV)", formik.touched, formik.errors) }  
                    <InputNumber inputId="fv" value={formik.values.fv} onValueChange={(e) => formik.setFieldValue("fv", e.value)}  mode="decimal" minFractionDigits={0} maxFractionDigits={2} className="w-full mb-3" disabled={calculated}/>              
                </>}
                <p></p>
                <div className='grid'>
                    <div className="col-12 md:col-6">
                        <Button label={calculated ? "Edit" : "Clear"} aria-label="Clear" className="p-button-outlined w-full" onClick={handleClearForm}/>
                    </div>
                    <div className="col-12 md:col-6">
                        <Button label={`Calculate ${type.toUpperCase()}`} aria-label="Submit" className="w-full" onClick={formik.handleSubmit}/>
                    </div>
                </div>   
            </div>

        </div>
        <div className="col-12 md:col-6">
            {
                calculated 
                    ? <>
                        <div className="card">
                            <h5>Answer:</h5>
                            {
                                isNaN(answer) 
                                    ? <h4>Error! Invalid inputs or try smaller values</h4>                                       
                                    : <>
                                        <h4 className='text-primary'>The {type.toUpperCase()} is {formatDollar(answer, 2)}{type.toLowerCase() === "rate" && "%"}</h4>                                    
                                        <div className='flex flex-row-reverse'>
                                            <Button label={copyAnswerButtonText} className="p-button-raised" onClick={() => handleCopyButton("answer")}/>
                                        </div>
                                    </> 
                                    
                            }
                        </div>
                        <div className="card">
                            <h5>MS Excel Formula:</h5>
                            <h4 className='text-primary'>{excelFormula}</h4>
                            <div className='flex flex-row-reverse'>
                                <Button label={copyExcelButtonText} className="p-button-raised" onClick={() => handleCopyButton("excel")}/>
                            </div>
                        </div>
                    </>
                    : <>
                        <div className="card">
                            <h5>Use case(s):</h5>
                            <ol>
                            {
                                useCases[type.toLowerCase()] && useCases[type.toLowerCase()].map((useCase, index )=> <li key={index}>{useCase}</li>)
                            }
                            </ol>
                        </div>
                    </>
            }       
        </div>
    </div>
  )
}

export default FormPvFvPmtRate

// use cases to be displayed
const useCases = {
    pv: [
        <>
            <h6>Today's value of money</h6>
            <p>After 20 years (N) of inflation @ 2.5% (i) per annum, a future cash amount of $100,000 (FV) would have the same purchasing power of $61,027.09 (negative PV) today! (Note: PMT = 0)</p>
            <p></p>
        </>,
        <>
            <h6>Initial investment needed</h6>
            <p>A person wishes to have a lump sum of $1,000,000 (FV) at the end of 17 (N) years. He is interested in an investment that grows at 5.5% (i) per annum. </p>
            <p>He will invest a lump sum without regular investment (PMT = 0). Hence, he should invest $402,446.53 (negative PV) today to get the desired amount 17 years later.</p>
            <p></p>
        </>,
        <>
            <h6>Initial investment needed with annual top ups</h6>
            <p>A person wishes to have a lump sum of $1,000,000 (FV) at the end of 17 (N) years. He is interested in an investment that grows at 5.5% (i) per annum.</p>
            <p>To lower the initial investment amount, he tops up $12,000 (negative PMT) annually at the beginning of the investment period. </p>
            <p>Hence, he should invest $264,900.58 (negative PV) today to get the desired amount 17 years later.</p>
            <p></p>
        </>,
        <>
            <h6>Initial investment needed with regular withdrawals</h6>
            <p>A person wishes to invest in an investment that grows at 5.5% (i) per annum to fund his retirement of 17 (N) years. </p>
            <p>He will withdraw $60,000 (PMT) per annum for his retirement. At the end of 17 years, the investment value will be $0 (PV). </p>
            <p>Hence, the initial investment amount needed for this investment is $687,729.72 (negative PV)</p>
            <p></p>
        </>
    ],
    fv: [
        <>
            <h6>Future value of an investment</h6>
            <p>Suppose a person invests $1,000 (negative PV) in a savings account today at a 1.5% (i) annual interest rate. (Note: PMT = 0)</p>
            <p>At the end of 2 (N) years, it will be worth $1,030.22 (FV)</p>
            <p></p>
        </>,
        <>
            <h6>Future value of an investment (with annual top-up)</h6>
            <p>Suppose a person invests a lump sum of $10,000 (negative PV) with an instrument with a 5% (i) return per annum for 10 (N) years. 
                In addition to the lump sum, he makes an annual investment top-up of $3,000 (negative PMT) at the beginning of every year.</p>
            <p>At the end of the 10th year, his investment will be worth $55,909.31 (FV)</p>
            <p></p>
        </>,
        <>
            <h6>Future value of an investment (with annual withdrawal)</h6>
            <p>Suppose a person invests a lump sum of $10,000 (negative PV) with an instrument with a 6% (i) return per annum for 15 (N) years. He withdrawal $500 annually at the beginning of every year. </p>
            <p>At the end of the 10th year, his investment will be worth $11,629.32 (FV)</p>
            <p></p>
        </>
    ],
    pmt : [
        <>
            <h6>Annual investment needed</h6>
            <p>A person aims to achieve $500,000 (FV) at the end of 18 (N) years. He invests in an instrument that grows 3.5% (i) per annum for 18 years without an initial lump sum (PV is zero).</p>
            <p>Hence he needs to invest $19,718.28 (negative PMT) annually at the beginning of every investment year to achieve his goal.</p>
            <p></p>
        </>,
        <>
            <h6>Annual investment needed with an initial investment</h6>
            <p>A person is investing an initial lump sum of $100,000 (negative PV) in an instrument that grows 3.5% (i) per annum. He wishes to achieve $500,000 (FV) at the end of 18 (N) years. </p>
            <p>Hence he needs to do an annual investment top-up of $12,392.98 (negative PMT) at the beginning of every investment year.</p>
            <p></p>
        </>,
        <>
            <h6>Annual withdrawal</h6>
            <p>A person is investing an initial lump sum of $100,000 (negative PV) in an instrument that grows 3.5% (i) per annum. </p>
            <p>He wishes to achieve a 20% gain (FV = $120,000) at the end of 18 (N) years while withdrawing annually to fund his hobby. </p>
            <p>Hence, he can withdraw $2,592.91 per annum (PMT) at the beginning of every investment year while achieving his goal.</p>
        </>
    ],
    rate : [
        <>
            <h6>A financial instrument with an annual return</h6>
            <p>A person would like to grow his savings from $10,000 (negative PV) to 12,000 (FV) in 4 (N) years.</p>
            <p>He will not be making any annual investment (PMT = 0).</p>
            <p>Hence, he needs to find an instrument that gives him a return of 4.66% (RATE) p.a to achieve his goal.</p>
        </>
    ]
}
