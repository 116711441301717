import React from 'react';
import { useLocation } from 'react-router-dom';

const DashboardBreadcrumb = (props) => {
    const location = useLocation();
    const label = props?.meta?.label;

    return label 
        ? <>{location.pathname === '/' ? <span>Dashboard</span> : <span>{label}</span>}</> 
        : <span>No page found</span>

};

export default DashboardBreadcrumb;
