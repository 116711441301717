/**
 * Axios instance for making API requests.
 * @module axios
 * @see {@link https://github.com/axios/axios |axios}
 */

import axios from "axios";
import { backendUrl } from "settings";

// old code when use .env file
// const url = process.env.REACT_APP_BACKEND_URL ?? "https://app-backend.techvisor.io/api";

const url = `${backendUrl}/api`;
// console.log("url @ axios.js", url);
// const url = "https://app-backend.techvisor.io/api";

/**
 * Axios instance for making authenticated API requests.
 * @type {import("axios").AxiosInstance}
 * @see {@link https://github.com/axios/axios|axios}
 */
export const axiosAuth = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true // code to passed in http Only cookie
});

export default axios.create({
    baseURL: url
});

