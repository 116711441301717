const breakEven_tmgoClassic = [
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 750,
        "breakEvenYield": 4.04,
        "totalValue": 45000.06602033043
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1000,
        "breakEvenYield": 3.59,
        "totalValue": 60006.856476190114
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1500,
        "breakEvenYield": 3.59,
        "totalValue": 90010.28471428511
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 2000,
        "breakEvenYield": 3.37,
        "totalValue": 120024.68150764944
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.93,
        "totalValue": 180040.50115148918
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.49,
        "totalValue": 240007.25037860527
    },
    {
        "premiumPaymentTerm": 6,
        "monthlyPremium": 750,
        "breakEvenYield": 3.6,
        "totalValue": 54013.15560622435
    },
    {
        "premiumPaymentTerm": 6,
        "monthlyPremium": 1000,
        "breakEvenYield": 3.29,
        "totalValue": 72002.6469906655
    },
    {
        "premiumPaymentTerm": 6,
        "monthlyPremium": 1500,
        "breakEvenYield": 3.29,
        "totalValue": 108003.97048599827
    },
    {
        "premiumPaymentTerm": 6,
        "monthlyPremium": 2000,
        "breakEvenYield": 3.14,
        "totalValue": 144002.47364316485
    },
    {
        "premiumPaymentTerm": 6,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.85,
        "totalValue": 216032.61098790815
    },
    {
        "premiumPaymentTerm": 6,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.56,
        "totalValue": 288040.031368059
    },
    {
        "premiumPaymentTerm": 7,
        "monthlyPremium": 750,
        "breakEvenYield": 3.22,
        "totalValue": 63008.22673889118
    },
    {
        "premiumPaymentTerm": 7,
        "monthlyPremium": 1000,
        "breakEvenYield": 3.01,
        "totalValue": 84015.93192163926
    },
    {
        "premiumPaymentTerm": 7,
        "monthlyPremium": 1500,
        "breakEvenYield": 3.01,
        "totalValue": 126023.89788245883
    },
    {
        "premiumPaymentTerm": 7,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.9,
        "totalValue": 168002.25696764214
    },
    {
        "premiumPaymentTerm": 7,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.7,
        "totalValue": 252061.6722434927
    },
    {
        "premiumPaymentTerm": 7,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.49,
        "totalValue": 336013.6868578543
    },
    {
        "premiumPaymentTerm": 8,
        "monthlyPremium": 750,
        "breakEvenYield": 2.91,
        "totalValue": 72014.04664509703
    },
    {
        "premiumPaymentTerm": 8,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.76,
        "totalValue": 96032.26363780211
    },
    {
        "premiumPaymentTerm": 8,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.76,
        "totalValue": 144048.39545670318
    },
    {
        "premiumPaymentTerm": 8,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.68,
        "totalValue": 192036.3453139758
    },
    {
        "premiumPaymentTerm": 8,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.53,
        "totalValue": 288062.3971155083
    },
    {
        "premiumPaymentTerm": 8,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.38,
        "totalValue": 384065.3396868626
    },
    {
        "premiumPaymentTerm": 9,
        "monthlyPremium": 750,
        "breakEvenYield": 2.65,
        "totalValue": 81014.5616452472
    },
    {
        "premiumPaymentTerm": 9,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.54,
        "totalValue": 108041.4052224958
    },
    {
        "premiumPaymentTerm": 9,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.54,
        "totalValue": 162062.1078337437
    },
    {
        "premiumPaymentTerm": 9,
        "monthlyPremium": 2000,
        "breakEvenYield": 2.48,
        "totalValue": 216054.5567555264
    },
    {
        "premiumPaymentTerm": 9,
        "monthlyPremium": 3000,
        "breakEvenYield": 2.37,
        "totalValue": 324121.2744811805
    },
    {
        "premiumPaymentTerm": 9,
        "monthlyPremium": 4000,
        "breakEvenYield": 2.25,
        "totalValue": 432006.85696080595
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 2.3,
        "totalValue": 90011.83447329336
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.13,
        "totalValue": 120026.81611449693
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.13,
        "totalValue": 180040.2241717454
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 2,
        "totalValue": 240016.98760191357
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.8,
        "totalValue": 360151.36278122163
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.67,
        "totalValue": 480019.7585714316
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 750,
        "breakEvenYield": 2.15,
        "totalValue": 99039.23109982042
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 1000,
        "breakEvenYield": 2.01,
        "totalValue": 132012.04941242322
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 1500,
        "breakEvenYield": 2.01,
        "totalValue": 198018.0741186349
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.91,
        "totalValue": 264011.69667447376
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.75,
        "totalValue": 396068.06758581207
    },
    {
        "premiumPaymentTerm": 11,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.66,
        "totalValue": 528254.2463301292
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 750,
        "breakEvenYield": 2.01,
        "totalValue": 108019.92889431502
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.91,
        "totalValue": 144077.23432455133
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.91,
        "totalValue": 216115.8514868272
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.83,
        "totalValue": 288132.09040028596
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.7,
        "totalValue": 432176.98535608314
    },
    {
        "premiumPaymentTerm": 12,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.62,
        "totalValue": 576116.0390012254
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 750,
        "breakEvenYield": 1.89,
        "totalValue": 117016.5646602221
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.81,
        "totalValue": 156071.60203234132
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.81,
        "totalValue": 234107.403048512
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.74,
        "totalValue": 312011.62767280487
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.64,
        "totalValue": 468129.84613551863
    },
    {
        "premiumPaymentTerm": 13,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.58,
        "totalValue": 624233.0320823272
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 750,
        "breakEvenYield": 1.79,
        "totalValue": 126059.62306634922
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.72,
        "totalValue": 168069.08870928024
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.72,
        "totalValue": 252103.63306392057
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.67,
        "totalValue": 336166.5864512991
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.58,
        "totalValue": 504064.9010207864
    },
    {
        "premiumPaymentTerm": 14,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.53,
        "totalValue": 672091.5341863097
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 750,
        "breakEvenYield": 1.64,
        "totalValue": 135061.3009285837
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.6,
        "totalValue": 180107.58716832573
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.6,
        "totalValue": 270161.3807524887
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.53,
        "totalValue": 360198.0543886701
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.46,
        "totalValue": 540237.8768233563
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.42,
        "totalValue": 720351.9371003325
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 750,
        "breakEvenYield": 1.57,
        "totalValue": 144096.37523808112
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.53,
        "totalValue": 192051.64617726632
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.53,
        "totalValue": 288077.4692658998
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.47,
        "totalValue": 384025.54429692304
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.42,
        "totalValue": 576332.5872414452
    },
    {
        "premiumPaymentTerm": 16,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.38,
        "totalValue": 768075.3628962298
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 750,
        "breakEvenYield": 1.5,
        "totalValue": 153053.32257175975
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.47,
        "totalValue": 204052.90083857064
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.47,
        "totalValue": 306079.3512578557
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.42,
        "totalValue": 408032.21715226833
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.38,
        "totalValue": 612409.9643186913
    },
    {
        "premiumPaymentTerm": 17,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.35,
        "totalValue": 816424.9114290057
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 750,
        "breakEvenYield": 1.44,
        "totalValue": 162050.05685797636
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.42,
        "totalValue": 216144.30202986658
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.42,
        "totalValue": 324216.45304479985
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.38,
        "totalValue": 432290.9135871296
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.34,
        "totalValue": 648420.1628542041
    },
    {
        "premiumPaymentTerm": 18,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.31,
        "totalValue": 864089.3369668949
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 750,
        "breakEvenYield": 1.39,
        "totalValue": 171113.9864543223
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.37,
        "totalValue": 228160.21708215875
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.37,
        "totalValue": 342240.32562323764
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.33,
        "totalValue": 456064.2432551254
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.3,
        "totalValue": 684313.430278219
    },
    {
        "premiumPaymentTerm": 19,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.28,
        "totalValue": 912416.2856426486
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 1.32,
        "totalValue": 180060.54101289867
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.26,
        "totalValue": 240013.41508004116
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.26,
        "totalValue": 360020.1226200615
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.24,
        "totalValue": 480403.898126327
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.22,
        "totalValue": 720002.7626728144
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.21,
        "totalValue": 960627.6190229029
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 750,
        "breakEvenYield": 1.28,
        "totalValue": 189103.13835904034
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.23,
        "totalValue": 252102.7149981921
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.23,
        "totalValue": 378154.07249728846
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.21,
        "totalValue": 504411.6246865968
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.18,
        "totalValue": 756156.4205634593
    },
    {
        "premiumPaymentTerm": 21,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.17,
        "totalValue": 1008642.6953851028
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 750,
        "breakEvenYield": 1.24,
        "totalValue": 198081.53719156617
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.2,
        "totalValue": 264153.04007655213
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.2,
        "totalValue": 396229.5601148279
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.17,
        "totalValue": 528469.5933975825
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.15,
        "totalValue": 792400.8490045127
    },
    {
        "premiumPaymentTerm": 22,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.14,
        "totalValue": 1056781.7625786988
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 750,
        "breakEvenYield": 1.21,
        "totalValue": 207210.27256273088
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.17,
        "totalValue": 276151.57473889797
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.17,
        "totalValue": 414227.36210834683
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.13,
        "totalValue": 552449.3187668899
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.12,
        "totalValue": 828082.0579123955
    },
    {
        "premiumPaymentTerm": 23,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.11,
        "totalValue": 1104596.4585760084
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 750,
        "breakEvenYield": 1.17,
        "totalValue": 216044.2869568152
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.14,
        "totalValue": 288085.62730194
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.14,
        "totalValue": 432128.44095291005
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.1,
        "totalValue": 576146.3489308403
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.1,
        "totalValue": 864821.2520238574
    },
    {
        "premiumPaymentTerm": 24,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.09,
        "totalValue": 1152970.8520823969
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 750,
        "breakEvenYield": 1.14,
        "totalValue": 225262.18572978367
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.11,
        "totalValue": 300227.58136539796
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.11,
        "totalValue": 450341.3720480973
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.06,
        "totalValue": 600073.5391030548
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.06,
        "totalValue": 900673.5777453365
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.05,
        "totalValue": 1200582.3875935294
    }
];

exports.breakEven_tmgoClassic = breakEven_tmgoClassic;