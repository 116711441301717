/**
 * Converts a data URL to a file object.
 * @param {string} dataURL - The data URL to convert.
 * @param {string} filename - The name of the file to create.
 * @param {string} type - The MIME type of the file.
 * @returns {Promise<File>} - A Promise that resolves with the created File object.
 */

export const dataURLtoFile = async (dataURL, filename, type) => {
    const response = await fetch(dataURL);
    const blob = await response.blob();
    return new File([blob], filename, { type });
};