const breakEven_incomeInvestFlex = [
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.75,
        "totalValue": 60001.53854924856
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.75,
        "totalValue": 90002.30782387283
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.75,
        "totalValue": 120003.07709849712
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.75,
        "totalValue": 180004.61564774567
    },
    {
        "premiumPaymentTerm": 5,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.75,
        "totalValue": 240006.15419699423
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 2.34,
        "totalValue": 60016.7715390095
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 2.26,
        "totalValue": 90020.31687792586
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.97,
        "totalValue": 120020.40200772134
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.97,
        "totalValue": 180030.603011582
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.97,
        "totalValue": 240040.80401544267
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.97,
        "totalValue": 360061.206023164
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.97,
        "totalValue": 480081.60803088534
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 300,
        "breakEvenYield": 1.24,
        "totalValue": 54025.92957351134
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 500,
        "breakEvenYield": 1.21,
        "totalValue": 90009.18079343345
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 750,
        "breakEvenYield": 1.2,
        "totalValue": 135078.27335005812
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.96,
        "totalValue": 180121.2142326971
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.96,
        "totalValue": 270181.8213490457
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.96,
        "totalValue": 360242.4284653942
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.96,
        "totalValue": 540363.6426980915
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.96,
        "totalValue": 720484.8569307884
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 300,
        "breakEvenYield": 0.84,
        "totalValue": 72034.2382958602
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 500,
        "breakEvenYield": 0.83,
        "totalValue": 120096.35656926435
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 0.82,
        "totalValue": 180124.4725712211
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 0.69,
        "totalValue": 240178.5573059453
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 0.69,
        "totalValue": 360267.83595891774
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 0.69,
        "totalValue": 480357.1146118906
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 0.69,
        "totalValue": 720535.6719178355
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 0.69,
        "totalValue": 960714.2292237811
    }
];

exports.breakEven_incomeInvestFlex = breakEven_incomeInvestFlex;