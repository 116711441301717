const breadcrumb = [
    { path: '/', parent: 'Dashboard', label: 'Set by DashboardBreadCrumb.js' },

    // dev
    { path: '/primeReact', parent: 'Dev', label: 'Prime React Info' },

    // user module
    { path: '/feedback', parent: 'User Mgmt', label: 'Feedback' },
    { path: '/profile', parent: 'User Mgmt', label: 'Profile' },
    { path: '/calculator/pv-fv-pmt-rate', parent: 'User Mgmt', label: 'PV | FV | PMT | RATE' },
    { path: '/calculator/irr-xirr', parent: 'User Mgmt', label: 'IRR | XIRR' },
    { path: '/card', parent: 'Contact Card', label: 'Create Card' },

    { path: '/comparison/insuredDetails', parent: 'Comparison', label: 'Insured Details' },
    { path: '/comparison/101RP', parent: 'Comparison', label: '101 Plan (RP)' },
    { path: '/comparison/integratedShield', parent: 'Comparison', label: 'Integrated Shield Plan' },
    { path: '/comparison/lifetimeIncome', parent: 'Comparison', label: 'Lifetime Income Plan' },
    { path: '/comparison/longTermCare', parent: 'Comparison', label: 'Long Term Care' },
    { path: '/comparison/multiCI', parent: 'Comparison', label: 'MultiPay CI Plan' },
    { path: '/comparison/retirementIncomePlan', parent: 'Comparison', label: 'Retirement Income Plan' },
    { path: '/comparison/term', parent: 'Comparison', label: 'Term Insurance' },
    { path: '/comparison/universalLifeIndex', parent: 'Comparison', label: 'Universal Life (Index)' },
    { path: '/comparison/wholeLife', parent: 'Comparison', label: 'Whole Life Plan' },
    { path: '/comparison/wholeLifeEndowment', parent: 'Comparison', label: 'Whole Life Endowment' },
    { path: '/comparison/manage', parent: 'Comparison', label: 'Online Management' },


    { path: '/finerty/journeyOfWealth', parent: 'Finerty', label: 'Journey of WEALTH' },
    { path: '/finerty/journeyToRetire', parent: 'Finerty', label: 'Journey to RETIRE' },
    { path: '/finerty/journeyOfHealth', parent: 'Finerty', label: 'Journey of HEALTH' },
    { path: '/finerty/journeyForLegacy', parent: 'Finerty', label: 'Journey for LEGACY' },

    // admin module

    { path: '/QueryAnnouncement', parent: 'Announcement', label: 'Create Announcement' },
    { path: '/viewAnnouncement', parent: 'Announcement', label: 'View Announcement' },
    { path: '/viewErrorCode', parent: 'Error Code', label: 'View Error Code' },
    { path: '/viewInvitationCodes', parent: 'Invitation Code', label: 'View Invitation Code' },
    { path: '/viewInsuranceRecord', parent: 'Insurance Library', label: 'View Insurance Record' },
    
    
    { path: '/viewUsersFeedback', parent: 'Feedback', label: 'View User Feedback' },
];

export default breadcrumb;

/*

const breadcrumb = [
    { path: '/', parent: 'Dashboard', label: 'Dashboard' },
    { path: '/formlayout', parent: 'UI Kit', label: 'Form Layout' },
    { path: '/input', parent: 'UI Kit', label: 'Input' },
    { path: '/floatlabel', parent: 'UI Kit', label: 'Float Label' },
    { path: '/invalidstate', parent: 'UI Kit', label: 'Invalid State' },
    { path: '/button', parent: 'UI Kit', label: 'Button' },
    { path: '/table', parent: 'UI Kit', label: 'Table' },
    { path: '/list', parent: 'UI Kit', label: 'List' },
    { path: '/tree', parent: 'UI Kit', label: 'Tree' },
    { path: '/panel', parent: 'UI Kit', label: 'Panel' },
    { path: '/overlay', parent: 'UI Kit', label: 'Overlay' },
    { path: '/media', parent: 'UI Kit', label: 'Media' },
    { path: '/menu', parent: 'UI Kit', label: 'Menu' },
    { path: '/menu/seat', parent: 'UI Kit', label: 'Menu' },
    { path: '/menu/payment', parent: 'UI Kit', label: 'Menu' },
    { path: '/menu/confirmation', parent: 'UI Kit', label: 'Menu' },
    { path: '/messages', parent: 'UI Kit', label: 'Messages' },
    { path: '/file', parent: 'UI Kit', label: 'File' },
    { path: '/chart', parent: 'UI Kit', label: 'Charts' },
    { path: '/misc', parent: 'UI Kit', label: 'Misc' },
    { path: '/icons', parent: 'Utilities', label: 'Icons' },
    { path: '/blocks', parent: 'PrimeBlocks', label: 'Blocks' },
    { path: '/crud', parent: 'Utilities', label: 'Crud' },
    { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
    { path: '/timeline', parent: 'Pages', label: 'Timeline' },
    { path: '/invoice', parent: 'Pages', label: 'Invoice' },
    { path: '/help', parent: 'Pages', label: 'Help' },
    { path: '/empty', parent: 'Pages', label: 'Empty Page' },
    { path: '/documentation', parent: 'Pages', label: 'Documentation' }
];

*/