import React, { useContext } from 'react'

// prime react
import { Accordion, AccordionTab } from 'primereact/accordion';

// comparison context
import { UserContext } from 'contexts/userContext';

// prime react
import { Fieldset } from 'primereact/fieldset';

// component
const DisplayInsuredDetails = () => {

    // comparison context
    const user = useContext(UserContext);

    // function to get insured's info in context
    const getInsured = () => {

        if (user.comparisonInsured) {
            const insured = user?.comparisonInsured;
            return <>
                <p>Insured details</p>
                <ol>
                    <li>Name: <span className='text-primary'>{insured.name}</span></li>
                    {insured.dob && <li>D.O.B: <span className='text-primary'>{insured.dob}</span></li>}
                    <li>Gender: <span className='text-primary'>{insured.gender}</span></li>
                    <li>Smoker/Non-smoker: <span className='text-primary'>{insured.smokingStatus}</span></li>                
                </ol>
                <p>Age</p>
                <ul>
                    <li>ALB: <span className='text-primary'>{insured.ALB}</span></li>
                    <li>ANB: <span className='text-primary'>{insured.ANB}</span></li>
                    <li>AnB: <span className='text-primary'>{insured.AnB} {!insured.dob && `(Could also be ${insured.ALB} as D.O.B was not entered)`}</span></li>
                </ul>
            </>
        } else {
            return <p>No information entered. Please enter insured's details</p>
        }
    }

    return (
        <div className='card'>
            <h5>Current Insured's details</h5>
            {getInsured()}
            <br></br>
            <Fieldset legend="ALB vs ANB vs AnB" toggleable collapsed={true}>
                <Accordion multiple>
                    <AccordionTab header="Age Last Birthday (ALB)">
                        <p>Calculation steps:</p>
                        <ol>
                            <li>Let X = Current Year - Birth Year</li>
                            <li>If the birthday for the year has not passed, then ALB = X - 1. Otherwise ALB = X</li>
                        </ol>
                        <p>Life insurers that uses ALB notation:</p>
                        <ul>
                            <li>AIA, China Life, FWD, Income, Manulife</li>
                        </ul>
                        <p><b>Note:</b></p>
                        <ul>
                            <li>All Integrated Shield Plans use Age Next Birthday (ANB) even when the insurer uses ALB notation for their life insurance</li>
                        </ul>
                    </AccordionTab>
                    <AccordionTab header="Age Next Birthday (ANB)">
                        <p>Calculation steps:</p>
                        <ol>
                            <li>ANB = ALB + 1</li>
                        </ol>
                        <p>Life insurers that uses ALB notation:</p>
                        <ul>
                            <li>China Taiping, eTiQa, Great Eastern, Prudential, SingLife, Tokio Marine</li>
                        </ul>
                        <p><b>Note:</b></p>
                        <ul>
                            <li>All Integrated Shield Plans use ANB notation</li>
                        </ul>
                    </AccordionTab>
                    <AccordionTab header="Age Nearest Birthday (AnB)">
                        <p>Calculation steps:</p>
                        <ol>
                            <li>Calculate the number of days between today and date of last birthday</li>
                            <li>Calculate the number of days between today and date of next birthday</li>
                            <li>Compare the above 2 numbers</li>
                            <li>If days from between today and date of last birthday is lesser, Age Nearest Birthday is ALB</li>
                            <li>If days from between today and date of next birthday is lesser, Age Nearest Birthday is ANB</li>
                            <li>If days from between today and date of next birthday is equal to the days from between today and date of next birthday (due to leap year), Age Nearest Birthday is ANB</li>
                        </ol>
                        <p>Life insurers that uses AnB notation:</p>
                        <ul>
                            <li>HSBC Life</li>
                        </ul>
                    </AccordionTab>
                </Accordion>
            </Fieldset>
        </div>
    )
}

export default DisplayInsuredDetails