import React, { useState, useContext, useRef } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

// PrimeReact
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages';

// custom axios hook
import useAxiosAuth from 'hooks/useAxiosAuth'

// context
import { UserContext, DispatchUserContext } from 'contexts/userContext';

// custom method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// regex to check for 8 digits numbers starts with 6,8 & 9
const phoneRegExp = /^[689]\d{7}$/;

// formik schema
const updateInfoSchema = yup.object({
    name: yup.string().required('Your preferred name is required'),
    mobile: yup.string().required('Your mobile number is required').matches(phoneRegExp, 'Mobile number is not valid'),
});

// component
const FormUpdateUserInfo = () => {

    // state to update preferred name and mobile
    const [beginUpdate, setBeginUpdate] = useState(false)

    // useRef for Message
    const messages = useRef(null);

    // user dispatch function and details from context
    const dispatch = useContext(DispatchUserContext);
    const user = useContext(UserContext)
    const { name, mobile } = user?.userDetails

    // axios instance
    const axiosInstance = useAxiosAuth();

    // formik form to change info
    const formikInfo = useFormik({
        initialValues: { mobile, name },
        validationSchema: updateInfoSchema,
        onSubmit: (values) => updateInfo(values)
    })

    const updateInfo = async (values) => {

        // prevent update to backend if there are no changes
        if (values.name === name && values.mobile === mobile) {
            messages.current.clear();
            messages.current.show({ severity: 'error', summary: '', detail: `No change in preferred name & password`, sticky: true });
            return;
        }
        handleClearUpdate();

        // display loading screen
        dispatch({ type: "LOADING", value: true });

        try {
            // post to backend
            const res = await axiosInstance.post("dashboard/profile/updateInfo", values);
            const { status, data } = res;

            if (status === 200 & data.success) {

                // update user context
                dispatch({ type: "UPDATE_USERINFO", value: values });

                // display success message
                messages.current.clear();
                messages.current.show({ severity: 'success', summary: '', detail: 'Preferred Name and mobile updated' })

            } else {
                // display unsuccessful message
                messages.current.clear();
                messages.current.show({ severity: 'error', summary: '', detail: 'Updated failed, please try again later' })
            }
        } catch (error) {
            primeMesagesErrorCatcher(error, messages);
        }

        // close loading screen
        dispatch({ type: "LOADING", value: false });

    }

    const handleBeginUpdate = () => setBeginUpdate(true);
    const handleClearUpdate = () => setBeginUpdate(false);

    return (
        <div className='field card'>
            <Messages ref={messages}></Messages>
            <h5>User Info</h5>
            <h6>Click [Update Info] to update the follwoing.</h6>
            <ol>
                <li>Your preferred name</li>
                <li>Your mobile number</li>
            </ol>
            <br></br>
            <div>
                {displayFormikLabel("name", "Your Preferred Name", formikInfo.touched, formikInfo.errors)}
                <InputText id="name" className="w-full mb-3" value={formikInfo.values.name} disabled={!beginUpdate} onChange={(e) => formikInfo.setFieldValue("name", e.target.value)} />

                {displayFormikLabel("mobile", "Your mobile (E.g. 91234567)", formikInfo.touched, formikInfo.errors)}
                <InputText id="mobil" className="w-full mb-3" value={formikInfo.values.mobile} disabled={!beginUpdate} onChange={(e) => formikInfo.setFieldValue("mobile", e.target.value)} />

                <div className='grid mt-1'>
                    {beginUpdate
                        ? <>
                            <div className="col-12 md:col-6">
                                <Button type="button" label="Cancel" aria-label="Cancel" className="p-button-outlined w-full" onClick={handleClearUpdate} />
                            </div>
                            <div className="col-12 md:col-6">
                                <Button type="submit" label="Update" aria-label="Update" className="w-full" onClick={formikInfo.handleSubmit} />
                            </div>
                        </>
                        : <div className='col-12'>
                            <Button type="button" label="Update Info" aria-label="Update Info" className="w-full" onClick={handleBeginUpdate} />
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default FormUpdateUserInfo