import React, { useRef, useContext } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

// PrimeReact
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea'
import { Messages } from 'primereact/messages';

// context
import { DispatchUserContext } from 'contexts/userContext';

// custom function 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display formik label
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// dropdown options
const catergories = [
    { name: 'Feedback' },
    { name: 'Feature Request' },
    { name: 'Suggestion' },
    { name: 'Complain' },
    { name: 'Others' }
];

// formik initial values
const initialValues = {
    category: "",
    feedback: ""
}

// formik validation schema
const validationSchema = yup.object({
    category: yup.string().required("Please select a category"),
    feedback: yup.string().required("Please enter your feedback"),
})

// component
const Feedback = () => {

    // ref for Messages
    const messages = useRef();

    // context dispatch function to update user context
    const dispatch = useContext(DispatchUserContext);

    // formik instance
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => submitFeedback(values)
    })

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // submit feedback function
    const submitFeedback = async (values) => {

        // display loading screen
        dispatch({ type: "LOADING", value: true });

        try {
            const res = await axiosInstance.post('dashboard/profile/submitFeedback', values)
            messages.current.clear();
            res?.data?.success
                ? messages.current.show({ severity: 'success', detail: 'Feedback Submitted. We will contact within 3 working days. Thanks!' })
                : messages.current.show({ severity: 'error', detail: 'Something went wrong. Please try again later' })
            formik.resetForm();
        } catch (error) {
            primeMesagesErrorCatcher(error, messages);
        }

        // close loading screen
        dispatch({type: "LOADING", value: false});
    }

    return (
        <div className='grid'>
            <div className='col-12'>
                <div className="card">
                    <h5>Feedback</h5>
                    <h6>We are constantly working to make DataVisor precisely what you need for your advisory business.</h6>

                    <ul>
                        <li>Your feedback helps us decide which features to build and prioritise improvements. </li>
                        <li>To help us make DataVisor the best it can be, we would like your thoughts and feedback. </li>
                        <li>Our feedback form should take about five minutes of your time, but your inputs are invaluable to us! </li>
                    </ul>
                </div>
            </div>
            <div className='col-12'>
                <div className="field card">
                    <h5>Instruction</h5>
                    <ol>
                        <li>Select a category</li>
                        <li>Enter your feedback</li>
                        <li>Click [Submit Feedback]</li>
                    </ol>
                    <br></br>
                    <Messages ref={messages}></Messages>
                    <div>
                        {
                            displayFormikLabel("category", "Category", formik.touched, formik.errors)
                        }
                        <Dropdown
                            id="category"
                            value={formik.values.category}
                            options={catergories}
                            onChange={(e) => formik.setFieldValue("category", e.target.value.name)}
                            className="w-full mb-3"
                            optionLabel="name"
                            placeholder={formik.values.category === "" ? "Select a category" : formik.values.category} />
                        {
                            displayFormikLabel("feedback", "Feedback", formik.touched, formik.errors)
                        }
                        <InputTextarea
                            id="feedback"
                            rows={5}
                            autoResize
                            className="w-full mb-3"
                            value={formik.values.feedback}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className='grid mt-2'>
                        <div className='col-12 md:col-6'>
                            <Button label="Cancel" aria-label="Cancel" className="p-button-outlined w-full" onClick={formik.resetForm} />
                        </div>
                        <div className='col-12 md:col-6'>
                            <Button label="Submit Feedback" aria-label="Submit Feedback" className="w-full" onClick={formik.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback