/**
 * Formats a number as a dollar amount with commas and a specified number of decimal places.
 * @param {string|number} value - The value to format as a dollar amount.
 * @param {number} [digits=0] - The number of decimal places to include in the formatted value.
 * @returns {string} The formatted dollar amount as a string.
 */

const formatDollar = (value, digits = 0) => {
    // accept string and numbers only
    value = (typeof (value) === "string") ? parseFloat(value) : value
    return value.toLocaleString("en-US", { maximumFractionDigits: digits })
}
export default formatDollar