import React, { useState, useEffect, useRef } from 'react';
import date from 'date-and-time';
import writeXlsxFile from 'write-excel-file';

// prime react
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';

// custom hooks 
import useScreenSize from '../hooks/useScreenSize';

// custom helper functions
import scrollToRef from 'helpers/scrollToRef';

// ====
// helper functions
// ====

// create cashflow sequence
const createCashFlowSequence = (data) => {

    // console.log("data", data);
    const { policyStart, premiumTerm, annualPremium, payoutStartDate, /* incomePayOutPeriod, */ payoutSequence, specialPayOut, specialPayOutSequece, maturityBenefit, maturityBenefit_Gtd, maturityBenefit_Total, policyTerm } = data;

    /*
    console.log("policyStart", policyStart);
    console.log("premiumTerm", premiumTerm);
    console.log("annualPremium", annualPremium);
    console.log("payoutStartDate", payoutStartDate);
    console.log("incomePayOutPeriod", incomePayOutPeriod);
    console.log("payoutSequence", payoutSequence);
    console.log("specialPayOut", specialPayOut);
    console.log("specialPayOutSequece", specialPayOutSequece);
    console.log("maturityBenefit", maturityBenefit);
    console.log("maturityBenefit_Gtd", maturityBenefit_Gtd);
    console.log("maturityBenefit_Total", maturityBenefit_Total);
    console.log("policyTerm", policyTerm);
    */

    const cashflow = [];

    // ===
    // premium payment
    // ===
    for (let i = 0; i < premiumTerm; i++) {
        cashflow.push({
            when: date.addYears(policyStart, i),
            amountGtd: -annualPremium,
            amountTotal: -annualPremium,
            description: "Premium payment"
        });
        // console.log(`premium payment on ${date.addYears(policyStart, i)} with premium amount ${annualPremium}`);
    }

    // ===
    // monthly payout
    // ===
    const firstRegularInflowIndex = payoutSequence[0].indexStart; // use as the starting index for the first inflow

    for (let i = 0; i < payoutSequence.length; i++) {

        const currentSequence = payoutSequence[i];
        // console.log("currentSequence", currentSequence);
        const { indexStart, indexEnd, type, g_amt, p_amt } = currentSequence;

        for (let j = indexStart; j <= indexEnd; j++) {
            cashflow.push({
                when: type === "Monthly" ? date.addMonths(payoutStartDate, j - firstRegularInflowIndex) : date.addYears(payoutStartDate, j - firstRegularInflowIndex),
                amountGtd: g_amt,
                amountTotal: +(g_amt + p_amt).toFixed(2),
                description: "Retirement income payout"
            });
            // console.log(`monthly payout on ${date.addMonths(payoutStartDate, i)} with payout amount ${data.payoutMinGtd}`);
        }
    }

    // ===
    // maturity benefit
    // ===
    if (maturityBenefit) {

        // get maturity date
        const maturityDate = date.addDays(date.addYears(policyStart, policyTerm), -1);
        // console.log("maturityDate", maturityDate);

        // check if the last payout is at maturity date
        const lastPayoutDate = cashflow[cashflow.length - 1].when;
        // console.log("lastPayoutDate", lastPayoutDate);

        if (date.isSameDay(lastPayoutDate, maturityDate)) {
            cashflow[cashflow.length - 1].amountGtd += maturityBenefit_Gtd;
            cashflow[cashflow.length - 1].amountTotal += maturityBenefit_Total;
            cashflow[cashflow.length - 1].description = `Last Retirement income payout + maturity benefit (Gtd: ${maturityBenefit_Gtd}, Total: ${maturityBenefit_Total})`;
        } else {
            cashflow.push({
                when: maturityDate,
                amountGtd: maturityBenefit_Gtd,
                amountTotal: maturityBenefit_Total,
                description: "Maturity Benefit"
            });
        }
    }

    // todo: ==============
    // todo: special payout (code not check cos there is no such use case yet)
    // todo: ==============

    if (specialPayOut) {
        for (let i = 0; i < specialPayOutSequece.length; i++) {

            const currentSequence = specialPayOutSequece[i];
            // console.log("currentSequence", currentSequence);
            const { indexStart, indexEnd, type, g_amt, p_amt } = currentSequence;

            for (let j = indexStart; j <= indexEnd; j++) {

                // todo: need to check this part
                // check if exsiting payout date is the same as special payout date 
                const existingPayout = cashflow.filter(c => date.isSameDay(c.when, type === "Monthly" ? date.addMonths(policyStart, j) : date.addYears(policyStart, j)));
                if (existingPayout.length > 0) {
                    existingPayout[0].amountGtd += g_amt;
                    existingPayout[0].amountTotal += +(g_amt + p_amt).toFixed(2);
                    existingPayout[0].description = "Special Payout";
                } else {
                    cashflow.push({
                        when: type === "Monthly" ? date.addMonths(policyStart, j) : date.addYears(policyStart, j),
                        amountGtd: g_amt,
                        amountTotal: +(g_amt + p_amt).toFixed(2),
                        description: "Special Payout"
                    });
                }
                // console.log(`special payout on ${date.addMonths(payoutStartDate, i)} with payout amount ${data.payoutMinGtd}`);
            }
        }
    }
    return cashflow;
}

// create excel file 
const createExcelFile = async (data) => {

    const headerRow = [
        { value: 'Date', fontWeight: 'bold', width: 50, align: 'center' },
        { value: 'Gtd Cashflow', fontWeight: 'bold', width: 50, align: 'center' },
        { value: 'Total Cashflow', fontWeight: 'bold', width: 50, align: 'center' },
        { value: 'Description', fontWeight: 'bold', width: 100 }
    ];

    const rowData = [headerRow];
    const cashflow = createCashFlowSequence(data);
    const columns = [{ width: 30 }, { width: 30 }, { width: 30 }, { width: 100 }];
    for (let i = 0; i < cashflow.length; i++) {
        const { when, amountGtd, amountTotal, description } = cashflow[i];
        rowData.push([
            { type: Date, value: when, format: 'dd/mm/yyyy', align: 'center' },
            { type: Number, value: amountGtd, align: 'center' },
            { type: Number, value: amountTotal, align: 'center' },
            { type: String, value: description }
        ]);
    }

    (async () => {
        try {
            await writeXlsxFile(rowData, { columns, fileName: `${data.uniqueID}.xlsx` })
            // console.log('Excel file successfully created!');
        } catch (error) {
            console.error('Error creating Excel file:', error);
        }
    })();
}

// component
const RetirementIncomeChart = (props) => {

    const { data } = props;

    // states
    const [display, setDisplay] = useState(false);
    const [chartData, setChartData] = useState({});
    const [chartOption, setChartOption] = useState({});
    const [chartMode, setChartMode] = useState("overview"); // overview, annual, monthly
    const [recordDisplayYear, setRecordDisplayYear] = useState(0);

    //  const [displayYearlyView, setDisplayYearlyView] = useState(false); // false for overview, true for yearly view
    const [mobileScreen, setMobileScreen] = useState(false);

    // ref
    const chartRef = useRef(null);

    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width])

    // ====
    // generate monthly chart
    // ====

    const generateMonthlyChart = (data, inflow, year, month) => {

        // * Note: month is zero based (Jan = 0), year is the usual year

        const { insurer, planName } = data;
        // console.log(`Name: ${insurer} ${planName}`);
        // console.log(inflow);
        // console.log("year @ generateMonthlyChart", year);
        // console.log("month @ generateMonthlyChart", month);

        // filter the inflow data for the month
        const monthlyInflow = inflow.filter(income => income.when.getFullYear() === year && income.when.getMonth() === month);
        // console.log("monthlyInflow @ generateMonthlyChart", monthlyInflow);

        // sum up the guaraneed and total inflow for the month
        const guaranteed = monthlyInflow.reduce((acc, income) => acc + income.amountGtd, 0);
        const total = monthlyInflow.reduce((acc, income) => acc + income.amountTotal, 0);
        const labels = [`Total Received: ${total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (Guaranteed Income + Projected Amount)`];

        const chart = {
            labels,
            datasets: [
                {
                    label: "Guaranteed Income",
                    data: [guaranteed],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgb(54, 162, 235)',
                    ],
                    borderWidth: 1
                },
                {
                    label: "Projected Amount",
                    data: [+(total - guaranteed).toFixed(2)],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgb(75, 192, 192)',
                    ],
                    borderWidth: 1
                },
            ]
        };

        const MMM = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const options = {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                title: {
                    display: true,
                    text: `${insurer} ${planName} (${MMM[month]} ${year})`
                },
            },
            scales: {
                x: {
                    beginAtZero: true
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (value, index, values) {
                            return '$' + value.toLocaleString();
                        }
                    }
                }
            },
        };

        setChartData(chart);
        setChartOption(options);
        setChartMode("monthly");

    } // end generateMonthlyChart

    // ====
    // generate annual chart
    // ====

    // consolidate monthly inflow for a year
    const consolidateMonthlyInflow = (inflow, year) => {

        const inflowYear = inflow.filter(income => income.when.getFullYear() === year);
        // console.log("inflowYear @ consolidateMonthlyInflow ", inflowYear);

        const allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const allMonthsInflow = allMonths.map(month => {
            const monthlyInflow = inflowYear.filter(income => date.format(income.when, 'MMM') === month);
            const total = monthlyInflow.reduce((acc, income) => acc + income.amountTotal, 0);
            return { month, total };
        });

        return allMonthsInflow;
    }

    const generateAnnualChart = (data, inflow, year) => {

        // record the year display to be use for monthly chart
        setRecordDisplayYear(year);

        const { insurer, planName } = data;
        // console.log(`Name: ${insurer} ${planName}`);
        // console.log("year @ generateAnnualChart", year);

        // filter the inflow data for the year
        const inflowYear = consolidateMonthlyInflow(inflow, year)
        // console.log("inflowYear @ generateAnnualChart", inflowYear);

        const labels = inflowYear.map(income => income.month);
        // console.log("labels @ generateAnnualChart", labels);

        const totalData = inflowYear.map(income => income.total);
        // console.log("totalData @ generateAnnualChart", totalData);

        const chart = {
            labels,
            datasets: [
                {
                    label: 'Monlthy Income',
                    data: totalData,
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgb(54, 162, 235)',
                    ],
                    borderWidth: 0.5
                },
            ]
        };

        const options = {
            onClick: (event, element) => {
                try {
                    if (element[0].index >= 0) {
                        const month = element[0]?.index;
                        // console.log(`index: ${index} was click and the year click is: ${year}`)
                        generateMonthlyChart(data, inflow, year, month);
                    }
                } catch {
                    // Do not do anything when click on blank space
                }
            },
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                title: {
                    display: true,
                    text: `${insurer} ${planName} (Year ${year})`
                },
            },
            scales: {
                x: {
                    beginAtZero: true
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (value, index, values) {
                            return '$' + value.toLocaleString();
                        }
                    }
                }
            }
        };

        setChartData(chart);
        setChartOption(options);
        setChartMode("annual");

    } // end generateAnnualChart

    // ====
    // generate overview chart
    // ====

    const generateOverviewChart = (data) => {

        const { insurer, planName, payoutStartDate } = data;
        // console.log("data @ generateOverviewChart", data);
        // console.log(`Name: ${insurer} ${planName}`);

        const retirementPlanCashflowSeq = createCashFlowSequence(data);
        // console.log("retirementPlanCashflowSeq @ generateOverviewChart", retirementPlanCashflowSeq);

        const inflow = retirementPlanCashflowSeq.filter(cashflow => cashflow.amountTotal > 0);
        // console.log("inflow @ generateOverviewChart", inflow);

        const beginningPayOutYear = payoutStartDate.getFullYear();
        const maturityDate = inflow[inflow.length - 1].when;
        const maturityYear = maturityDate.getFullYear();
        // console.log(`Pay out start from ${beginningPayOutYear} to ${maturityYear}`);

        // creat and number array from beginningPayOutYear to maturityYear
        const labels = Array.from({ length: maturityYear - beginningPayOutYear + 1 }, (_, i) => beginningPayOutYear + i);
        // console.log("labels", labels);

        // inital the barChartData with 0 with the same length as labels
        const barChartData = labels.map(ele => 0);
        // console.log("barChartData", barChartData, barChartData.length, labels.length);

        // go through the inflow data to populate the income on an annual basis
        inflow.forEach(cashflow => {
            const year = cashflow.when.getFullYear();
            const index = labels.indexOf(year);
            barChartData[index] = +(barChartData[index] + cashflow.amountTotal).toFixed(2);
        });
        // console.log("barChartData", barChartData);

        const chart = {
            labels,
            datasets: [
                {
                    label: 'Yearly Income',
                    data: barChartData,
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgb(54, 162, 235)',
                    ],
                    borderWidth: 0.5
                }
            ]
        };

        const options = {
            onClick: (event, element) => {
                try {
                    if (element[0]?.index >= 0) {
                        const index = element[0].index;
                        const year = labels[index];
                        // console.log(`index: ${index} was click and the year click is: ${year}`)
                        generateAnnualChart(data, inflow, year);
                    }
                } catch {
                    // do nothing when click on blank space
                }
            },
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                title: {
                    display: true,
                    text: `${insurer} ${planName} (Overview)`
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (value, index, values) {
                            return '$' + value.toLocaleString();
                        }
                    }
                }
            }
        };

        setChartData(chart);
        setChartOption(options);
        setChartMode("overview");

    } // end generateOverviewChart

    const displayChart = (data) => {
        const currentDisplay = display;
        !currentDisplay && generateOverviewChart(data); // only generate chart when chart is not display
        setDisplay(!display);
    }

    const marginBottom = display ? "flex justify-content-end mb-3" : "flex justify-content-end mb-6";

    const handleChartClick = () => {
        displayChart(data);
        scrollToRef(chartRef);
    }


    return <>
        <div className={marginBottom}>
            <span>
                <Button icon="pi pi-chart-bar" label={mobileScreen ? "Chart" : (display ? "Hide Chart" : "Display Chart")} onClick={handleChartClick} className="mr-2" />
                <Button icon="pi pi-file-excel" label={mobileScreen ? "Excel" : "Download Excel"} onClick={() => createExcelFile(data)} className="mr-5" />
            </span>
        </div>
        <Dialog header="Retirement Income Chart" visible={display} style={{ width: '80vw' }} onHide={() => setDisplay(false)}>
            <div className="m-0">
                {
                    display && <div ref={chartRef}>
                        <Card className="m-0">
                            {
                                chartMode !== "overview" && <Button icon="pi pi-arrow-left" label={mobileScreen ? "Overview" : "Back to Overview"} onClick={() => generateOverviewChart(data)} className="ml-2" />
                            }
                            {
                                chartMode === "monthly" && <Button icon="pi pi-calendar" label={mobileScreen ? `Yr ${recordDisplayYear}` : `Back to Year ${recordDisplayYear}`} onClick={() => generateAnnualChart(data, createCashFlowSequence(data), recordDisplayYear)} className="ml-2" />
                            }
                            <Chart type="bar" data={chartData} options={chartOption} />
                        </Card>
                    </div>
                }
            </div>
        </Dialog>
    </>;
}

export default RetirementIncomeChart;