import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';

// PrimeReact
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages';

//cusom components
import LoadingScreen from 'components/LoadingScreen';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// custom method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// formik initial values
const initialValues = {
    password: "",
    confirmedPassword: "",
    otp: ""
}

// password regex
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

// formilk schema
const validationSchema = yup.object({
    password: yup.string().required('Password is required').matches(passwordRegExp, "Your password must contain at least 8 characters with 1 uppercase, 1 lowercase, 1 number and 1 special character"),
    confirmedPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirmed password is required'),
    otp: yup.string().required('2FA is required'),
});

// component
const ResetPassword = () => {

    // store token validation status
    const [validateResetToken, setValidateResetToken] = useState(false);
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);

    // hook to get token from url
    const { token } = useParams();
    // console.log("token:", token);

    // custom hook
    const axiosInstance = useAxiosAuth();

    // hook to navigate to other page
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = async () => {
            try {
                const res = await axiosInstance.post('non-auth/validateResetToken', { token });
                if (res?.data?.success) {
                    const { isUsed, expiry, name } = res?.data?.details;
                    const hasExpired = new Date() > new Date(expiry);

                    // check if token is used  and if is expired
                    if (!isUsed && !hasExpired) {
                        setValidateResetToken(true);
                        setUserName(name);
                    } else {
                        navigate("/");
                    }
                }
            } catch (error) {
                navigate("/");
            }
        }
        // check if token is valid
        if (token) {
            checkToken();
        } else {
            // console.log("no token");
            messages.current.show({ severity: 'error', detail: `Invalid Token`, sticky: true });
        }
    }, [axiosInstance, navigate, token])

    // Ref for toast
    const messages = useRef(null);

    // formik instance  
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    // function to go back to sign in page
    const handleSignIn = () => {
        navigate("/");
    }

    // function to reset password
    const handleSubmit = async (values) => {

        const { password, otp } = values;
        setLoading(true);
        try {
            const res = await axiosInstance.post('non-auth/updatePassword', { password, otp, token });
            if (res?.data?.success) {
                messages.current.show({ severity: 'success', detail: `Please login with the new password. Redirecting to login page in 5 sec.`, sticky: true })
            } else {
                messages.current.show({ severity: 'error', detail: `Reset password failed. Please try again with a valid OTP or contact support. Redirecting to login page in 5 sec.`, sticky: true })
            }
            setLoading(false);
            setTimeout(() => navigate("/"), 5000);
        } catch (error) {
            setLoading(false);
            primeMesagesErrorCatcher(error, messages);
        }
    }

    return token
        ? <div className='flex h-screen align-items-center justify-content-center'>
            <div className="surface-card p-4 shadow-2 border-round w-11 lg:w-6">
                <Messages ref={messages}></Messages>
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Welcome back {userName}!</div>
                    <span className="text-600 font-medium line-height-3">Please key in your new password and 2FA below to complete the process</span>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div>
                        {displayFormikLabel("password", "Enter New Password", formik.touched, formik.errors)}
                        <InputText id="password" type="password" value={formik.values.password} onChange={formik.handleChange} className="w-full mb-2" />

                        {displayFormikLabel("confirmedPassword", "Confirm New Password", formik.touched, formik.errors)}
                        <InputText id="confirmedPassword" type="password" value={formik.values.confirmedPassword} onChange={formik.handleChange} className="w-full mb-2" />

                        {displayFormikLabel("otp", "2FA", formik.touched, formik.errors)}
                        <InputText id="otp" type="password" value={formik.values.otp} onChange={formik.handleChange} className="w-full mb-2" />

                        <Button label="Update Password" icon="pi pi-lock" className="w-full mt-5 mb-1" disabled={!validateResetToken} />

                        <div className="flex align-items-center justify-content-end mb-6">
                            <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={handleSignIn} href="/" >Back to Sign In</a>
                        </div>
                    </div>
                </form>
            </div>
            {loading && <LoadingScreen />}
        </div>
        : <h1>No Token</h1>
    
}

export default ResetPassword