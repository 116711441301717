

/**
 * Returns a label for a Formik field with validation error message if applicable.
 * @param {string} fieldName - The name of the Formik field.
 * @param {string} defaultText - The default text to display as the label.
 * @param {object} formikTouched - The Formik touched object.
 * @param {object} formikErrors - The Formik errors object.
 * @returns {JSX.Element} - The label JSX element.
 */

import React from "react";

const displayFormikLabel = (fieldName, defaultText, formikTouched, formikErrors) => {

    // validate input in field is valid if formik.touched[fieldName] && formik.errors[fieldName] are true
    // !! to return return false even when value is null
    const isFormFieldValid = !!(formikTouched[fieldName] && formikErrors[fieldName]);
    
    // return the corresponding label when the field is valid or not valid
    return isFormFieldValid
        ? <label htmlFor={fieldName} className="p-error block font-medium mb-2">{formikErrors[fieldName]}</label>
        : <label htmlFor={fieldName} className="block font-medium mb-2">{defaultText}</label>
}
export default displayFormikLabel
