import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types';

// prime react
import { Sidebar } from 'primereact/sidebar';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Avatar } from 'primereact/avatar';

// custom component
import ImageFilesUploader from 'components/ImageFilesUploader';
import ImageCropper from 'components/ImageCropper';

// user context
import { UserContext, DispatchUserContext } from 'contexts/userContext';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// custom helper method 
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';
import { dataURLtoFile } from 'helpers/uploadFile';

// carousel's responsive settings
const responsiveOptions = {
    avatarURL: [
        { breakpoint: '1024px', numVisible: 3, numScroll: 1 },
        { breakpoint: '600px', numVisible: 2, numScroll: 1 },
        { breakpoint: '480px', numVisible: 1, numScroll: 1 }
    ],
    backgroundURL: [
        { breakpoint: '1024px', numVisible: 3, numScroll: 1 },
        { breakpoint: '600px', numVisible: 1, numScroll: 1 },
        { breakpoint: '480px', numVisible: 1, numScroll: 1 }
    ]
};

// set the title of the image carousel
const getTitle = (text) => {
    switch (text) {
        case "avatarURL":
            return "Profile Picture";
        case "backgroundURL":
            return "Background Picture"
        default:
            return "Set the value of prop (type)";
    }
}

const FormUpdatePic = (props) => {

    // setting title based on prop (type) passed
    const { type, aspectRatio } = props;
    const [title, setTitle] = useState();

    // state to reload the page after update
    const [reload, setReload] = useState(false);

    // ref for Prime React Messages
    const messages = useRef();

    const [imageList, setImageList] = useState([])

    // cropper and uploader state
    const [uploadImageDataURL, setUploadImageDataURL] = useState("");
    const [croppedImageDataURL, setCroppedImageDataURL] = useState("");
    const [openFullScreen, setOpenFullScreen] = useState(false);

    const user = useContext(UserContext)
    const dispatch = useContext(DispatchUserContext);

    // custom hook
    const axiosInstance = useAxiosAuth();

    // image fall back URL
    const imageFallback = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'

    // ==================
    // image carousel code
    // ==================

    // useEffect to load image list from user context
    useEffect(() => {

        // function for getImage Array below
        const prepareImageList = (arr) => {
            return arr.length === 0 ? [] : arr.map((item, i) => {
                return {
                    isDefault: i === 0 ? true : false,
                    url: item
                }
            });
        }

        // retrieve the image array from mongodb
        const getImageArray = async () => {
            try {
                const res = await axiosInstance.get(`/dashboard/profile/get-array-${type}`);
                const { status, data } = res;

                if (status === 200 && data.success) {
                    const arr = (data.arr) ? data.arr : [];
                    // update image array in user context 
                    dispatch({ type: "UPDATE_USERPICS", value: { arr, type } });
                    // storing the pictures urls in state (imageList) & set default image (1st url in the array)        
                    setImageList(prepareImageList(arr));
                    // setting the title of the page profile pic or background pic
                    setTitle(getTitle(type));
                } else {
                    messages.current.clear();
                    messages.current.show({ severity: 'error', detail: res.data.msg, sticky: true })
                }
            } catch (error) {
                primeMesagesErrorCatcher(error, messages);
            }
        }

        // display loading screen
        dispatch({ type: "LOADING", value: true });

        getImageArray();

        // close loading screen
        dispatch({ type: "LOADING", value: false });

    }, [axiosInstance, dispatch, reload, type]);

    // carousell display template
    const imageTemplate = (image) => {
        return (
            <div>
                <div className="mb-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        type === "avatarURL"
                            ? <Avatar image={image.url} size="xlarge" imageAlt="profile" onImageError={(e) => e.target.src = imageFallback} />
                            : <img src={image.url} onError={(e) => e.target.src = imageFallback} alt="background"
                                style={{ width: '140px', height: '70px', objectFit: 'contain', border: '5px solid transparent' }} />
                    }
                </div>
                <div className="mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button icon="pi pi-thumbs-up" className="p-button p-button-rounded mr-2" onClick={() => handleImage(image, true)} disabled={image.isDefault} />
                    <Button icon="pi pi-download" className="p-button-success p-button-rounded mr-2" onClick={() => handleImageDownload(image.url)} />
                    <Button icon="pi pi-times" className="p-button-warning p-button-rounded" onClick={() => handleImage(image, false)} disabled={imageList.length === 1 ? true : false} />
                </div>
            </div>
        )
    }

    const handleImageDownload = (url) => {
        window.open(url);
    }

    // set default image or delete image (setDefaultImage = false)
    const handleImage = async (image, setDefaultImage) => {

        // copy the urls of non-selected image into arr
        let arr = [];
        imageList.forEach(img => (img.url !== image.url) && arr.push(img.url));

        // if set default then add back to arr else do nothing (delete image pass in)
        setDefaultImage && arr.unshift(image.url);
        uploadImageArray(arr);
    }

    const uploadImageArray = useCallback(async (arr) => {

        // display loading screen
        dispatch({ type: "LOADING", value: true });

        try {
            // update backend
            const res = await axiosInstance.post("dashboard/profile/managePic", { arr, type });
            const { status, data } = res;

            if (status === 200 & data.success) {
                setReload(prev => !prev);
                messages.current.clear();
                messages.current.show({ severity: 'success', detail: `Profile picture is updated` })
            } else {
                messages.current.clear();
                messages.current.show({ severity: 'error', detail: res.data.msg, sticky: true })
            }
        } catch (error) {
            primeMesagesErrorCatcher(error, messages);
        }

        // close loading screen
        dispatch({ type: "LOADING", value: false });

    }, [axiosInstance, type, dispatch])

    // ==================
    // image uploader and cropper code
    // ==================

    // only proceed if only one file is upload and the file is of type image
    const processUploadEvent = (event) => {

        // check if only 1 file is uploaded and the file is of type image
        if (event.files.length === 1 && event.files[0].type.startsWith("image")) {

            // reading the file as dataURL with FileReader -> event.files[0] as dataURL
            const reader = new FileReader();
            reader.readAsDataURL(event.files[0]);

            // when reader is loaded
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    const { height, width } = img;
                    // console.log(`ImageFileUploader.js -> width: ${width} | height: ${height}`);

                    if (aspectRatio === 1 && height !== width) {
                        alert("Error. The image's height and width must be the same. Current image size is " + width + " x " + height + " pixels");
                        return false;
                    }

                    if (aspectRatio === 2 && (width !== 2 * height)) {
                        alert("Error. The image's width must be 2 time of its height. Current image size is " + width + " x " + height + " pixels");
                        return false;
                    }

                    // set image max width and height to 800 pixels        
                    if (height > 800 || width > 800) {
                        alert("Error. Max image size is 800 x 800 pixels. Current image size is " + width + " x " + height + " pixels");
                        return false;
                    }
            
                    setUploadImageDataURL(e.target.result);
                    setOpenFullScreen(true);
                    return true;
                }
            } // end reader onload
        } else {
            alert("Erorr. Only accept 1 file per uploaded")
        }
    }

    // function to close the cropper
    const closeFullScreen = () => setOpenFullScreen(false);

    // reload whenever there is a new image is cropped & upload cropped image to backend
    useEffect(() => {

        // https://javascript.plainenglish.io/how-to-access-private-s3-buckets-securely-87778efd93bd
        const generatePreSignedPutUrl = async () => {
            let presignedUrl, fileName;
            try {
                const res = await axiosInstance.get(`/dashboard/profile/get-upload-${type}`);
                const { status, data } = res;
                if (status === 200 & data.success) {
                    presignedUrl = res.data.url;
                    fileName = res.data.fileName; // *** fileName must be the same for upload, also must be same for fileType            
                } else {
                    messages.current.clear();
                    messages.current.show({ severity: 'error', detail: res.data.msg, sticky: true })
                }
            } catch (error) {
                primeMesagesErrorCatcher(error, messages);
            }

            const file = await dataURLtoFile(croppedImageDataURL, fileName, "image/jpeg")
            try {
                const resp = await fetch(presignedUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": file.type,
                    },
                    body: file,
                });


                if (resp.status === 200) {
                    // get existing array from user context & add imageURL to the last of array
                    const newImageUrl = resp.url.split('?', 2)[0];
                    const newArrayList = user.userDetails[type].map(i => i)
                    newArrayList.push(newImageUrl);

                    // update the new image URL array to database
                    uploadImageArray(newArrayList);
                    setCroppedImageDataURL("");

                } else {
                    messages.current.clear();
                    messages.current.show({ severity: 'error', detail: "Upload failed!", sticky: true })
                }
            } catch (error) {
                primeMesagesErrorCatcher(error, messages);
            }
        }

        // upload cropped image to backend whenever croppedImageDataURL is changed
        if (croppedImageDataURL !== "") {
            generatePreSignedPutUrl();
        }
    }, [croppedImageDataURL, axiosInstance, dispatch, type, user, uploadImageArray])

    return (
        <div className='grid'>
            <div className='col-12'>
                <Messages ref={messages} />
            </div>
            <div className='col-12'>
                <div className='field card'>
                    <h5>{`${title} (${imageList.length}/3)`}</h5>
                    <Carousel value={imageList} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions[type]} itemTemplate={imageTemplate} />
                </div>
            </div>
            <div className='col-12'>
                <div className='field card'>
                    <h5>Upload {title}</h5>
                    {
                        (imageList.length < 3)
                            ? <>
                                <ImageFilesUploader
                                    maxFileSize={2000000}
                                    getUploadEvent={processUploadEvent}
                                />
                                <Sidebar visible={openFullScreen} fullScreen onHide={closeFullScreen} showCloseIcon={false}>
                                    <div>
                                        <ImageCropper
                                            aspectRatio={aspectRatio}
                                            imageDataURL={uploadImageDataURL}
                                            getCroppedImageDataURL={setCroppedImageDataURL}
                                            closeImageCropper={closeFullScreen}
                                        />
                                    </div>
                                </Sidebar>
                            </>
                            : <>
                                <p>Please delete 1 image before uploading a new image</p>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default FormUpdatePic;

FormUpdatePic.propTypes = {
    type: PropTypes.oneOf(['avatarURL', 'backgroundURL']),
    aspectRatio: PropTypes.number.isRequired
}