const breakEven_fwdInvestGoalX = [
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 500,
        "breakEvenYield": 2.09,
        "totalValue": 60002.056497696256
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 750,
        "breakEvenYield": 2.09,
        "totalValue": 90003.08474654437
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.43,
        "totalValue": 120039.90113993574
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.43,
        "totalValue": 180059.85170990395
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.43,
        "totalValue": 240079.80227987148
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.43,
        "totalValue": 360119.7034198079
    },
    {
        "premiumPaymentTerm": 10,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.34,
        "totalValue": 480168.9530976107
    }
];

exports.breakEven_fwdInvestGoalX = breakEven_fwdInvestGoalX;