
import axios from "helpers/axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

// dispatch function to store the new refresh token into the user context
import { DispatchUserContext } from "contexts/userContext";

const useRefreshToken = () => {

  // user context
  const dispatch = useContext(DispatchUserContext);

  // navigate to other pages
  const navigate = useNavigate();

  const getRefreshToken = async () => {

    // console.log("@ useRefreshToken.js => Request for a refresh token")

    try {
      const res = await axios.get('/refresh', {
        withCredentials: true // this is needed to send the http cookie to backend
      });
      // console.log("useRefreshToken.js")
      // console.log(res);
      
      dispatch({ type: "SET_JWT", value: res?.data?.jwt });
      return res?.data?.jwt;

    } catch (err) {

      // logout and clear local storage
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      navigate("/");

      // display error message
      const errMsg = err?.response?.data.message;
      // console.error("error msg at useRefresh Token", errMsg);
      if (errMsg === "Token expired" || errMsg.startsWith("More than")) {
        alert("Session Expired!");
      } else {
        alert(" Errors in authenication ")
      }
    }
  }
  return getRefreshToken;
}

export default useRefreshToken