import React, { useState, useRef, useContext } from 'react'

// PrimeReact
import { Button } from 'primereact/button'
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup';
import { Messages } from 'primereact/messages';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// context
import { DispatchUserContext } from 'contexts/userContext';

// custom helper method 
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

const FormRequestUpdate = () => {

    // ref for Prime React Messages
    const messages = useRef();

    // context dispatch function to update user context
    const dispatch = useContext(DispatchUserContext);

    // state for request update
    const [requestUpdate, setRequestUpdate] = useState(false);

    // handle Request update for rnf, name on rnf and email
    const handleRequestUpdate = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Please confirm request for change of RNF, RNF Name & email?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                request();
                setRequestUpdate(true);
            },
            reject: () => { }
        });
    }

    // custom hook
    const axiosInstance = useAxiosAuth();

    // sending request to backend
    const request = async () => {

        // display loading screen
        dispatch({ type: "LOADING", value: true });

        try {
            const res = await axiosInstance.get("dashboard/profile/requestUpdate")
            const { data } = res;

            if (data?.success) {
                messages.current.clear();
                messages.current.show({ severity: 'success', detail: 'Request submitted' });
            } else {
                messages.current.clear();
                messages.current.show({ severity: 'success', detail: 'Request failed. Please try again later.' });
            }
        } catch (error) {
            primeMesagesErrorCatcher(error, messages);
        }

        // close loading screen
        dispatch({ type: "LOADING", value: false });
    }

    return (
        <div className='field card'>
            <Messages ref={messages} />
            <h6>Click [Request Update] to update the follwoing.</h6>
            <ol>
                <li>RNF number</li>
                <li>Name on RNF</li>
                <li>Work Email</li>
            </ol>
            <p>We will get back to you within 2 working days</p>
            <Button label={!requestUpdate ? "Request Update" : "Request Sent"} disabled={requestUpdate} aria-label="Submit" className="w-full mt-2" onClick={handleRequestUpdate} />
            <ConfirmPopup />
        </div>
    )
}

export default FormRequestUpdate