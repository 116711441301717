import React, {useState, useRef} from 'react'

import { TabView, TabPanel } from 'primereact/tabview';

import FormXirr from '_dashboard/modules/calculator/FormXirr';
import FormIrr from '_dashboard/modules/calculator/FormIrr';

const IrrXirr = () => {

    // storing the index of active tab
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    // ref to focus on form
    const inputRef = useRef(null);

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Calculate IRR | XIRR</h5>
                    <ul>
                        <li>All calculations are in the beginning mode</li>
                        <li>Click on [Invest] to toggle to [Collect] or vice versa</li>
                    </ul>
                    <h6>Internal Rate of Return (IRR)</h6>
                    <ol>
                        <li>The first entry (End of year 0) and last entry should not be $0</li>
                        <li>There must be a minimum of one outflow [Invest] and a minimum of one inflow [Collect]</li>
                        <li>For years with no cash flow, leave the amount as $0 (Either invest or collect would be fine)</li>                        
                    </ol> 
                    <h6>Extended Internal Rate of Return (XIRR)</h6>
                    <ol>
                        <li>All entries must be greater than 0</li>
                        <li>There must be a minimum of one outflow [Invest] and a minimum of one inflow [Collect]</li>                 
                    </ol> 
                </div>
            </div>

            <div className="col-12">
                <div className="card" ref={inputRef}>
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>                                
                        <TabPanel header="Internal Rate of Return (IRR)" >
                            <FormIrr/>
                        </TabPanel>
                        <TabPanel header="Extended Internal Rate of Return (XIRR)">
                            <FormXirr/>
                        </TabPanel>   
                    </TabView>
                </div>
            </div>
        </div>
    )
}

export default IrrXirr