const breakEven_hsbcWealthVoyage = [
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 300,
        "breakEvenYield": 1.98,
        "totalValue": 54014.4126045235
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 500,
        "breakEvenYield": 1.98,
        "totalValue": 90024.02100753924
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 750,
        "breakEvenYield": 1.98,
        "totalValue": 135019.90853252355
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.88,
        "totalValue": 180127.18879679206
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.88,
        "totalValue": 270190.78319518775
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.88,
        "totalValue": 360254.3775935841
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.88,
        "totalValue": 540381.5663903755
    },
    {
        "premiumPaymentTerm": 15,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.88,
        "totalValue": 720508.7551871683
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 300,
        "breakEvenYield": 1.49,
        "totalValue": 72040.42248175236
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 500,
        "breakEvenYield": 1.49,
        "totalValue": 120067.37080292072
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 750,
        "breakEvenYield": 1.49,
        "totalValue": 180092.53152089452
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.4,
        "totalValue": 240154.00235796673
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.4,
        "totalValue": 360231.00353695056
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.4,
        "totalValue": 480308.00471593346
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.4,
        "totalValue": 720462.0070739011
    },
    {
        "premiumPaymentTerm": 20,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.4,
        "totalValue": 960616.0094318669
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 300,
        "breakEvenYield": 1.21,
        "totalValue": 90020.97335900982
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 500,
        "breakEvenYield": 1.21,
        "totalValue": 150034.95559834962
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 750,
        "breakEvenYield": 1.21,
        "totalValue": 225033.87301664182
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 1000,
        "breakEvenYield": 1.17,
        "totalValue": 300095.35320823983
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 1500,
        "breakEvenYield": 1.17,
        "totalValue": 450143.0298123599
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 2000,
        "breakEvenYield": 1.17,
        "totalValue": 600190.7064164797
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 3000,
        "breakEvenYield": 1.17,
        "totalValue": 900286.0596247198
    },
    {
        "premiumPaymentTerm": 25,
        "monthlyPremium": 4000,
        "breakEvenYield": 1.17,
        "totalValue": 1200381.4128329593
    }
];

exports.breakEven_hsbcWealthVoyage = breakEven_hsbcWealthVoyage;