/**
 * Replaces special characters in a given text with their corresponding HTML entities.
 * @param {string} text - The text to be processed.
 * @returns {string} The processed text with special characters replaced by their corresponding HTML entities.
 */

const unescapeSpecialChar = (text)=> {

    const specialChars = [
        [`&amp;lt;`, `<`],
        [`&amp;gt;`, '>'],
        [`&gt;`, `>`],
        [`&amp;`, `&`],
        [`&amp;amp;`, `&`],
        [`&#x27;`, `'`],
        [`&#x96;`, "`"],
        [`&quot;`, `"`],
        [`&#x2F`, `/`],
        [`&#x5C`, `\\`]
    ]
    specialChars.forEach(ele => text = text.replaceAll(ele[0], ele[1]));
    return text;
}

/*
const unescapeSpecialChar = (text) => {
    const specialChars = [
        ['&', '&amp;'],
        ['<', '&lt;'],
        ['>', '&gt;'],
        ['"', '&quot;'],
        ["'", '&#x27;'],
        ['`', '&#x96;'],
        ['/', '&#x2F;'],
        ['\\', '&#x5C;']
    ];

    specialChars.forEach(char => {
        const regex = new RegExp(char[0], 'g');
        text = text.replace(regex, char[1]);
    });

    return text;
}
*/
export default unescapeSpecialChar;
